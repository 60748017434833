import { TableHeader, TableProps } from './Table.types'
import { TableRoot } from './Table.styles'
import { buildClasses } from '../../utils/Helper'
import React, { ForwardedRef, Fragment, forwardRef, useEffect, useMemo, useState } from 'react'
import TableExpansionRow from './TableExpansionRow'

const Table = <D extends object = object>(
    {
        headers,
        data,
        keyExtractor,
        getExpansionPanel,
        expandOnClickRow,
        initialExpandedRowValue = [],
        onExpandRows,
        expandedRowId,
        scrollIntoExpandedRow = true,
        isSmallTable,
        minHeight,
        onRowClick, // Add the onRowClick prop
        ...props
    }: TableProps<D>,
    ref: ForwardedRef<HTMLTableElement>
) => {
    const [expandedRows, setExpandedRows] = useState<(number | string)[]>(initialExpandedRowValue)

    useEffect(() => {
        if (!getExpansionPanel) {
            return
        }

        if (expandedRowId && !expandedRows.includes(expandedRowId)) {
            setExpandedRows([expandedRowId])
        } else if (!expandedRowId && expandedRows.length) {
            setExpandedRows(initialExpandedRowValue)
        }
    }, [expandedRowId, initialExpandedRowValue, expandedRows, getExpansionPanel])

    const visibleHeaders = useMemo<TableHeader<D>[]>(() => {
        return headers.filter((item: TableHeader<D>) => {
            if (typeof item.visible !== 'boolean') {
                return true
            }
            return item.visible
        })
    }, [headers])

    const toggleExpand = (value: number | string) => {
        if (!getExpansionPanel) {
            return
        }

        const isExpanded = expandedRows.includes(value)
        const newExpandedRows = isExpanded ? expandedRows.filter(item => item !== value) : [...expandedRows, value]
        setExpandedRows(newExpandedRows)
        onExpandRows?.(newExpandedRows)
    }

    const renderHeaders = () => {
        return visibleHeaders.map((item: TableHeader<D>, index) => {
            return <th key={index}>{item.title}</th>
        })
    }

    const renderRows = () => {
        return data.map((row: D, dataIndex) => {
            const rowScrollId = `tableRowScrollId-${dataIndex}`
            const isClickable = !!(expandOnClickRow || onRowClick)
            const handleRowClick = () => {
                if (onRowClick) {
                    onRowClick(row)
                }
                if (expandOnClickRow) {
                    toggleExpand(keyExtractor(dataIndex, row))
                }
            }
            return (
                <Fragment key={keyExtractor ? keyExtractor(dataIndex, row) : dataIndex}>
                    <tr
                        className={buildClasses({ 'cursor-pointer': isClickable })}
                        onClick={isClickable ? handleRowClick : undefined}
                    >
                        {visibleHeaders.map((header, headerIndex) => {
                            return (
                                <td data-label={header.title} key={`col-${headerIndex}`} className='!whitespace-nowrap'>
                                    {header.value(row, {
                                        isExpanded: expandedRows.includes(keyExtractor(dataIndex, row)),
                                        toggleExpand: () => {
                                            return toggleExpand(keyExtractor(dataIndex, row))
                                        }
                                    })}
                                </td>
                            )
                        })}
                    </tr>
                    {getExpansionPanel && expandedRows.includes(keyExtractor(dataIndex, row)) && (
                        <TableExpansionRow
                            row={row}
                            rowScrollId={rowScrollId}
                            colSpan={visibleHeaders.length}
                            getExpansionPanel={getExpansionPanel}
                            scrollIntoView={scrollIntoExpandedRow}
                        />
                    )}
                </Fragment>
            )
        })
    }

    return (
        <TableRoot isSmallTable={isSmallTable} minHeight={minHeight} {...props}>
            <table ref={ref}>
                <thead className='text-overline-normal semibold'>
                    <tr>{renderHeaders()}</tr>
                </thead>
                <tbody className='text-body-normal'>{renderRows()}</tbody>
            </table>
        </TableRoot>
    )
}

export default forwardRef(Table)
