import tw, { styled } from 'twin.macro'

export const ModalOverlay = styled.div`
    ${tw`fixed top-0 left-0 bg-black bg-opacity-50 z-40 w-full h-full`}
`

export const ModalRoot = styled.div`
    ${tw`fixed z-50 bg-background-primary shadow-lg rounded-lg max-w-5xl w-full overflow-y-auto py-1`}
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const ModalTitle = styled.div`
    ${tw`border-b border-b-gray-200 py-4 px-3 flex justify-between items-center gap-3`}
`

export const ModalTitleCloseButton = styled.button`
    ${tw`flex items-center justify-center`}
`

export const ModalBody = styled.div`
    ${tw`py-2 px-3`}
`

export const ModalActions = styled.h6`
    ${tw`border-t border-t-gray-200 py-4 px-3`}
`
