import { InfoListProps } from './InfoList.types'
import { InfoListRoot } from './InfoList.styles'
import React from 'react'
import Typography from '../typography/Typography'

const InfoList = ({ items, ...props }: InfoListProps) => {
    return (
        <InfoListRoot {...props}>
            {items.map((item, index) => {
                return (
                    <li key={index}>
                        <Typography className='text-basic-primary' as='strong' size='large' type='semibold'>
                            {item.key}:
                        </Typography>
                        <Typography className='text-basic-primary' size='large'>
                            {item.value}
                        </Typography>
                    </li>
                )
            })}
        </InfoListRoot>
    )
}

export default InfoList
