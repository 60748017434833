import { ComponentInteractiveProps } from '../ComponentManager.types'
import { ComponentManagerComponentRow } from '../ComponentManager.styles'
import { InteractiveModel } from '../../interactive-model/InteractiveModel'
import { Element as ScrollElement } from 'react-scroll'
import Column from '../../grid/column/Column'
import SectionTitle from '../../section-title/SectionTitle'

export const ComponentInteractiveImage = ({
    component: { title, fileUrl, hash },
    devProject,
    ...props
}: ComponentInteractiveProps) => {
    if (fileUrl) {
        return (
            <ScrollElement name={hash}>
                <ComponentManagerComponentRow {...props}>
                    <Column cols={12}>
                        <SectionTitle>{title}</SectionTitle>
                    </Column>
                    <Column cols={12}>
                        <InteractiveModel fileUrl={fileUrl} devProject={devProject} />
                    </Column>
                </ComponentManagerComponentRow>
            </ScrollElement>
        )
    }
}
