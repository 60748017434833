import { MicroSiteContactFormTerms } from '../../../services/Microsite.types'
import { getMicroSiteContactFormTerms } from '../../../services/MicrositeService'
import { useAxios } from '../../useAxios'
import { useQuery } from '@tanstack/react-query'

export const MICRO_SITE_CONTACT_FORM_TERMS_QUERY_KEY = 'micro-site-contact-form-terms'

const useMicrositeContactFormTerms = (initialData?: MicroSiteContactFormTerms) => {
    const axiosInstance = useAxios()

    return useQuery(
        [MICRO_SITE_CONTACT_FORM_TERMS_QUERY_KEY],
        async () => {
            return getMicroSiteContactFormTerms(axiosInstance)
        },
        { initialData }
    )
}

export default useMicrositeContactFormTerms
