import tw, { styled } from 'twin.macro'

export const PriceDiscountedRoot = styled.span`
    ${tw`mr-auto lg:mr-0 lg:ml-auto text-2xl font-semibold text-grey-discount !my-0 !leading-none relative no-underline`}

    &::after {
        ${tw`bg-error-400 block absolute w-full h-0.5 top-0 right-0 transform -rotate-[14deg] mt-[.6rem]`}
        content: "";
    }
`

export const DiscountRoot = styled.span`
    ${tw`mr-auto lg:mr-0 lg:ml-auto text-error-400 font-bold`}
`

export const DiscountTextRoot = styled.div`
    ${tw`mr-3 mb-2 lg:mb-0 flex flex-row items-center p-1 w-16 h-6 bg-error-100 rounded-full uppercase`}

    span {
        ${tw`font-bold text-sm leading-5 flex items-center tracking-widest text-error-400 ml-1`}
    }
`

export const PriceContainerRoot = styled.div<{ $isMeter?: boolean; $isMonth?: boolean }>`
    ${tw`flex flex-row items-end lg:ml-auto`}
    ${({ $isMeter }) => {
        return $isMeter ? tw`lg:-mr-[3.37rem]` : ''
    }}
  ${({ $isMonth }) => {
        return $isMonth ? tw`lg:-mr-[5.7rem]` : ''
    }}
`

export const PriceRoot = styled.h3`
    ${tw`text-4xl font-bold text-basic-primary !my-0 !leading-none`}
`

export const PriceSuffixRoot = styled.span`
    ${tw`text-2xl mb-[0.05rem] font-bold text-basic-primary !my-0 !leading-none ml-1`}
`

export const AnotherImagesContainer = styled.div`
    ${tw`xl:h-[510px] grid grid-cols-2 xl:grid-cols-1 col-span-2 xl:col-span-1 xl:grid-rows-2 gap-4`}
`

export const AnotherImage = styled.img`
    ${tw`w-full h-full object-cover rounded-[1.563rem] col-span-1 cursor-pointer`}
`

export const PriceNoteRoot = styled.span`
    ${tw`mr-auto lg:mr-0 lg:ml-auto text-grey-400 font-semibold`}
`
