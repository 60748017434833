import tw, { styled } from 'twin.macro'

export const ImageBlockRoot = styled.div`
    ${tw`flex flex-col relative`}
`

export const ImageBlockNavigation = styled.div`
    ${tw`lg:absolute lg:-top-[5.3rem] w-full flex justify-center lg:justify-end items-center`}
`

export const ImageBlockDotListContainer = styled.div`
    ${tw`flex justify-center`}
`

export const ImageBlockDotList = styled.ul`
    ${tw`flex list-none`}

    margin: 0.75rem 0 0;
    padding: 0.938rem;
    border-radius: 0.875rem;

    background-color: ${({ theme: { colors } }) => {
        return colors.background.gradient
    }};

    li {
        ${tw`flex justify-center items-center cursor-pointer p-0`}
        margin: 0 0.25rem;
    }
`

export const ImageContainer = styled.div`
    ${tw`flex justify-center`}
`

export const ImageContent = styled.div`
    ${tw`flex justify-center relative md:h-[31.25rem]`}
`

export const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    cursor: pointer;
`

export const NavigationContainer = styled.div`
    ${tw`flex relative`}
    top: -1.375rem;
`

export const NavigationButton = styled.button`
    ${tw`flex justify-center items-center cursor-pointer`}
    padding: 0.75rem;
    border-radius: 2.5rem;
    background-color: ${({ theme: { colors } }) => {
        return colors.text.primary
    }};
    border-color: transparent;
    margin: 0.75rem;

    :disabled {
        opacity: 0.6;
        pointer-events: none;
    }
`
