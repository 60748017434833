import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const ArrowUpIcon = ({ color = 'basic-black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.27372 15.5082C3.85241 14.9326 3.9256 14.0818 4.43718 13.6079L11.2372 7.30789C11.6803 6.89737 12.3197 6.89737 12.7628 7.30789L19.5628 13.6079C20.0744 14.0818 20.1476 14.9326 19.7263 15.5082C19.305 16.0837 18.5487 16.166 18.0371 15.6921L12 10.0988L5.96287 15.6921C5.45129 16.166 4.69502 16.0837 4.27372 15.5082Z'
                fill={getColorFromString(theme, color)}
            />
        </RootIcon>
    )
}

export default ArrowUpIcon
