import { getInteractiveModel } from '../../../services/interactive-model/InteractiveModelService'
import { useAxios } from '../../useAxios'
import { useQuery } from '@tanstack/react-query'

export const useInteractiveModel = (url: string, config = {}) => {
    const axiosInstance = useAxios()

    return useQuery(['interactiveModel', url], async () => {
        return getInteractiveModel(axiosInstance, url, config)
    })
}
