import { AnotherImage, AnotherImagesContainer } from './MicrositeHeader.styles'
import { MicrositeHeaderPreviewImageProps } from './MicrositeHeader.types'
import { buildClassesWithDefault } from '../../utils/Helper'
import Column from '../grid/column/Column'
import React from 'react'
import useGallery from '../../hooks/useGallery'

const MicrositeHeaderPreviewImage = ({
    contact,
    info,
    hasMorePreviewImages,
    showBrokerMainCard
}: MicrositeHeaderPreviewImageProps) => {
    const { setCurrentImage, renderImageViewer } = useGallery(info.previewImageUrls)
    const shouldExpandColumn = !contact || !showBrokerMainCard
    const columnLg = shouldExpandColumn ? 12 : 8
    const columnXl = shouldExpandColumn ? 12 : 9

    const classes = {
        'grid-cols-2 xl:grid-cols-3': hasMorePreviewImages
    }

    const renderMainImage = () => {
        return (
            <div className='col-span-2'>
                <img
                    onClick={() => {
                        return setCurrentImage(0)
                    }}
                    src={info.previewImageUrls[0]}
                    alt='Preview'
                    className='lg:h-[510px] w-full object-cover rounded-[1.563rem] col-span-2 cursor-pointer'
                />
            </div>
        )
    }

    const renderAnotherImages = () => {
        if (info.previewImageUrls.length > 1) {
            return (
                <AnotherImagesContainer>
                    <AnotherImage
                        onClick={() => {
                            return setCurrentImage(1)
                        }}
                        src={info.previewImageUrls[1]}
                        alt='Preview'
                    />
                    <AnotherImage
                        onClick={() => {
                            return setCurrentImage(2)
                        }}
                        src={info.previewImageUrls[2]}
                        alt='Preview'
                    />
                </AnotherImagesContainer>
            )
        }
    }

    return (
        <>
            {renderImageViewer()}
            <Column cols={12} lg={columnLg} xl={columnXl} className='h-full order-2 lg:order-none'>
                <div className={buildClassesWithDefault(classes, 'grid gap-4')}>
                    {renderMainImage()}
                    {renderAnotherImages()}
                </div>
            </Column>
        </>
    )
}

export default MicrositeHeaderPreviewImage
