import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const LakeIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <svg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M13.7536 13.7055C13.596 13.6116 13.3995 13.6116 13.2418 13.7055L11.8275 14.5481C11.3545 14.8299 10.7651 14.8299 10.2921 14.5481L8.87773 13.7055C8.72006 13.6116 8.52359 13.6116 8.36592 13.7055L6.95589 14.5456C6.4796 14.8294 5.88562 14.8273 5.41134 14.5402L4.03971 13.7098C3.88162 13.6141 3.68362 13.6134 3.52486 13.708L1.60317 14.853C1.36594 14.9943 1.05905 14.9166 0.917708 14.6794C0.776364 14.4421 0.854093 14.1353 1.09132 13.9939L3.01301 12.8489C3.4893 12.5652 4.08329 12.5672 4.55758 12.8544L5.92921 13.6847C6.08731 13.7804 6.2853 13.7811 6.44406 13.6865L7.85409 12.8464C8.32711 12.5646 8.91651 12.5646 9.38953 12.8464L10.8039 13.689C10.9615 13.7829 11.158 13.7829 11.3157 13.689L12.73 12.8464C13.203 12.5646 13.7924 12.5646 14.2654 12.8464L15.6798 13.689C15.8375 13.7829 16.0339 13.7829 16.1916 13.689L17.6059 12.8464C18.079 12.5646 18.6683 12.5646 19.1413 12.8464L21.0675 13.9939C21.3047 14.1352 21.3825 14.4421 21.2412 14.6793C21.0998 14.9166 20.7929 14.9943 20.5557 14.853L18.6295 13.7055C18.4719 13.6116 18.2754 13.6116 18.1177 13.7055L16.7034 14.5481C16.2304 14.8299 15.641 14.8299 15.168 14.5481L13.7536 13.7055Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M13.7536 16.3739C13.596 16.28 13.3995 16.28 13.2418 16.3739L11.8275 17.2165C11.3545 17.4983 10.7651 17.4983 10.2921 17.2165L8.87773 16.3739C8.72006 16.28 8.52359 16.28 8.36592 16.3739L6.95589 17.214C6.4796 17.4978 5.88562 17.4957 5.41134 17.2085L4.03971 16.3782C3.88162 16.2825 3.68362 16.2818 3.52486 16.3764L1.60317 17.5214C1.36594 17.6627 1.05905 17.585 0.917708 17.3478C0.776364 17.1105 0.854093 16.8036 1.09132 16.6623L3.01301 15.5173C3.4893 15.2335 4.08329 15.2356 4.55758 15.5227L5.92921 16.3531C6.08731 16.4488 6.2853 16.4495 6.44406 16.3549L7.85409 15.5148C8.32711 15.233 8.91651 15.233 9.38953 15.5148L10.8039 16.3574C10.9615 16.4513 11.158 16.4513 11.3157 16.3574L12.73 15.5148C13.203 15.233 13.7924 15.233 14.2654 15.5148L15.6798 16.3574C15.8375 16.4513 16.0339 16.4513 16.1916 16.3574L17.6059 15.5148C18.079 15.233 18.6683 15.233 19.1413 15.5148L21.0675 16.6623C21.3047 16.8036 21.3825 17.1105 21.2412 17.3477C21.0998 17.585 20.7929 17.6627 20.5557 17.5214L18.6295 16.3739C18.4719 16.28 18.2754 16.28 18.1177 16.3739L16.7034 17.2165C16.2304 17.4983 15.641 17.4983 15.168 17.2165L13.7536 16.3739Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M13.7536 19.0423C13.596 18.9484 13.3995 18.9484 13.2418 19.0423L11.8275 19.8849C11.3545 20.1667 10.7651 20.1667 10.2921 19.8849L8.87773 19.0424C8.72006 18.9484 8.52359 18.9484 8.36592 19.0424L6.95589 19.8824C6.4796 20.1662 5.88562 20.1641 5.41134 19.877L4.03971 19.0466C3.88162 18.9509 3.68362 18.9502 3.52486 19.0448L1.60317 20.1898C1.36594 20.3312 1.05905 20.2534 0.917708 20.0162C0.776364 19.779 0.854093 19.4721 1.09132 19.3307L3.01301 18.1858C3.4893 17.902 4.08329 17.9041 4.55758 18.1912L5.92921 19.0215C6.08731 19.1172 6.2853 19.1179 6.44406 19.0233L7.85409 18.1833C8.32711 17.9015 8.91651 17.9014 9.38953 18.1832L10.8039 19.0258C10.9615 19.1197 11.158 19.1197 11.3157 19.0258L12.73 18.1832C13.203 17.9014 13.7924 17.9014 14.2654 18.1832L15.6798 19.0258C15.8375 19.1197 16.0339 19.1197 16.1916 19.0258L17.6059 18.1832C18.079 17.9014 18.6683 17.9014 19.1413 18.1832L21.0675 19.3307C21.3047 19.472 21.3825 19.7789 21.2412 20.0162C21.0998 20.2534 20.7929 20.3312 20.5557 20.1898L18.6295 19.0423C18.4719 18.9484 18.2754 18.9484 18.1177 19.0423L16.7034 19.8849C16.2304 20.1667 15.641 20.1667 15.168 19.8849L13.7536 19.0423Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M0.482422 11.0439C0.482422 10.7677 0.706279 10.5439 0.982422 10.5439H20.9761C21.2522 10.5439 21.4761 10.7677 21.4761 11.0439C21.4761 11.32 21.2522 11.5439 20.9761 11.5439H0.982422C0.706279 11.5439 0.482422 11.32 0.482422 11.0439Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M10.9795 0.578054C11.2556 0.57804 11.4795 0.801886 11.4795 1.07803L11.48 10.7384C11.48 11.0146 11.2562 11.2385 10.98 11.2385C10.7039 11.2385 10.48 11.0146 10.48 10.7385L10.4795 1.07808C10.4795 0.801937 10.7033 0.578068 10.9795 0.578054Z'
                    fill={fillColor}
                />
                <path
                    d='M9.62988 3.42915C9.62988 2.68392 10.234 2.07979 10.9792 2.07979C11.7245 2.07979 12.3286 2.68392 12.3286 3.42915V6.34004C12.3286 7.08528 11.7245 7.68941 10.9792 7.68941C10.234 7.68941 9.62988 7.08528 9.62988 6.34004V3.42915Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M3.49626 2.6665C3.58716 2.40575 3.87222 2.26805 4.13298 2.35895C6.05087 3.0275 7.33625 4.56143 8.16171 6.1413C8.98794 7.72266 9.38518 9.40783 9.49934 10.4921C9.52826 10.7667 9.32907 11.0128 9.05444 11.0417C8.77982 11.0706 8.53375 10.8714 8.50484 10.5968C8.4019 9.61916 8.03526 8.05871 7.27539 6.60439C6.51476 5.14858 5.39184 3.85679 3.80382 3.30322C3.54306 3.21233 3.40537 2.92726 3.49626 2.6665Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M5.1667 7.21437C5.26067 6.95471 5.54735 6.82039 5.80701 6.91436C6.6664 7.22538 7.22062 7.92983 7.56328 8.6107C7.90763 9.29495 8.07255 10.0204 8.12057 10.494C8.14844 10.7687 7.94831 11.014 7.67357 11.0419C7.39884 11.0698 7.15354 10.8696 7.12568 10.5949C7.08804 10.2238 6.95153 9.61962 6.67002 9.06024C6.38681 8.49749 5.99072 8.04432 5.4667 7.85468C5.20704 7.76071 5.07272 7.47403 5.1667 7.21437Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M17.0411 7.81963C16.9649 7.55419 16.6881 7.4007 16.4226 7.47681C15.5605 7.724 14.9794 8.2945 14.6092 8.87723C14.2428 9.45413 14.0646 10.0696 14.012 10.481C13.9769 10.7549 14.1705 11.0053 14.4444 11.0404C14.7183 11.0755 14.9688 10.8818 15.0039 10.6079C15.0397 10.3277 15.1731 9.85466 15.4533 9.41341C15.7299 8.978 16.1308 8.60077 16.6982 8.43808C16.9637 8.36196 17.1172 8.08508 17.0411 7.81963Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M18.6649 2.6665C18.574 2.40575 18.2889 2.26805 18.0282 2.35895C16.1103 3.0275 14.8249 4.56143 13.9994 6.1413C13.1732 7.72266 12.776 9.40783 12.6618 10.4921C12.6329 10.7667 12.8321 11.0128 13.1067 11.0417C13.3813 11.0706 13.6274 10.8714 13.6563 10.5968C13.7592 9.61916 14.1259 8.05871 14.8857 6.60439C15.6464 5.14858 16.7693 3.85679 18.3573 3.30322C18.6181 3.21233 18.7558 2.92726 18.6649 2.6665Z'
                    fill={fillColor}
                />
            </svg>
        </RootIcon>
    )
}

export default LakeIcon
