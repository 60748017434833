import { MicroSite, MicrositeCustomParams } from '../../services/Microsite.types'
import { MicrositeHeaderParamsProps } from './MicrositeHeader.types'
import { ParamsProps } from '../params/Params.types'
import BalconyIcon from '../icon/BalconyIcon'
import BarrierFreeIcon from '../icon/BarrierFreeIcon'
import BrickIcon from '../icon/BrickIcon'
import CellarIcon from '../icon/CellarIcon'
import CooperativeHouseIcon from '../icon/CooperativeHouseIcon'
import CustomIcon from '../icon/CustomIcon'
import ForestIcon from '../icon/ForestIcon'
import GarageIcon from '../icon/GarageIcon'
import GardenIcon from '../icon/GardenIcon'
import HouseIcon from '../icon/HouseIcon'
import HouseOtherIcon from '../icon/HouseOtherIcon'
import HousePersonalIcon from '../icon/HousePersonalIcon'
import LakeIcon from '../icon/LakeIcon'
import LayersIcon from '../icon/LayersIcon'
import LiftIcon from '../icon/LiftIcon'
import LoggiaIcon from '../icon/LoggiaIcon'
import NationalHouseIcon from '../icon/NationalHouseIcon'
import NatureIcon from '../icon/NatureIcon'
import PanelIcon from '../icon/PanelIcon'
import Param from '../params/Param'
import Params from '../params/Params'
import ParkingIcon from '../icon/ParkingIcon'
import React from 'react'
import SkiIcon from '../icon/SkiIcon'
import SquareMetersIcon from '../icon/SquareMetersIcon'
import TerraceIcon from '../icon/TerraceIcon'

const MicrositeHeaderParams = ({
    info: propInfo,
    paramsBgColor,
    iconSize = 24,
    iconColor = 'text-blue',
    ...props
}: MicrositeHeaderParamsProps) => {
    if (!propInfo.params) {
        return
    }

    const { params, customParams, ...info } = propInfo
    const iconProps = {
        size: iconSize,
        color: iconColor
    }

    const renderOwnershipIcon = (ownership: MicroSite['ownership']['type']) => {
        switch (ownership) {
            case 'cooperative':
                return <CooperativeHouseIcon {...iconProps} />
            case 'personal':
                return <HousePersonalIcon {...iconProps} />
            case 'state_municipal':
                return <NationalHouseIcon {...iconProps} />
            case 'other':
                return <HouseOtherIcon {...iconProps} />
            default:
                return null
        }
    }

    const renderBuildingIcon = (building: MicroSite['building']['type']) => {
        switch (building) {
            case 'brick':
                return <BrickIcon {...iconProps} />
            case 'panel':
                return <PanelIcon {...iconProps} />
            case 'other':
                return <LayersIcon {...iconProps} />
            case 'wooden':
                return <LayersIcon {...iconProps} />
            case 'mixed':
                return <LayersIcon {...iconProps} />
            case 'assembled':
                return <LayersIcon {...iconProps} />
            case 'modular':
                return <LayersIcon {...iconProps} />
            case 'ytong':
                return <LayersIcon {...iconProps} />
            case 'stone':
                return <LayersIcon {...iconProps} />
            case 'poroterm':
                return <LayersIcon {...iconProps} />
            default:
                return null
        }
    }

    return (
        <Params {...(props as ParamsProps)}>
            {info.area > 0 && (
                <Param icon={<SquareMetersIcon {...iconProps} />} bgColor={paramsBgColor}>
                    {info.area} m2
                </Param>
            )}
            {info.disposition && (
                <Param icon={<HouseIcon {...iconProps} />} bgColor={paramsBgColor}>
                    {info.disposition}
                </Param>
            )}
            {info.ownership && info.ownership.type && (
                <Param icon={renderOwnershipIcon(info.ownership.type)} bgColor={paramsBgColor}>
                    {info.ownership.label}
                </Param>
            )}
            {info.building && (
                <Param icon={renderBuildingIcon(info.building.type)} bgColor={paramsBgColor}>
                    {info.building.label}
                </Param>
            )}
            {params.hasBalcony && (
                <Param icon={<BalconyIcon {...iconProps} />} bgColor={paramsBgColor}>
                    Balkón
                </Param>
            )}
            {params.hasTerrace && (
                <Param icon={<TerraceIcon {...iconProps} />} bgColor={paramsBgColor}>
                    Terasa
                </Param>
            )}
            {params.hasLoggia && (
                <Param icon={<LoggiaIcon {...iconProps} />} bgColor={paramsBgColor}>
                    Lodžie
                </Param>
            )}
            {params.hasCellar && (
                <Param icon={<CellarIcon {...iconProps} />} bgColor={paramsBgColor}>
                    Sklep
                </Param>
            )}
            {params.hasGarden && (
                <Param icon={<GardenIcon {...iconProps} />} bgColor={paramsBgColor}>
                    Zahrada
                </Param>
            )}
            {params.hasParking && (
                <Param icon={<ParkingIcon {...iconProps} />} bgColor={paramsBgColor}>
                    Parkování
                </Param>
            )}
            {params.hasGarage && (
                <Param icon={<GarageIcon {...iconProps} />} bgColor={paramsBgColor}>
                    Garáž
                </Param>
            )}
            {params.hasLift && (
                <Param icon={<LiftIcon {...iconProps} />} bgColor={paramsBgColor}>
                    Výtah
                </Param>
            )}
            {params.hasBarrierfree && (
                <Param icon={<BarrierFreeIcon {...iconProps} />} bgColor={paramsBgColor}>
                    Bezbariérový
                </Param>
            )}
            {params.nearLake && (
                <Param icon={<LakeIcon {...iconProps} />} bgColor={paramsBgColor}>
                    U jezera
                </Param>
            )}
            {params.nearForest && (
                <Param icon={<ForestIcon {...iconProps} />} bgColor={paramsBgColor}>
                    U lesa
                </Param>
            )}
            {params.nearNature && (
                <Param icon={<NatureIcon {...iconProps} />} bgColor={paramsBgColor}>
                    U přírody
                </Param>
            )}
            {params.nearSki && (
                <Param icon={<SkiIcon {...iconProps} />} bgColor={paramsBgColor}>
                    U sjezdovky
                </Param>
            )}
            {customParams.map((param: MicrositeCustomParams, index: number) => {
                if (!param?.value) {
                    return null
                }
                return (
                    <Param key={index.toString()} icon={<CustomIcon {...iconProps} />} bgColor={paramsBgColor}>
                        {param.value}
                    </Param>
                )
            })}
        </Params>
    )
}

export default MicrositeHeaderParams
