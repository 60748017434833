import { getColorFromString } from '../../utils/Helper'
import tw, { styled } from 'twin.macro'

export const InfoBadgeTitle = styled.span<{ $isForProperty?: boolean }>`
    ${tw`block mb-3 text-basic-primary`}
    ${({ $isForProperty }) => {
        return $isForProperty ? tw`text-left` : tw`lg:text-right`
    }}
`

export const InfoBadgeContent = styled.span<{ $color: string; $bgColor: string }>`
    ${tw`px-3 py-1.5 rounded-2xl`}
    background-color: ${({ theme, $bgColor }) => {
        return getColorFromString(theme, $bgColor)
    }};
    color: ${({ theme, $color }) => {
        return getColorFromString(theme, $color)
    }};
`
