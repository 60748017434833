import { MicrositeHeaderEnergyInfoProps } from './MicrositeHeader.types'
import InfoBadge from '../info-badge/InfoBadge'
import React from 'react'

const MicrositeHeaderEnergyInfo = ({
    isForProperty,
    title = 'Energetický štítek',
    category,
    label
}: MicrositeHeaderEnergyInfoProps) => {
    const sharedProps = {
        isForProperty,
        title
    }

    switch (category) {
        case 'a':
            return (
                <InfoBadge {...sharedProps} color='white' bgColor='success-400'>
                    {label}
                </InfoBadge>
            )
        case 'b':
            return (
                <InfoBadge {...sharedProps} color='success-400' bgColor='success-200'>
                    {label}
                </InfoBadge>
            )
        case 'c':
            return (
                <InfoBadge {...sharedProps} color='success-100' bgColor='success-400'>
                    {label}
                </InfoBadge>
            )
        case 'd':
            return (
                <InfoBadge {...sharedProps} color='warning-400' bgColor='warning-100'>
                    {label}
                </InfoBadge>
            )
        case 'e':
            return (
                <InfoBadge {...sharedProps} color='error-400' bgColor='error-100'>
                    {label}
                </InfoBadge>
            )
        case 'f':
            return (
                <InfoBadge {...sharedProps} color='error-400' bgColor='error-200'>
                    {label}
                </InfoBadge>
            )
        case 'g':
            return (
                <InfoBadge {...sharedProps} color='white' bgColor='error-400'>
                    {label}
                </InfoBadge>
            )
        case 'undefined':
            return (
                <InfoBadge {...sharedProps} color='white' bgColor='error-400'>
                    {label}
                </InfoBadge>
            )
        default:
            return null
    }
}

export default MicrositeHeaderEnergyInfo
