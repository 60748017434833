import { extractImageUrl } from '../utils/Helper'
import ImageViewer from '../components/image-viewer/ImageViewer'
import React, { useCallback, useMemo, useState } from 'react'

export type GalleryViewerResolution = {
    resolution: 'sd' | 'hd' | 'full-hd' | '4k'
    width: 640 | 1280 | 1920 | 3840
    height: 480 | 720 | 1080 | 2160
}

const viewerResolutions: GalleryViewerResolution[] = [
    {
        resolution: 'sd',
        width: 640,
        height: 480
    },
    {
        resolution: 'hd',
        width: 1280,
        height: 720
    },
    {
        resolution: 'full-hd',
        width: 1920,
        height: 1080
    },
    {
        resolution: '4k',
        width: 3840,
        height: 2160
    }
]

const useGallery = (
    images: string[],
    viewerResolution: GalleryViewerResolution['resolution'] = viewerResolutions[1].resolution
) => {
    const [currentImage, setCurrentImage] = useState<number>()

    const closeViewer = () => {
        return setCurrentImage(undefined)
    }

    const getResolutionParams = useCallback((resolution: GalleryViewerResolution['resolution']) => {
        return viewerResolutions.find(({ resolution: resolutionItem }) => {
            return resolutionItem === resolution
        })
    }, [])

    const imagesViewer = useMemo<string[]>(() => {
        return images.map(previewImage => {
            return extractImageUrl(previewImage, getResolutionParams(viewerResolution))
        })
    }, [images, viewerResolution])

    const renderImageViewer = () => {
        if (typeof currentImage !== 'undefined') {
            return <ImageViewer currentIndex={currentImage} src={imagesViewer} onClose={closeViewer} />
        }
    }

    return { currentImage, setCurrentImage, closeViewer, getResolutionParams, renderImageViewer }
}

export default useGallery
