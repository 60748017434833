import { InteractiveModelSvgProps } from './InteractiveModel.types'
import React from 'react'

export const InteractiveModelSvg = ({
    imageData,
    polygonsData,
    devProject,
    handlePolygonClick,
    setHoveredPropertyId,
    lastHoveredProperty
}: InteractiveModelSvgProps) => {
    return (
        <svg
            className='absolute top-0 left-0 w-full h-full rounded-2xl'
            style={{ pointerEvents: 'none' }}
            preserveAspectRatio='xMidYMid meet'
            viewBox={`0 0 ${imageData.width} ${imageData.height}`}
        >
            {polygonsData.map((polygon, index) => {
                const correspondingDevProject = devProject?.find(dp => dp.id === polygon.idPropertyMicrosite)

                let hoverClass = 'hover:fill-blue-500/50'
                let fillClass = 'fill-transparent'

                if (correspondingDevProject) {
                    switch (correspondingDevProject.state) {
                        case 0:
                            hoverClass = 'hover:fill-green-400/50'
                            fillClass = 'fill-green-400/50'
                            break
                        case 1:
                            hoverClass = 'hover:fill-yellow-500/50'
                            fillClass = 'fill-yellow-500/50'
                            break
                        case 2:
                            hoverClass = 'hover:fill-red-400/50'
                            fillClass = 'fill-red-400/50'
                            break
                        case 3:
                            hoverClass = 'hover:fill-blue-200/50'
                            fillClass = 'fill-blue-200/50'
                            break
                        case 4:
                            hoverClass = 'hover:fill-blue-700/50'
                            fillClass = 'fill-blue-700/50'
                            break
                    }
                }

                const finalFillClass =
                    lastHoveredProperty && polygon.idPropertyMicrosite === lastHoveredProperty.id
                        ? fillClass
                        : 'fill-transparent'

                return (
                    <polygon
                        key={index}
                        id={polygon.id}
                        points={polygon.points}
                        onClick={() => handlePolygonClick(polygon.fileUrl, polygon.idPropertyMicrosite)}
                        onMouseEnter={() => setHoveredPropertyId(polygon.idPropertyMicrosite)}
                        onMouseLeave={() => setHoveredPropertyId(null)}
                        className={`stroke-current text-transparent ${finalFillClass} ${hoverClass} cursor-pointer pointer-events-auto`}
                    />
                )
            })}
        </svg>
    )
}
