import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const TwitterIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M3.53413 13.6325C3.96962 16.3131 6.63831 16.9899 7.91821 16.9933C7.91821 16.9933 4.98499 19.2076 1 19.0822C9.11697 22.8194 14.349 20.5369 17.9674 16.9933C20.9229 13.8138 21.6575 8.96537 21.6934 6.72327C22.8492 5.63677 21.8747 6.52175 24 4.18138C23.0714 4.75546 21.8661 4.92578 21.3795 4.93917C22.0918 4.019 21.3443 4.93917 23.3845 2.32717C23.3845 2.32717 20.9621 3.34191 20.4017 3.46378C19.7492 2.69341 17.7722 1.38758 15.0837 2.32717C12.3952 3.26675 12.1058 6.43023 12.2971 7.89452C12.2971 7.89452 7.73009 7.98275 2.55058 2.80134C0.985985 5.80874 2.87009 8.38101 4.00772 9.29122C4.00772 9.29122 2.86224 9.17708 1.85997 8.69546C1.63088 11.3396 4.28694 13.022 5.64361 13.5327C5.64361 13.5327 4.3023 13.6325 3.53413 13.6325Z'
                fill={getColorFromString(theme, color)}
            />
        </RootIcon>
    )
}

export default TwitterIcon
