import { Content, FooterLink, FooterLinks, TitleBorder, TitleContainer } from './Footer.styles'
import { FooterLinksProps } from './Footer.types'
import { MicroSiteFooterMenuItem } from '../../services/Microsite.types'
import Column from '../grid/column/Column'
import FooterContact from './FooterContact'
import React from 'react'
import Row from '../grid/row/Row'
import Typography from '../typography/Typography'

const FooterLinkList = ({
    leftLinks,
    rightLinks,
    companyName,
    contact,
    socials,
    shouldDisplayContactHeader
}: FooterLinksProps) => {
    const renderLinks = (links: MicroSiteFooterMenuItem[]) => (
        <Column cols={12} lg={6} paddingY={false}>
            <FooterLinks className='text-body-small'>
                {links.map(({ url, label, newWindow }, index) => (
                    <li key={index}>
                        <FooterLink href={url} title={label} target={newWindow ? '_blank' : '_self'}>
                            {label}
                        </FooterLink>
                    </li>
                ))}
            </FooterLinks>
        </Column>
    )

    const renderCompanyNames = () =>
        companyName === 'RE/MAX Delux' ? 'RE/MAX DELUX Realitní kancelář v Brně' : companyName

    return (
        <>
            <div
                className={`justify-center items-center lg:justify-between lg:items-start ${shouldDisplayContactHeader ? 'flex flex-col' : ''}`}
            >
                <TitleContainer shouldDisplayContactHeader={shouldDisplayContactHeader}>
                    {!shouldDisplayContactHeader && (
                        <FooterContact
                            contact={contact}
                            socials={socials}
                            shouldDisplayContactHeader={shouldDisplayContactHeader}
                            companyName={companyName}
                        />
                    )}
                    <Typography type='bold' className='text-basic-white'>
                        {shouldDisplayContactHeader && renderCompanyNames()}
                    </Typography>
                </TitleContainer>

                <TitleBorder shouldDisplayContactHeader={shouldDisplayContactHeader} />
            </div>
            <Content>
                <Row wrap={true}>
                    {renderLinks(leftLinks)}
                    {renderLinks(rightLinks)}
                </Row>
            </Content>
        </>
    )
}

export default FooterLinkList
