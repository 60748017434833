import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const PanelIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2.3335 6.49998C2.3335 6.22384 2.55735 5.99998 2.8335 5.99998H21.8336C22.1097 5.99998 22.3336 6.22384 22.3336 6.49999V6.83337C22.3336 7.1095 22.102 7.32446 21.8451 7.42568C21.3504 7.6206 21.0003 8.10275 21.0003 8.66666C21.0003 9.23058 21.3504 9.71273 21.8451 9.90764C22.102 10.0089 22.3336 10.2238 22.3336 10.5V10.8333C22.3336 11.1095 22.1097 11.3333 21.8336 11.3333H2.8335C2.55735 11.3333 2.3335 11.1095 2.3335 10.8333V10.4999C2.3335 10.2238 2.56501 10.0088 2.82189 9.90761C3.31653 9.71268 3.66657 9.23055 3.66657 8.66666C3.66657 8.10278 3.31653 7.62065 2.82189 7.42571C2.56501 7.32448 2.3335 7.10953 2.3335 6.83342V6.49998ZM18.3335 10C19.0699 10 19.6668 9.40304 19.6668 8.66666C19.6668 7.93028 19.0699 7.33333 18.3335 7.33333C17.5971 7.33333 17.0001 7.93028 17.0001 8.66666C17.0001 9.40304 17.5971 10 18.3335 10ZM7.66678 8.66666C7.66678 9.40304 7.06982 10 6.33344 10C5.59706 10 5.00011 9.40304 5.00011 8.66666C5.00011 7.93028 5.59706 7.33333 6.33344 7.33333C7.06982 7.33333 7.66678 7.93028 7.66678 8.66666Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2.3335 13.8333C2.3335 13.5572 2.55735 13.3333 2.8335 13.3333H21.8336C22.1097 13.3333 22.3336 13.5572 22.3336 13.8333V14.1667C22.3336 14.4428 22.102 14.6578 21.8451 14.759C21.3504 14.9539 21.0003 15.4361 21.0003 16C21.0003 16.5639 21.3504 17.0461 21.8451 17.241C22.102 17.3422 22.3336 17.5572 22.3336 17.8333V18.1667C22.3336 18.4428 22.1097 18.6667 21.8336 18.6667H2.8335C2.55735 18.6667 2.3335 18.4428 2.3335 18.1667V17.8333C2.3335 17.5572 2.56501 17.3422 2.82189 17.241C3.31653 17.046 3.66657 16.5639 3.66657 16C3.66657 15.4361 3.31653 14.954 2.82189 14.7591C2.56501 14.6578 2.3335 14.4429 2.3335 14.1668V13.8333ZM18.3335 17.3334C19.0699 17.3334 19.6668 16.7364 19.6668 16C19.6668 15.2636 19.0699 14.6667 18.3335 14.6667C17.5971 14.6667 17.0001 15.2636 17.0001 16C17.0001 16.7364 17.5971 17.3334 18.3335 17.3334ZM7.66678 16C7.66678 16.7364 7.06982 17.3334 6.33344 17.3334C5.59706 17.3334 5.00011 16.7364 5.00011 16C5.00011 15.2636 5.59706 14.6667 6.33344 14.6667C7.06982 14.6667 7.66678 15.2636 7.66678 16Z'
                fill={fillColor}
            />
        </RootIcon>
    )
}

export default PanelIcon
