import { MenuItem, NavbarMobileMenuProps } from './Navbar.types'
import { NavbarMobileMenuCloseButtonRoot, NavbarMobileMenuRoot } from './Navbar.styles'
import CloseIcon from '../../components/icon/CloseIcon'
import NavbarMenuItem from './NavbarMenuItem'
import React, { HTMLAttributes } from 'react'

const NavbarMobileMenu = ({ isOpen, onClose, items, ...props }: NavbarMobileMenuProps) => {
    const renderItems = () => {
        return items.map((item: MenuItem, index: number) => {
            if (!item) {
                return null
            }
            return (
                <NavbarMenuItem
                    key={item?.key ? item.key : index.toString()}
                    scrollId={item.scrollId}
                    onClick={onClose}
                >
                    {item?.name}
                </NavbarMenuItem>
            )
        })
    }

    return (
        <NavbarMobileMenuRoot {...(props as HTMLAttributes<HTMLDivElement>)} $isMenuOpen={isOpen}>
            <NavbarMobileMenuCloseButtonRoot onClick={onClose}>
                <CloseIcon color='basic-primary' size={32} />
            </NavbarMobileMenuCloseButtonRoot>
            <div className='flex flex-col items-center justify-center space-y-12 mt-20'>{renderItems()}</div>
        </NavbarMobileMenuRoot>
    )
}

export default NavbarMobileMenu
