import { FooterDactylGroupLogo } from './Footer.styles'
import DactylGroupLogo from '../logo/DactylGroupLogo'
import React from 'react'

const FooterDactylGroup = () => {
    return (
        <div className='flex flex-col lg:flex-row items-center text-basic-footer'>
            <a className='hover:text-basic-white' href='https://dactylgroup.com/cs' target='_blank' rel='noreferrer'>
                <FooterDactylGroupLogo>
                    <DactylGroupLogo />
                </FooterDactylGroupLogo>
            </a>
            <p className='px-1.5 block '>
                <span className='hidden lg:inline-block mx-1'>|</span>
                <a
                    className='hover:text-basic-white'
                    href='https://www.dactylgroup.com/cs/weby-a-eshopy'
                    target='_blank'
                    rel='noreferrer'
                >
                    Vývoj webů
                </a>
                {' · '}
                <a
                    className='hover:text-basic-white'
                    href='https://www.dactylgroup.com/cs/vyvoj-mobilnich-aplikaci'
                    target='_blank'
                    rel='noreferrer'
                >
                    Vývoj mobilních aplikací
                </a>
            </p>
        </div>
    )
}

export default FooterDactylGroup
