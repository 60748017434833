import { AxiosError } from 'axios'
import { DefaultTheme } from 'styled-components'
import {
    ImageElementData,
    PolygonData,
    SvgParseResult,
    svgChildrenProps
} from '../components/interactive-model/InteractiveModel.types'
import { Redirect } from 'next/types'
import { parse } from 'svg-parser'
import axios from 'axios'

export const pxToRems = (pixels: number) => {
    return 0.0625 * pixels
}

export const getColorFromString = (theme: DefaultTheme, color: string): string => {
    const colorData = color.split('-')
    let themeColor = theme.colors[colorData[0]]
    if (colorData.length > 1 && typeof themeColor === 'object') {
        themeColor = themeColor[colorData[1]]
    }
    return themeColor || color
}

export const removePropertiesFromObject = (obj: object, ...properties: string[]) => {
    return Object.fromEntries(
        Object.entries(obj).filter(([key]) => {
            return !properties.includes(key)
        })
    )
}

export const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const convertHexToRGBA = (hexCode: string, opacity = 1) => {
    let hex = hexCode.replace('#', '')

    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
    }

    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
        opacity = opacity / 100
    }

    return `rgba(${r},${g},${b},${opacity})`
}

export const buildClasses = (className: string | string[] | { [key: string]: boolean }) => {
    return buildClassesWithDefault(className)
}

type BuildClassCallback = () => { [key: string]: boolean }

export const buildClassesWithDefault = (
    className: string | string[] | { [key: string]: boolean } | BuildClassCallback,
    defaultClassName?: string
) => {
    if (defaultClassName) {
        defaultClassName = defaultClassName.trim()
    }
    if (typeof className === 'string') {
        return className + (defaultClassName && defaultClassName.length ? ` ${defaultClassName}` : '')
    }
    if (Array.isArray(className)) {
        return className.join(' ')
    }
    return (
        Object.entries(typeof className === 'function' ? className() : className)
            .filter(([, value]) => {
                return value
            })
            .map(([key]) => {
                return key
            })
            .join(' ') + (defaultClassName && defaultClassName.length > 0 ? ` ${defaultClassName}` : '')
    )
}

export const chunkArray = <D = object>(array: D[], chunkSize: number = 10): [D[]] => {
    return array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize)

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, []) as unknown as [D[]]
}

export const getParameterByName = (name: string, url = window.location.href) => {
    // NOTE: Escape square brackets in the parameter name using a regex
    name = name.replace(/[\[\]]/g, '\\$&')

    // NOTE: Construct a regex to search for the parameter in the URL
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)

    // NOTE: Execute the regex against the URL to find the parameter value
    const results = regex.exec(url)

    // NOTE: If the parameter is not found, return null
    if (!results) {
        return null
    }

    // NOTE: If the parameter is found but has no value, return an empty string
    if (!results[2]) {
        return ''
    }

    // NOTE: Decode the parameter value and replace any "+" characters with spaces
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const getNormalizedPrice = (price: number | string, currency?: string) => {
    if (price === undefined || price === null) {
        return
    }
    let newPrice = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    if (currency) {
        newPrice = `${newPrice} ${currency}`
    }
    return newPrice
}

export const getCurrencySymbol = (currency: string | null): string => {
    const currencySymbols: { [key: string]: string } = {
        czk: 'Kč',
        eur: '€'
    }

    return currencySymbols[currency] || ''
}

export const extractImageUrl = (imageUrl: string, { width, height } = { width: 640, height: 480 }) => {
    if (!imageUrl || !imageUrl.length) {
        return imageUrl
    }
    const baseUrl = new URL(imageUrl).origin
    const imageIdentification = imageUrl.split('/').pop()
    return `${baseUrl}/cdn/image/${width}/${height}/${imageIdentification}`
}

export const extractDataFromForm = (form: HTMLFormElement) => {
    const extractValueAndNameFromInput = (input: HTMLInputElement) => {
        const name =
            input.labels?.[0]?.textContent.replace('*', '').replace('(nepovinné)', '').trim() || input.name?.trim()
        const value = input.value
        if (!name?.length || !value?.length) {
            return false
        }
        const isSerializableActivated = input.classList.contains('serializableActivator')
        return {
            name,
            value,
            isSerializableActivated
        }
    }

    const inputs = form.querySelectorAll('input[type=text], input[type=email], textarea')
    const inputsData = Array.from(inputs).filter(extractValueAndNameFromInput).map(extractValueAndNameFromInput) as {
        name: string
        value: string
        id: string
        isSerializableActivated: boolean
    }[]
    return {
        id: form.id,
        data: inputsData
    }
}

export const extractDataFromForms = (selector: string) => {
    const forms = document.querySelectorAll(selector)
    return Array.from(forms)
        .filter(item => {
            return extractDataFromForm(item as HTMLFormElement).data.length
        })
        .map(extractDataFromForm)
}

export const getAxiosRedirectUrl = (error: AxiosError) => {
    if (!error.response) {
        return
    }

    const { status, headers } = error.response
    if ((status === 301 || status === 302) && headers) {
        return headers['location']
    }
}

export const buildRedirectServerSideProps = (error: Error): undefined | { redirect: Redirect } => {
    if (axios.isAxiosError(error)) {
        const redirectToUrl = getAxiosRedirectUrl(error)
        if (redirectToUrl) {
            return {
                redirect: {
                    statusCode: 301,
                    destination: redirectToUrl
                }
            }
        }
    }
}

export const extractVideoId = (videoUrl: string): string | null => {
    if (!videoUrl) {
        return null
    }

    const patterns = [
        /youtu\.be\/([^#\&\?]{11})/, // youtu.be/<id>
        /\?v=([^#\&\?]{11})/, // ?v=<id>
        /\&v=([^#\&\?]{11})/, // &v=<id>
        /embed\/([^#\&\?]{11})/, // embed/<id>
        /\/v\/([^#\&\?]{11})/ // /v/<id>
    ]

    for (const pattern of patterns) {
        const match = videoUrl.match(pattern)
        if (match && match[1]) {
            return match[1]
        }
    }

    return null
}

export const parseSvgContent = (svgContent: string) => {
    const parsed = parse(svgContent)

    const svgElement = parsed.children[0] as any

    const svgChildren = (svgElement.children || []).filter(
        (child: PolygonData) => typeof child !== 'string'
    ) as svgChildrenProps[]

    const imageElementData: ImageElementData = svgChildren
        .filter(child => child.tagName === 'image')
        .map(image => ({
            width: image.properties.width,
            height: image.properties.height,
            href: image.properties['xlink:href']
        }))[0]

    const polygonsData: PolygonData[] = svgChildren
        .filter(child => child.tagName === 'polygon')
        .map(polygon => ({
            id: polygon.properties.id,
            points: polygon.properties.points,
            fileUrl: polygon.properties.fileUrl as string,
            idPropertyMicrosite: polygon.properties.idPropertyMicrosite
        }))

    const result: SvgParseResult = { image: imageElementData, polygons: polygonsData }
    return result
}
