import { MortgageCalculatorContainerRoot, MortgageCalculatorInputRoot } from './MortgageCalculator.styles'
import { MortgageCalculatorInputProps } from './MortgageCalculator.types'
import HiddenInput from '../hidden-input/HiddenInput'
import React, { ChangeEvent } from 'react'

const MortgageCalculatorInput = ({
    unit,
    label = '',
    containerClassName,
    value,
    change,
    hiddenLabel,
    hiddenName,
    hiddenValue,
    ...props
}: MortgageCalculatorInputProps) => {
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        event?.preventDefault()
        const convertedNumber = Number(event?.target?.value.replace(/\s/g, ''))
        const isNumber = !isNaN(convertedNumber)
        if (isNumber && change) {
            change(convertedNumber)
        }
    }

    return (
        <MortgageCalculatorContainerRoot className={containerClassName}>
            {hiddenName && hiddenValue && <HiddenInput label={hiddenLabel} name={hiddenName} value={hiddenValue} />}
            <MortgageCalculatorInputRoot
                {...props}
                type='text'
                className='bg-basic-white rounded-full h-10'
                label={label}
                onChange={onChange}
                value={value
                    .toString()
                    .replace(/\s/g, '')
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            />
            <span className='text-text-blue ml-2'>{unit}</span>
        </MortgageCalculatorContainerRoot>
    )
}

export default MortgageCalculatorInput
