import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const SkiIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <svg width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M7.16976 0.877958C7.55806 0.479828 8.19659 0.47499 8.59088 0.867192L13.0141 5.26698C13.2099 5.46172 13.2107 5.7783 13.0159 5.97408C12.8212 6.16986 12.5046 6.1707 12.3088 5.97596L7.88565 1.57617L1.02445 8.61106C0.831642 8.80875 0.515085 8.81271 0.317397 8.6199C0.11971 8.4271 0.115753 8.11054 0.30856 7.91285L7.16976 0.877958Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.7605 2.68845C15.1638 2.25331 15.855 2.26297 16.246 2.70919L22.2393 9.54915C22.4212 9.75684 22.4004 10.0727 22.1927 10.2547C21.985 10.4367 21.6691 10.4159 21.4871 10.2082L15.4939 3.36821L3.584 16.2184C3.39629 16.4209 3.07994 16.4329 2.8774 16.2452C2.67487 16.0575 2.66286 15.7411 2.85057 15.5386L14.7605 2.68845Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M20.2625 16.0955C20.391 16.0955 20.4952 16.1997 20.4952 16.3282L20.4952 17.8798C20.4952 18.0083 20.391 18.1124 20.2625 18.1124C20.134 18.1124 20.0298 18.0083 20.0298 17.8798L20.0298 16.3282C20.0298 16.1997 20.134 16.0955 20.2625 16.0955Z'
                    fill={fillColor}
                />
                <path
                    d='M18.6632 16.6806H21.8597C22.0376 16.6806 22.1497 16.4892 22.0627 16.334L20.4541 13.4677C20.3649 13.3088 20.1359 13.3094 20.0476 13.4688L18.4597 16.3352C18.3737 16.4902 18.4859 16.6806 18.6632 16.6806Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M18.6136 10.473C18.713 10.473 18.7936 10.5536 18.7936 10.653L18.7936 11.8533C18.7936 11.9528 18.713 12.0334 18.6136 12.0334C18.5142 12.0334 18.4336 11.9528 18.4336 11.8533L18.4336 10.653C18.4336 10.5536 18.5142 10.473 18.6136 10.473Z'
                    fill={fillColor}
                />
                <path
                    d='M17.3766 10.9256H19.8495C19.9871 10.9256 20.0738 10.7775 20.0065 10.6575L18.762 8.44003C18.6931 8.31709 18.5159 8.31758 18.4476 8.44089L17.2191 10.6584C17.1526 10.7784 17.2394 10.9256 17.3766 10.9256Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M3.86111 11.0249C3.98962 11.0249 4.0938 11.1291 4.0938 11.2576L4.0938 12.8091C4.0938 12.9376 3.98962 13.0418 3.86111 13.0418C3.7326 13.0418 3.62842 12.9376 3.62842 12.8091L3.62842 11.2576C3.62842 11.1291 3.7326 11.0249 3.86111 11.0249Z'
                    fill={fillColor}
                />
                <path
                    d='M2.26183 11.61H5.45838C5.63624 11.61 5.74834 11.4185 5.6613 11.2634L4.05272 8.39706C3.96353 8.23814 3.73456 8.23877 3.64625 8.39817L2.05829 11.2645C1.97237 11.4196 2.08453 11.61 2.26183 11.61Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M7.38916 7.76865C7.48354 7.76865 7.56005 7.84517 7.56005 7.93955L7.56005 9.07906C7.56005 9.17345 7.48354 9.24996 7.38916 9.24996C7.29477 9.24996 7.21826 9.17345 7.21826 9.07906L7.21826 7.93955C7.21826 7.84517 7.29477 7.76865 7.38916 7.76865Z'
                    fill={fillColor}
                />
                <path
                    d='M6.2146 8.19836H8.56225C8.69289 8.19836 8.77522 8.05775 8.71129 7.94383L7.52989 5.83868C7.46439 5.72197 7.29622 5.72243 7.23137 5.8395L6.06511 7.94465C6.00201 8.05855 6.08438 8.19836 6.2146 8.19836Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.136 6.91346C11.3098 6.69891 11.6247 6.66592 11.8392 6.83977L14.3892 8.90604C15.6804 9.95233 16.1902 11.688 15.6698 13.2664L15.3708 14.1733C15.0549 15.1313 15.2416 16.184 15.8678 16.9749L17.435 18.9543C17.6064 19.1708 17.5699 19.4852 17.3534 19.6567C17.1369 19.8281 16.8224 19.7915 16.651 19.575L15.0838 17.5957C14.2489 16.5411 13.9999 15.1375 14.4211 13.8601L14.7201 12.9533C15.1104 11.7695 14.7281 10.4677 13.7596 9.68299L11.2096 7.61672C10.9951 7.44287 10.9621 7.12801 11.136 6.91346Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M9.6568 8.44908C9.84279 8.24497 10.159 8.23029 10.3631 8.41628L12.1563 10.0503C13.4082 11.1911 13.8059 12.9967 13.1491 14.5579L12.5559 15.9682C12.144 16.9471 12.2805 18.0708 12.9146 18.9228L14.0481 20.4455C14.2129 20.667 14.167 20.9802 13.9455 21.1451C13.724 21.31 13.4108 21.2641 13.2459 21.0426L12.1125 19.5199C11.2669 18.3839 11.085 16.8856 11.6341 15.5804L12.2274 14.1702C12.72 12.9993 12.4217 11.645 11.4827 10.7894L9.6896 9.15543C9.48549 8.96943 9.4708 8.65319 9.6568 8.44908Z'
                    fill={fillColor}
                />
            </svg>
        </RootIcon>
    )
}

export default SkiIcon
