import { MicrositeHeaderPreviewVideoProps } from './MicrositeHeader.types'
import { VideoPlayer } from '../video-player/VideoPlayer'
import { extractVideoId } from '../../utils/Helper'
import { useMemo } from 'react'
import Column from '../grid/column/Column'
import React from 'react'

export const MicrositeHeaderPreviewVideo = ({
    previewVideoUrl,
    contact,
    showBrokerMainCard
}: MicrositeHeaderPreviewVideoProps) => {
    const shouldExpandColumn = !contact || !showBrokerMainCard
    const columnLg = shouldExpandColumn ? 12 : 8
    const columnXl = shouldExpandColumn ? 12 : 9

    const videoId = useMemo(() => {
        return extractVideoId(previewVideoUrl)
    }, [previewVideoUrl])

    if (!videoId) {
        return null
    }

    return (
        <Column cols={12} lg={columnLg} xl={columnXl} className='h-full order-2 lg:order-none'>
            <VideoPlayer videoId={videoId} height={shouldExpandColumn ? undefined : 510} shouldAutoPlay={0} />
        </Column>
    )
}
