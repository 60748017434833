import { ComponentContactBannerProps } from './ComponentManager.types'
import { ComponentManagerComponentRow } from './ComponentManager.styles'
import { ContactFormData, ContactFormRef } from '../contact-form/ContactForm.types'
import { MicroSiteContactFormRequest } from '../../services/Microsite.types'
import { buildClasses } from '../../utils/Helper'
import ArrowDownIcon from '../icon/ArrowDownIcon'
import ArrowUpIcon from '../icon/ArrowUpIcon'
import Button from '../button/Button'
import CardBanner from '../card/banner/CardBanner'
import Column from '../grid/column/Column'
import ContactForm from '../contact-form/ContactForm'
import React, { useRef, useState } from 'react'
import SectionTitle from '../section-title/SectionTitle'
import useMicrositeContactFormPost from '../../hooks/api/shared-microsite/useMicrositeContactFormPost'

const ComponentContactBanner = ({
    component: { title, hash, bannerText, bannerButtonLabel, bannerEmail },
    bgColor,
    ...props
}: ComponentContactBannerProps) => {
    const contactForm = useRef<ContactFormRef>(null)
    const [showForm, setShowForm] = useState<boolean>()
    const { postContactForm: postPropertyMicroSiteContactForm } = useMicrositeContactFormPost()

    const formSubmit = (data: ContactFormData) => {
        let brokerEmail: string | undefined = undefined
        if (bannerEmail?.length) {
            brokerEmail = bannerEmail
        } else {
            brokerEmail = data.brokerEmail
        }
        const requestData: MicroSiteContactFormRequest = {
            name: data.fullName,
            email: data.email,
            phone: data.phone,
            text: data.message,
            checkbox: data.agreeProcessingPersonalData ? 1 : 0,
            brokerEmail
        }
        return postPropertyMicroSiteContactForm(requestData).then(() => {
            return contactForm.current.resetForm()
        })
    }

    const renderActionButton = () => {
        return (
            bannerButtonLabel && (
                <Button
                    color='red'
                    rightIcon={
                        showForm ? (
                            <ArrowUpIcon color='basic-white' size={16} />
                        ) : (
                            <ArrowDownIcon color='basic-white' size={16} />
                        )
                    }
                    onClick={() => {
                        return setShowForm(prevState => {
                            return !prevState
                        })
                    }}
                >
                    {bannerButtonLabel}
                </Button>
            )
        )
    }

    if (bannerText && bannerButtonLabel) {
        return (
            <ComponentManagerComponentRow {...props}>
                <Column cols={12}>
                    <SectionTitle>{title}</SectionTitle>
                </Column>
                <Column cols={12}>
                    <CardBanner action={renderActionButton()}>{bannerText}</CardBanner>
                </Column>
                <Column cols={12} className={buildClasses({ hidden: !showForm })}>
                    <ContactForm
                        ref={contactForm}
                        bgColor={bgColor}
                        id={hash}
                        submit={formSubmit}
                        bannerEmail={bannerEmail}
                    />
                </Column>
            </ComponentManagerComponentRow>
        )
    }
}

export default ComponentContactBanner
