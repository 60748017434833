import { ApiConfig } from './apiConfig.types'

export const apiConfig: ApiConfig = {
    microSiteShared: {
        submitContactForm: '/property-microsite/{slug}/submit-contact-form',
        getSubmitContactFormTerms: '/property-microsite/contact-form-terms'
    },
    propertyMicroSite: {
        get: '/property-microsite/{slug}'
    },
    projectMicroSite: {
        get: '/developer-project-microsite/{slug}',
        getProperty: '/developer-project-property-microsite/{id}'
    },
    mortgageCalculator: {
        post: '/mortgage-calculator/{id}'
    }
}
