import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const NatureIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M6.22179 20.4224C4.44799 20.7151 2.9661 21.7257 2.15515 22.5635C1.96308 22.7619 1.64654 22.767 1.44813 22.575C1.24973 22.3829 1.24459 22.0664 1.43665 21.8679C2.36183 20.9122 4.02876 19.7707 6.05898 19.4357C8.12049 19.0955 10.5106 19.5964 12.8019 21.86C12.9983 22.0541 13.0002 22.3707 12.8062 22.5671C12.6121 22.7636 12.2955 22.7655 12.0991 22.5714C10.0334 20.5307 7.9643 20.1348 6.22179 20.4224Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.1654 18.613C12.3818 18.8831 10.8978 19.8143 10.0901 20.5799C9.88967 20.7699 9.5732 20.7614 9.38323 20.561C9.19325 20.3606 9.2017 20.0442 9.40211 19.8542C10.3306 18.974 11.9954 17.9302 14.0157 17.6243C16.061 17.3146 18.4428 17.7672 20.7365 19.8466C20.9411 20.0321 20.9566 20.3483 20.7711 20.5529C20.5856 20.7575 20.2694 20.773 20.0648 20.5875C18.0016 18.717 15.9242 18.3467 14.1654 18.613Z'
                    fill={fillColor}
                />
                <path d='M13.8188 15.0753L13.3154 17.984H15.5884L14.972 15.0753H13.8188Z' fill={fillColor} />
                <path
                    d='M17.0072 15.3963H11.8386C11.5625 15.3963 11.3374 15.1703 11.4024 14.9019C11.6072 14.0559 12.3563 12.879 14.3689 12.879C16.468 12.879 17.205 13.9853 17.4566 14.8506C17.5396 15.1362 17.3046 15.3963 17.0072 15.3963Z'
                    fill={fillColor}
                />
                <path
                    d='M19.5425 5.26312C19.5425 6.44671 18.583 7.40619 17.3994 7.40619C16.2158 7.40619 15.2563 6.44671 15.2563 5.26312C15.2563 4.07954 16.2158 3.12006 17.3994 3.12006C18.583 3.12006 19.5425 4.07954 19.5425 5.26312Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.6479 5.26312C14.6479 5.53926 14.4241 5.76312 14.1479 5.76312L12.9272 5.76312C12.6511 5.76312 12.4272 5.53926 12.4272 5.26312C12.4272 4.98698 12.6511 4.76312 12.9272 4.76312L14.1479 4.76312C14.4241 4.76312 14.6479 4.98698 14.6479 5.26312Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M22.2041 5.26312C22.2041 5.53926 21.9802 5.76312 21.7041 5.76312L20.4834 5.76312C20.2073 5.76312 19.9834 5.53926 19.9834 5.26312C19.9834 4.98698 20.2073 4.76312 20.4834 4.76312L21.7041 4.76312C21.9802 4.76312 22.2041 4.98698 22.2041 5.26312Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M17.3994 2.68553C17.1233 2.68553 16.8994 2.46167 16.8994 2.18553L16.8994 0.964828C16.8994 0.688686 17.1233 0.464828 17.3994 0.464828C17.6756 0.464828 17.8994 0.688686 17.8994 0.964828L17.8994 2.18553C17.8994 2.46167 17.6756 2.68553 17.3994 2.68553Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M19.3645 3.29457C19.1703 3.09833 19.1719 2.78175 19.3681 2.58748L20.2356 1.72868C20.4319 1.53441 20.7485 1.53601 20.9427 1.73226C21.137 1.9285 21.1354 2.24508 20.9392 2.43936L20.0716 3.29815C19.8754 3.49242 19.5588 3.49082 19.3645 3.29457Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M15.4025 7.08597C15.5968 7.28221 15.5952 7.59879 15.399 7.79306L14.5314 8.65186C14.3352 8.84613 14.0186 8.84453 13.8244 8.64828C13.6301 8.45203 13.6317 8.13546 13.8279 7.94118L14.6954 7.08239C14.8917 6.88812 15.2083 6.88972 15.4025 7.08597Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M15.3708 3.29457C15.5651 3.09833 15.5635 2.78175 15.3672 2.58748L14.4997 1.72868C14.3035 1.53441 13.9869 1.53601 13.7926 1.73226C13.5983 1.9285 13.5999 2.24508 13.7962 2.43936L14.6637 3.29815C14.86 3.49242 15.1765 3.49082 15.3708 3.29457Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M19.3963 7.08597C19.202 7.28221 19.2036 7.59879 19.3999 7.79306L20.2674 8.65186C20.4636 8.84613 20.7802 8.84453 20.9745 8.64828C21.1688 8.45203 21.1672 8.13546 20.9709 7.94118L20.1034 7.08239C19.9071 6.88812 19.5906 6.88972 19.3963 7.08597Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M17.3994 10.1705C17.1233 10.1705 16.8994 9.94667 16.8994 9.67053L16.8994 8.44983C16.8994 8.17369 17.1233 7.94983 17.3994 7.94983C17.6756 7.94983 17.8994 8.17369 17.8994 8.44983L17.8994 9.67053C17.8994 9.94667 17.6756 10.1705 17.3994 10.1705Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M6.84629 8.52424C7.0672 8.52424 7.24629 8.70332 7.24629 8.92424L7.24629 19.8391C7.24629 20.06 7.0672 20.2391 6.84629 20.2391C6.62538 20.2391 6.44629 20.06 6.44629 19.8391L6.44629 8.92424C6.44629 8.70332 6.62538 8.52424 6.84629 8.52424Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M3.90305 13.6993C4.04136 13.527 4.29313 13.4995 4.46539 13.6378L6.87408 15.5718C7.04634 15.7101 7.07386 15.9619 6.93555 16.1341C6.79724 16.3064 6.54547 16.3339 6.37322 16.1956L3.96452 14.2616C3.79226 14.1233 3.76474 13.8715 3.90305 13.6993Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M9.82001 10.316C9.95061 10.4941 9.91203 10.7444 9.73385 10.875L7.24236 12.7011C7.06418 12.8317 6.81386 12.7931 6.68327 12.6149C6.55268 12.4368 6.59126 12.1864 6.76944 12.0559L9.26093 10.2298C9.43911 10.0992 9.68942 10.1378 9.82001 10.316Z'
                    fill={fillColor}
                />
                <path
                    d='M6.81889 9.82588C3.97146 9.29232 3.33869 7.19364 3.64361 5.40945C3.67995 5.19677 3.86258 5.04087 4.07829 5.04528C5.9744 5.0841 8.05576 6.56821 7.34452 9.48805C7.28746 9.72232 7.05589 9.87029 6.81889 9.82588Z'
                    fill={fillColor}
                />
                <path
                    d='M5.06016 15.0477C2.30789 15.0593 1.33075 13.2177 1.28549 11.493C1.27983 11.2773 1.42946 11.0892 1.64219 11.0532C3.42519 10.7516 5.63472 11.7632 5.51094 14.6161C5.50049 14.857 5.30128 15.0467 5.06016 15.0477Z'
                    fill={fillColor}
                />
                <path
                    d='M8.22707 11.2069C8.31286 8.44374 10.1963 7.52904 11.9287 7.54555C12.1444 7.54761 12.3271 7.7038 12.3556 7.91767C12.5954 9.71755 11.5017 11.8995 8.64246 11.6728C8.40209 11.6537 8.21958 11.4479 8.22707 11.2069Z'
                    fill={fillColor}
                />
            </svg>
        </RootIcon>
    )
}

export default NatureIcon
