import { styled } from 'twin.macro'

export const TextFieldValidationMessageRoot = styled.strong`
    font-size: 1rem;
    line-height: 2rem;
    letter-spacing: 0.004em;
    color: ${({ theme: { colors } }) => {
        return colors.error['400']
    }};
`
