import { MicroSiteDetailComponentPropertiesItem } from '../../../services/Microsite.types'
import ComponentManager from '../ComponentManager'
import Loading from '../../../assets/img/loading.svg'
import MicrositeHeader from '../../microsite-header/MicrositeHeader'
import React from 'react'
import useMicrositePageMetadata from '../../../hooks/useMicrositePageMetadata'
import useProjectPropertyMicrosite from '../../../hooks/api/project-microsite/useProjectPropertyMicrosite'

export type ComponentPropertiesTableExpansionProps = {
    data: MicroSiteDetailComponentPropertiesItem
}

const ComponentPropertiesTableExpansion = ({ data }: ComponentPropertiesTableExpansionProps) => {
    const { data: property, isLoading } = useProjectPropertyMicrosite(data.id)
    const { header, contact } = useMicrositePageMetadata({ data: property, slug: property?.slug })

    const modifiedContact = { ...contact, showCard: false }

    const renderLoading = () => {
        return (
            isLoading && (
                <div role='status' className='flex justify-center items-center w-full'>
                    <Loading className='w-10 h-10 mr-2 text-secondaryBlue-100 animate-spin fill-primary' />
                    <span className='sr-only'>Loading...</span>
                </div>
            )
        )
    }

    return (
        <div className='block w-full overflow-hidden text-left'>
            {renderLoading()}
            {property && (
                <>
                    <MicrositeHeader
                        paramsFullWidth
                        previewVideoUrl={property?.previewVideoUrl}
                        contact={modifiedContact}
                        info={header}
                        paramsBgColor='white'
                        type='project'
                    />
                    <ComponentManager
                        components={property?.content}
                        brokerEmail={property?.broker?.brokerEmail || ''}
                        type='property'
                        descriptionsCustomCols={12}
                    />
                </>
            )}
        </div>
    )
}

export default ComponentPropertiesTableExpansion
