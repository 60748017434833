import { TextareaProps } from './Textarea.types'
import { TextareaRoot } from './Textarea.styles'
import React, { ForwardedRef, forwardRef, useId } from 'react'
import TextFieldValidationMessage from '../text-field-validation-message/TextFieldValidationMessage'

const Textarea = (
    { label, id: idProp, containerClassName, required, invalidMessage, ...props }: TextareaProps,
    ref: ForwardedRef<HTMLTextAreaElement>
) => {
    const id = useId()

    return (
        <TextareaRoot className={containerClassName}>
            <label htmlFor={idProp || id}>
                {label} {required ? <span className='text-error-400'>*</span> : null}
            </label>
            <textarea {...props} ref={ref} id={idProp || id} required={required} />
            {invalidMessage && <TextFieldValidationMessage message={invalidMessage} />}
        </TextareaRoot>
    )
}

export default forwardRef(Textarea)
