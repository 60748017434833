import { MicroSiteContactFormRequest } from '../../../services/Microsite.types'
import { postMicroSiteContactForm } from '../../../services/MicrositeService'
import { useAxios } from '../../useAxios'
import { useCallback, useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useRouter } from 'next/router'
import toast from 'react-hot-toast'

const useMicrositeContactFormPost = () => {
    const {
        query: { id: slug }
    } = useRouter()
    const { executeRecaptcha } = useGoogleReCaptcha()
    const axios = useAxios()
    const [isLoading, setLoading] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const [formData, setFormData] = useState<MicroSiteContactFormRequest | null>(null)
    const [isFormSent, setFormSent] = useState(true)

    const postContactForm = useCallback(
        async (data: MicroSiteContactFormRequest) => {
            setLoading(true)
            setNotFound(false)
            setFormData(data)

            const submitFormWithToken = async (token: string) => {
                return postMicroSiteContactForm(axios, slug.toString(), data, token)
            }
            try {
                const googleRecaptchaToken = await executeRecaptcha('contact_broker')
                const response = await submitFormWithToken(googleRecaptchaToken)

                setNotFound(false)
                return response
            } catch (e) {
                if (e.response && e.response.status === 422) {
                    const newToken = await executeRecaptcha('contact_broker')
                    await submitFormWithToken(newToken)
                } else {
                    setFormSent(false)
                }
            } finally {
                setLoading(false)
                toast.success('Kontaktní formulář byl úspěšně odeslán.')
            }
        },
        [axios, executeRecaptcha, slug]
    )

    useEffect(() => {
        const handleExit = async (event: BeforeUnloadEvent) => {
            if (!isFormSent && formData) {
                event.preventDefault()
                await postContactForm(formData)
            }
        }

        window.addEventListener('beforeunload', handleExit)

        return () => {
            window.removeEventListener('beforeunload', handleExit)
        }
    }, [formData, isFormSent, postContactForm])

    return {
        isLoading,
        notFound,
        postContactForm
    }
}

export default useMicrositeContactFormPost
