import { ImageIconContainer, ImageOverlay, ImageRoot, Image as StyledImage } from './Image.styles'
import { ImageProps } from './Image.types'
import NextImage from 'next/image'
import React from 'react'
import SearchIcon from '../icon/SearchIcon'
import useHover from '../../hooks/useHover'

const Image = ({
    src,
    alt = 'Image',
    width = 266,
    height = 266,
    icon,
    imageIconSize,
    next,
    rounded = true,
    onClick,
    aspectRatio,
    ...props
}: ImageProps) => {
    const [ref, hovering] = useHover<HTMLDivElement>()

    return (
        <ImageRoot
            {...props}
            ref={ref}
            $width={width}
            $height={height}
            $rounded={rounded}
            $clickable={!!onClick}
            onClick={onClick}
        >
            {icon && <ImageIconContainer $size={imageIconSize}>{icon}</ImageIconContainer>}
            <StyledImage
                $rounded={rounded}
                $aspectRatio={aspectRatio}
                src={src}
                alt={alt}
                as={next ? NextImage : 'img'}
            />
            {onClick && (
                <ImageOverlay $rounded={rounded} className={hovering ? 'fadeIn' : 'fadeOut'}>
                    <SearchIcon size={64} />
                </ImageOverlay>
            )}
        </ImageRoot>
    )
}

export default Image
