import { ComponentFloorPlanProps } from './ComponentManager.types'
import { ComponentManagerComponentRow } from './ComponentManager.styles'
import { Element as ScrollElement } from 'react-scroll'
import Column from '../grid/column/Column'
import ImageBlock from '../image-block/ImageBlock'
import React from 'react'
import SectionTitle from '../section-title/SectionTitle'

const ComponentFloorPlan = ({ component: { title, images, hash }, ...props }: ComponentFloorPlanProps) => {
    return (
        images.length && (
            <ScrollElement name={hash}>
                <ComponentManagerComponentRow {...props}>
                    <Column cols={12}>
                        <SectionTitle>{title}</SectionTitle>
                    </Column>
                    <Column cols={12}>
                        <ImageBlock images={images} />
                    </Column>
                </ComponentManagerComponentRow>
            </ScrollElement>
        )
    )
}

export default ComponentFloorPlan
