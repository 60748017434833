import { ImageElementData, InteractiveModelProps, PolygonData } from './InteractiveModel.types'
import { InteractiveModelSvg } from './InteractiveModelSvg'

import { StandardHeaders } from '../component-manager/component-properties-table/StandardHeaders'
import { parseSvgContent } from '../../utils/Helper'
import { useEffect, useMemo, useState } from 'react'
import { useInteractiveModel } from '../../hooks/api/interactive-model/useInteractiveModel'
import { useRouter } from 'next/router'
import ArrowLeftIcon from '../icon/ArrowLeftIcon'
import InteractiveModelTable from './interactive-model-table/InteractiveModelTable'
import useWindowSize from '../../hooks/useWindowSize'

export const InteractiveModel = ({ fileUrl, devProject }: InteractiveModelProps) => {
    const [currentFileUrl, setCurrentFileUrl] = useState(fileUrl)
    const [imageData, setImageData] = useState<ImageElementData>()
    const [polygonsData, setPolygons] = useState<PolygonData[]>([])
    const [hoveredPropertyId, setHoveredPropertyId] = useState(null)
    const [showDefaultTable, setShowDefaultTable] = useState(false)
    const [lastHoveredProperty, setLastHoveredProperty] = useState(null)
    const { data: interactiveModel, isLoading } = useInteractiveModel(currentFileUrl)
    const { width } = useWindowSize()
    const router = useRouter()

    useEffect(() => {
        if (interactiveModel && !isLoading) {
            const { image, polygons } = parseSvgContent(interactiveModel)
            setImageData(image)
            setPolygons(polygons)
        }
    }, [interactiveModel, isLoading])

    const isTableForSmallDevice = useMemo(() => width <= 800, [width])

    const displayedCols = ['name', 'unitType', 'disposition', 'area', 'price', 'price_discounted', 'state']

    const standardHeaders = useMemo(() => {
        return StandardHeaders(displayedCols, isTableForSmallDevice, false)
    }, [displayedCols, isTableForSmallDevice])

    const hoveredProperty = useMemo(() => {
        const property = devProject?.find(devProperty => devProperty.id === hoveredPropertyId) || null
        if (property) {
            setLastHoveredProperty(property)
        }
        return lastHoveredProperty
    }, [hoveredPropertyId, lastHoveredProperty, devProject])

    const backToBuildingImage = () => {
        setCurrentFileUrl(fileUrl)
        setShowDefaultTable(false)
        setLastHoveredProperty(null)
        setHoveredPropertyId(null)
    }

    const handleClickOutside = event => {
        if (!event.target.closest('polygon') && !event.target.closest('table')) {
            setHoveredPropertyId(null)
            setLastHoveredProperty(null)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    const renderRowForSmallDevice = () => {
        return (
            <div className='flex cursor-pointer mb-8 justify-center' onClick={backToBuildingImage}>
                <ArrowLeftIcon size={30} color='text-button' />
                <span className='ml-2 text-lg bold text-text-button'>Zpět na vizualizaci projektu</span>
            </div>
        )
    }

    const handlePolygonClick = (polygonFileUrl?: string, idPropertyMicrosite?: number) => {
        if (polygonFileUrl && !idPropertyMicrosite) {
            setCurrentFileUrl(polygonFileUrl)
            setShowDefaultTable(true)
        } else if (idPropertyMicrosite) {
            const { pathname, query } = router
            const newQuery = { ...query }

            if (query.property === idPropertyMicrosite.toString()) {
                delete newQuery.property

                router
                    .replace(
                        {
                            pathname,
                            query: newQuery
                        },
                        undefined,
                        { shallow: true }
                    )
                    .then(() => {
                        setTimeout(() => {
                            router.replace(
                                {
                                    pathname,
                                    query: { ...newQuery, property: idPropertyMicrosite }
                                },
                                undefined,
                                { shallow: true }
                            )
                        }, 1)
                    })
            } else {
                router.push(
                    {
                        pathname,
                        query: { ...newQuery, property: idPropertyMicrosite }
                    },
                    undefined,
                    { shallow: true }
                )
            }
        }
    }

    const containsFileUrl = (data: PolygonData[]): boolean => {
        return data.some(polygon => !!polygon.fileUrl)
    }

    const handleRowClick = property => {
        handlePolygonClick(null, property.id)
    }

    if (isLoading) {
        return null
    }

    if (interactiveModel && imageData) {
        return (
            <div>
                {isTableForSmallDevice && showDefaultTable && renderRowForSmallDevice()}
                {!showDefaultTable && !hoveredProperty && !containsFileUrl(polygonsData) && (
                    <InteractiveModelTable
                        property={hoveredProperty}
                        backToBuildingImage={() => setCurrentFileUrl(fileUrl)}
                        singleImage
                        onRowClick={handleRowClick}
                    />
                )}
                {showDefaultTable && !hoveredProperty && !isTableForSmallDevice && (
                    <InteractiveModelTable property={hoveredProperty} backToBuildingImage={backToBuildingImage} />
                )}
                {hoveredProperty && !isTableForSmallDevice && (
                    <InteractiveModelTable
                        headers={standardHeaders}
                        property={hoveredProperty}
                        backToBuildingImage={() => backToBuildingImage()}
                        onRowClick={handleRowClick}
                    />
                )}
                <div className='relative max-w-full'>
                    <img src={imageData.href} alt='Interactive Model' className='rounded-3xl w-full block' />
                    <InteractiveModelSvg
                        imageData={imageData}
                        polygonsData={polygonsData}
                        devProject={devProject}
                        handlePolygonClick={handlePolygonClick}
                        setHoveredPropertyId={setHoveredPropertyId}
                        lastHoveredProperty={lastHoveredProperty}
                    />
                </div>
            </div>
        )
    }
}
