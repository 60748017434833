import { getColorFromString } from '../../utils/Helper'
import tw, { styled } from 'twin.macro'

export const ParamsRoot = styled.ul`
    ${tw`grid grid-cols-2 md:grid-cols-4 gap-5`}
`

export const ParamRoot = styled.li`
    ${tw`inline-flex w-auto flex-grow items-center gap-2 whitespace-nowrap`}
`

export const ParamIconContainer = styled.span<{ $bgColor: string }>`
    ${tw`inline-flex items-center justify-center p-3 rounded-lg`}
    background-color: ${({ $bgColor, theme }) => {
        return getColorFromString(theme, $bgColor)
    }};
`
