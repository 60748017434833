import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const DownloadIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const themeColor = getColorFromString(theme, color)
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19ZM12.28 15.78L10.28 17.78C10.21 17.85 10.12 17.91 10.03 17.94C9.94 17.98 9.85 18 9.75 18C9.65 18 9.56 17.98 9.47 17.94C9.39 17.91 9.31 17.85 9.25 17.79C9.24 17.78 9.23 17.78 9.23 17.77L7.23 15.77C6.94 15.48 6.94 15 7.23 14.71C7.52 14.42 8 14.42 8.29 14.71L9 15.44V11.25C9 10.84 9.34 10.5 9.75 10.5C10.16 10.5 10.5 10.84 10.5 11.25V15.44L11.22 14.72C11.51 14.43 11.99 14.43 12.28 14.72C12.57 15.01 12.57 15.49 12.28 15.78Z'
                fill={themeColor}
            />
            <path
                d='M17.4302 8.81C18.3802 8.82 19.7002 8.82 20.8302 8.82C21.4002 8.82 21.7002 8.15 21.3002 7.75C19.8602 6.3 17.2802 3.69 15.8002 2.21C15.3902 1.8 14.6802 2.08 14.6802 2.65V6.14C14.6802 7.6 15.9202 8.81 17.4302 8.81Z'
                fill={themeColor}
            />
        </RootIcon>
    )
}

export default DownloadIcon
