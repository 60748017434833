import { CardBannerProps } from './CardBanner.types'
import { CardBannerRoot } from './CardBanner.styles'
import React from 'react'
import Typography from '../../typography/Typography'

const CardBanner = ({ children, action, ...props }: CardBannerProps) => {
    return (
        <CardBannerRoot {...props}>
            <Typography level='h4' as='h4' className='text-text-cta'>
                {children}
            </Typography>
            {action}
        </CardBannerRoot>
    )
}

export default CardBanner
