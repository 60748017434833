import { RowAlign, RowDirection, RowJustify, SCRowProps } from './Row.types'
import tw, { TwStyle, styled } from 'twin.macro'

export const RowDirectionStyles: Record<RowDirection, TwStyle> = {
    row: tw`flex-row`,
    column: tw`flex-col`
}

export const RowJustifyStyles: Record<RowJustify, TwStyle> = {
    start: tw`justify-start`,
    end: tw`justify-end`,
    center: tw`justify-center`,
    between: tw`justify-between`,
    around: tw`justify-around`,
    evenly: tw`justify-evenly`
}

export const RowAlignStyles: Record<RowAlign, TwStyle> = {
    start: tw`items-start`,
    end: tw`items-end`,
    center: tw`items-center`,
    stretch: tw`items-stretch`,
    baseline: tw`items-baseline`
}

export const RowRoot = styled.div<SCRowProps>`
    ${tw`flex`}
    ${({ $autoWidth }) => {
        return !$autoWidth ? tw`w-full` : ''
    }}
  ${({ $wrap }) => {
        return $wrap ? tw`flex-wrap` : ''
    }}
  ${({ $direction }) => {
        return RowDirectionStyles[$direction]
    }}
  ${({ $justify }) => {
        return RowJustifyStyles[$justify]
    }}
  ${({ $align }) => {
        return RowAlignStyles[$align]
    }}
  ${({ $marginX }) => {
        return $marginX ? tw`-mx-2 lg:-mx-3` : ''
    }}
  ${({ $marginY }) => {
        return $marginY ? tw`-my-1 lg:-my-2` : ''
    }}
`
