import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const LoggiaIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.06836 20.1364C3.06836 19.7222 3.40415 19.3864 3.81836 19.3864H20.182C20.5962 19.3864 20.932 19.7222 20.932 20.1364C20.932 20.5506 20.5962 20.8864 20.182 20.8864H3.81836C3.40415 20.8864 3.06836 20.5506 3.06836 20.1364Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.06836 12.904C3.06836 12.4898 3.40415 12.154 3.81836 12.154L20.182 12.154C20.5962 12.154 20.932 12.4898 20.932 12.904C20.932 13.3182 20.5962 13.654 20.182 13.654L3.81836 13.654C3.40415 13.654 3.06836 13.3183 3.06836 12.904Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.63623 11.25C6.05044 11.25 6.38623 11.5858 6.38623 12V19.2323C6.38623 19.6465 6.05044 19.9823 5.63623 19.9823C5.22202 19.9823 4.88623 19.6465 4.88623 19.2323V12C4.88623 11.5858 5.22202 11.25 5.63623 11.25Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 11.25C12.4142 11.25 12.75 11.5858 12.75 12V19.2323C12.75 19.6465 12.4142 19.9823 12 19.9823C11.5858 19.9823 11.25 19.6465 11.25 19.2323V12C11.25 11.5858 11.5858 11.25 12 11.25Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.3638 11.25C18.778 11.25 19.1138 11.5858 19.1138 12V19.2323C19.1138 19.6465 18.778 19.9823 18.3638 19.9823C17.9496 19.9823 17.6138 19.6465 17.6138 19.2323V12C17.6138 11.5858 17.9496 11.25 18.3638 11.25Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.3362 5.03597C12.2492 4.97126 12.1304 4.97001 12.0421 5.03286L3.43484 11.1566C3.09734 11.3967 2.62907 11.3177 2.38894 10.9802C2.14882 10.6427 2.22776 10.1745 2.56527 9.93434L11.1725 3.81063C11.7909 3.37065 12.6224 3.37944 13.2314 3.8324L21.4477 9.94367C21.78 10.1909 21.8491 10.6607 21.6018 10.9931C21.3546 11.3254 20.8848 11.3944 20.5525 11.1472L12.3362 5.03597Z'
                fill={fillColor}
            />
        </RootIcon>
    )
}

export default LoggiaIcon
