import { TypographyProps } from './Typography.types'
import { removePropertiesFromObject } from '../../utils/Helper'
import React, { ElementType } from 'react'

const HEADING_LEVELS = ['h1', 'h2', 'h3', 'h4']
const BODY_LEVEL = 'body'
const OVERLINE_LEVEL = 'overline'

const Typography = <AsElement extends ElementType = 'p'>({
    as,
    children,
    level = 'body',
    className,
    ...props
}: TypographyProps<AsElement>) => {
    const size = 'size' in props ? (props.size as string) : 'large'
    const type = 'type' in props ? (props.type as string) : 'regular'
    const Element: React.ElementType = as || 'p'
    const classes = [className ?? className]

    if (HEADING_LEVELS.includes(level.toLowerCase())) {
        classes.push(`text-${level}`)
    }

    if (level === BODY_LEVEL) {
        classes.push(`text-${level}-${size}`, type)
    }

    if (OVERLINE_LEVEL === level.toLowerCase()) {
        classes.push(`text-${level}-normal`, type)
    }

    return (
        <Element {...removePropertiesFromObject(props, 'size', 'type')} className={classes.join(' ').trim()}>
            {children}
        </Element>
    )
}

export default Typography
