import { ComponentType } from 'react'
import { IconProps, SocialIconProps, SocialIconType } from './Icon.types'
import FacebookIcon from './FacebookIcon'
import InstagramIcon from './InstagramIcon'
import LinkedInIcon from './LinkedInIcon'
import PinterestIcon from './PinterestIcon'
import TikTokIcon from './TikTokIcon'
import TwitterIcon from './TwitterIcon'
import YoutubeIcon from './YoutubeIcon'

const socialIcons: Record<SocialIconType, ComponentType<IconProps>> = {
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    linkedin: LinkedInIcon,
    youtube: YoutubeIcon,
    tiktok: TikTokIcon,
    twitter: TwitterIcon,
    pinterest: PinterestIcon
}

const SocialIcon = ({ socialType, ...props }: SocialIconProps) => {
    const Icon = socialIcons[socialType]

    return <Icon {...props} />
}

export default SocialIcon
