import { TextFieldValidationMessageProps } from './TextFieldValidationMessage.types'
import { TextFieldValidationMessageRoot } from './TextFieldValidationMessage.styles'

const TextFieldValidationMessage = ({ message, ...props }: TextFieldValidationMessageProps) => {
    if (Array.isArray(message)) {
        return (
            <TextFieldValidationMessageRoot {...props}>
                <ul>
                    {message.map((item, index) => {
                        return <li key={index}>{item}</li>
                    })}
                </ul>
            </TextFieldValidationMessageRoot>
        )
    }

    return <TextFieldValidationMessageRoot {...props}>{message}</TextFieldValidationMessageRoot>
}

export default TextFieldValidationMessage
