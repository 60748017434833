import { CardIconContainer, CardRoot } from './Card.styles'
import { CardProps } from './Card.types'
import { HtmlTextContainerRoot } from '../../styles/GlobalStyles'
import { TypographyProps } from '../typography/Typography.types'
import React from 'react'
import Typography from '../typography/Typography'

const Card = ({ children, title, icon, iconContainerClassName, renderAsHtml, ...props }: CardProps) => {
    const renderText = () => {
        const typographyProps: TypographyProps = {
            level: 'body',
            size: 'normal',
            type: 'regular',
            className: 'text-grey-600 text-justify'
        }
        if (renderAsHtml && typeof children === 'string') {
            return (
                <HtmlTextContainerRoot>
                    <Typography {...typographyProps} as='div' dangerouslySetInnerHTML={{ __html: children }} />
                </HtmlTextContainerRoot>
            )
        }
        return <Typography {...typographyProps}>{children}</Typography>
    }

    return (
        <CardRoot {...props}>
            {icon && <CardIconContainer className={iconContainerClassName}>{icon}</CardIconContainer>}
            <Typography as='h4' level='h4' className='text-text-blues'>
                {title}
            </Typography>
            {renderText()}
        </CardRoot>
    )
}

export default Card
