import { ButtonElementType, ButtonProps } from './Button.types'
import { ButtonLeftIconContainer, ButtonRightIconContainer, ButtonRoot } from './Button.styles'
import React from 'react'

const Button = <AsElement extends ButtonElementType = 'button'>({
    as,
    children,
    variant = 'normal',
    color = 'red',
    leftIcon,
    rightIcon,
    ...props
}: ButtonProps<AsElement>) => {
    const renderLeftIcon = () => {
        return leftIcon && <ButtonLeftIconContainer>{leftIcon}</ButtonLeftIconContainer>
    }

    const renderRightIcon = () => {
        return rightIcon && <ButtonRightIconContainer>{rightIcon}</ButtonRightIconContainer>
    }
    return (
        <ButtonRoot {...props} as={as || 'button'} $variant={variant} $color={color}>
            {renderLeftIcon()}
            {children}
            {renderRightIcon()}
        </ButtonRoot>
    )
}

export default Button
