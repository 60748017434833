import { ParamIconContainer, ParamRoot } from './Params.styles'
import { ParamProps } from './Params.types'
import React from 'react'

const Param = ({ children, icon, bgColor = 'secondaryBlue-100', ...props }: ParamProps) => {
    if (!children) {
        return null
    }
    return (
        <ParamRoot {...props}>
            <ParamIconContainer $bgColor={bgColor}>{icon}</ParamIconContainer>
            <span className='text-basic-primary'>{children}</span>
        </ParamRoot>
    )
}

export default Param
