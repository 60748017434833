import { Container } from '../../styles/GlobalStyles'
import { FooterProps } from './Footer.types'
import { FooterRoot, TopBorder } from './Footer.styles'
import { buildClassesWithDefault } from '../../utils/Helper'
import Column from '../grid/column/Column'
import FooterContact from './FooterContact'
import FooterDactylGroup from './FooterDactylGroup'
import FooterLinkList from './FooterLinkList'
import FooterLogoList from './FooterLogoList'
import Grid from '../grid/Grid'
import React from 'react'
import Row from '../grid/row/Row'
import Typography from '../typography/Typography'
import useWindowSize from '../../hooks/useWindowSize'

const Footer = ({
    className,
    menuLeft,
    menuRight,
    contact,
    recaptchaText,
    companyName,
    showCertificate,
    googleReviews,
    socials,
    ...props
}: FooterProps) => {
    const { breakpoints } = useWindowSize()
    const shouldDisplayContactHeader =
        !(menuLeft.length === 0 && menuRight.length === 0) || !breakpoints?.is1024rGreater

    return (
        <FooterRoot {...props} className={buildClassesWithDefault('bg-basic-primary relative', className)}>
            <TopBorder shouldDisplayContactHeader={shouldDisplayContactHeader} />
            <Container>
                <Grid>
                    <Row className='justify-center text-center lg:!justify-between lg:!text-left'>
                        <Column cols={12} lg={shouldDisplayContactHeader ? 6 : 12}>
                            <FooterLinkList
                                leftLinks={menuLeft}
                                rightLinks={menuRight}
                                companyName={companyName}
                                contact={contact}
                                socials={socials}
                                shouldDisplayContactHeader={shouldDisplayContactHeader}
                            />
                        </Column>
                        {shouldDisplayContactHeader && (
                            <Column cols={12} lg={6}>
                                <FooterContact
                                    contact={contact}
                                    socials={socials}
                                    shouldDisplayContactHeader={shouldDisplayContactHeader}
                                />
                            </Column>
                        )}
                    </Row>
                    {shouldDisplayContactHeader && (
                        <FooterLogoList
                            companyName={companyName}
                            showCertificate={showCertificate}
                            googleReviews={googleReviews}
                        />
                    )}
                    <Row className='text-grey-400 pb-20 md:pb-0 justify-center'>
                        {!shouldDisplayContactHeader && (
                            <Column cols={12} lg={2} className='flex items-start'>
                                <h6 className='text-basic-white'>© 2024 {companyName}</h6>
                            </Column>
                        )}
                        <Column cols={12} lg={shouldDisplayContactHeader ? 12 : 6}>
                            <Typography
                                className='google-recaptcha-links text-xs'
                                size='small'
                                dangerouslySetInnerHTML={{ __html: recaptchaText }}
                            />
                        </Column>
                        {!shouldDisplayContactHeader && (
                            <Column cols={12} lg={4} className='flex items-end justify-end'>
                                <FooterDactylGroup />
                            </Column>
                        )}
                    </Row>
                </Grid>
            </Container>
        </FooterRoot>
    )
}

export default Footer
