import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const HousePersonalIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M23.3023 20.5903H22.3721V9.99901C22.3721 9.41635 22.1116 8.87127 21.6558 8.51416L20.5116 7.61197L20.493 5.30951C20.493 4.79263 20.0744 4.37913 19.5628 4.37913H16.3907L15.1442 3.40176C14.4744 2.86608 13.5256 2.86608 12.8558 3.40176L6.34419 8.51416C5.88837 8.87127 5.62791 9.41635 5.62791 9.98961L5.5814 20.5903H4.69767C4.31628 20.5903 4 20.9099 4 21.2952C4 21.6805 4.31628 22 4.69767 22H23.3023C23.6837 22 24 21.6805 24 21.2952C24 20.9099 23.6837 20.5903 23.3023 20.5903ZM16.3256 20.5903H11.6744V18.0059C11.6744 17.2259 12.2977 16.5963 13.0698 16.5963H14.9302C15.7023 16.5963 16.3256 17.2259 16.3256 18.0059V20.5903ZM19.1163 12.6022C19.1163 13.1191 18.6977 13.542 18.186 13.542H16.3256C15.814 13.542 15.3953 13.1191 15.3953 12.6022V11.1925C15.3953 10.6757 15.814 10.2528 16.3256 10.2528H18.186C18.6977 10.2528 19.1163 10.6757 19.1163 11.1925V12.6022Z'
                fill={fillColor}
            />
            <path
                d='M14 11C14 14.866 10.866 18 7 18C3.13401 18 0 14.866 0 11C0 7.13401 3.13401 4 7 4C10.866 4 14 7.13401 14 11Z'
                fill='white'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7 16.5C10.0376 16.5 12.5 14.0376 12.5 11C12.5 7.96243 10.0376 5.5 7 5.5C3.96243 5.5 1.5 7.96243 1.5 11C1.5 14.0376 3.96243 16.5 7 16.5ZM7 18C10.866 18 14 14.866 14 11C14 7.13401 10.866 4 7 4C3.13401 4 0 7.13401 0 11C0 14.866 3.13401 18 7 18Z'
                fill={fillColor}
            />
            <path
                d='M6.82771 6.33334C5.58941 6.33334 4.5827 7.34005 4.5827 8.57835C4.5827 9.79301 5.53269 10.7761 6.77099 10.8186C6.8088 10.8139 6.84661 10.8139 6.87497 10.8186C6.88442 10.8186 6.88915 10.8186 6.8986 10.8186C6.90333 10.8186 6.90333 10.8186 6.90805 10.8186C8.11799 10.7761 9.06799 9.79301 9.07271 8.57835C9.07271 7.34005 8.066 6.33334 6.82771 6.33334Z'
                fill={fillColor}
            />
            <path
                d='M9.22831 12.0758C7.90966 11.1967 5.75918 11.1967 4.43109 12.0758C3.83084 12.4776 3.5 13.0211 3.5 13.6024C3.5 14.1838 3.83084 14.7226 4.42636 15.1196C5.08805 15.5639 5.95769 15.786 6.82733 15.786C7.69698 15.786 8.56662 15.5639 9.22831 15.1196C9.82382 14.7178 10.1547 14.179 10.1547 13.593C10.1499 13.0116 9.82382 12.4728 9.22831 12.0758Z'
                fill={fillColor}
            />
        </RootIcon>
    )
}

export default HousePersonalIcon
