import tw, { styled } from 'twin.macro'

export const PersonalDataModalBodyRoot = styled.div`
    p {
        ${tw`py-12 text-basic-primary`}
    }

    strong {
        ${tw`font-bold`}
    }

    a {
        ${tw`py-1 text-text-secondary underline hover:no-underline`}
    }

    ul {
        ${tw`list-disc pl-8`}
    }

    ol {
        ${tw`list-decimal pl-8`}
    }
`
