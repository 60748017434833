import { CheckboxContainerRoot, CheckboxRoot } from './Checkbox.styles'
import { CheckboxProps } from './Checkbox.types'
import React, { ForwardedRef, forwardRef, useId } from 'react'
import TextFieldValidationMessage from '../text-field-validation-message/TextFieldValidationMessage'

const Checkbox = (
    { label, id: idProp, required, invalidMessage, ...props }: CheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
) => {
    const id = useId()

    return (
        <CheckboxContainerRoot>
            <CheckboxRoot>
                <input {...props} ref={ref} id={idProp || id} required={required} type='checkbox' />
                <label htmlFor={idProp || id}>
                    {label} {required && <span className='text-error-400'>*</span>}
                </label>
            </CheckboxRoot>
            {invalidMessage && <TextFieldValidationMessage message={invalidMessage} />}
        </CheckboxContainerRoot>
    )
}

export default forwardRef(Checkbox)
