import { ComponentInteractiveImage } from './component-interactive-image/ComponentInteractiveImage'
import { ComponentManagerProps } from './ComponentManager.types'
import { MicroSiteContent, MicroSiteDetailComponentProperties } from '../../services/Microsite.types'
import ComponentContact from './ComponentContact'
import ComponentContactBanner from './ComponentContactBanner'
import ComponentDescription from './ComponentDescription'
import ComponentDescriptionWithImage from './ComponentDescriptionWithImage'
import ComponentDocuments from './ComponentDocuments'
import ComponentFinancing from './ComponentFinancing'
import ComponentFloorPlan from './ComponentFloorPlan'
import ComponentGallery from './ComponentGallery'
import ComponentLocation from './ComponentLocation'
import ComponentMaterPort from './ComponentMaterPort'
import ComponentMortgage from './ComponentMortgage'
import ComponentParams from './ComponentParams'
import ComponentPropertiesTable from './component-properties-table/ComponentPropertiesTable'
import ComponentVideoTour from './ComponentVideoTour'
import React from 'react'

const ComponentManager = ({
    components,
    type,
    brokerEmail,
    contactBgColor,
    descriptionsCustomCols
}: ComponentManagerProps) => {
    // useState is not necessary (I want reset this value before each rendering. useState keeps the current value.)
    let isRenderedBeforeContact = false

    const getContact = (index: number, contactType: 'after' | 'before' = 'after') => {
        const contactComponent = contactType === 'after' ? components?.[index + 1] : components?.[index - 1]
        if (
            contactComponent &&
            contactComponent.componentName === 'contactform' &&
            contactComponent.type === 'section'
        ) {
            return contactComponent
        }
    }

    const devProjectProperties: MicroSiteDetailComponentProperties | undefined = components.find(
        (component: any): component is MicroSiteDetailComponentProperties =>
            component.componentName === 'devprojectproperties'
    )

    return (
        <>
            {components.map((component: MicroSiteContent, key: number) => {
                if ('componentName' in component) {
                    const shared = { key }
                    switch (component.componentName) {
                        case 'description':
                            return (
                                <ComponentDescription
                                    {...shared}
                                    component={component}
                                    customCols={descriptionsCustomCols}
                                />
                            )
                        case 'params':
                            return <ComponentParams {...shared} component={component} />
                        case 'interactive':
                            return (
                                <ComponentInteractiveImage
                                    {...shared}
                                    component={component}
                                    devProject={devProjectProperties?.properties ?? []}
                                />
                            )
                        case 'gallery':
                            if (!component.images.length) {
                                return
                            }
                            return <ComponentGallery {...shared} component={component} />
                        case 'floorplan':
                            return <ComponentFloorPlan {...shared} component={component} />
                        case 'matterport':
                            return <ComponentMaterPort {...shared} component={component} />
                        case 'location':
                            return <ComponentLocation {...shared} component={component} />
                        case 'videotour':
                            return <ComponentVideoTour {...shared} component={component} />
                        case 'mortgagecalculator':
                            return <ComponentMortgage {...shared} component={component} />
                        case 'documents':
                            if (!component.files.length) {
                                return
                            }
                            return (
                                <ComponentDocuments
                                    {...shared}
                                    darkerBackground={type === 'project'}
                                    component={component}
                                />
                            )
                        case 'devprojectproperties':
                            if (!component.properties.length) {
                                return
                            }
                            return <ComponentPropertiesTable {...shared} component={component} />
                        case 'aboutdevproject':
                            return <ComponentDescriptionWithImage {...shared} component={component} />
                        case 'devprojectfinancing':
                            return <ComponentFinancing {...shared} component={component} />
                        case 'contactform':
                            if (component.type === 'banner') {
                                return (
                                    <ComponentContactBanner
                                        {...shared}
                                        brokerEmail={brokerEmail}
                                        component={component}
                                        bgColor={contactBgColor}
                                    />
                                )
                            }
                            const contactComponentAfter = getContact(key)
                            const contactComponentBefore = getContact(key, 'before')
                            if (contactComponentBefore && isRenderedBeforeContact) {
                                isRenderedBeforeContact = false
                                return
                            }
                            if (contactComponentAfter) {
                                isRenderedBeforeContact = true
                                return (
                                    <ComponentContact
                                        key={key}
                                        title={component?.title || ''}
                                        firstContactComponent={component}
                                        secondContactComponent={contactComponentAfter}
                                        bgColor={contactBgColor}
                                        hash={component.hash}
                                    />
                                )
                            }
                            return (
                                <ComponentContact
                                    key={key}
                                    title={component?.title || ''}
                                    firstContactComponent={component}
                                    bgColor={contactBgColor}
                                    hash={component.hash}
                                />
                            )
                        default:
                            return null
                    }
                }
            })}
        </>
    )
}

export default ComponentManager
