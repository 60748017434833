import {
    DiscountRoot,
    DiscountTextRoot,
    PriceContainerRoot,
    PriceDiscountedRoot,
    PriceNoteRoot,
    PriceRoot,
    PriceSuffixRoot
} from './MicrositeHeader.styles'
import { MicrositeHeaderPriceProps } from './MicrositeHeader.types'
import { getCurrencySymbol, getNormalizedPrice } from '../../utils/Helper'
import React, { useMemo } from 'react'
import useWindowSize from '../../hooks/useWindowSize'

const MicrositeHeaderPrice = ({
    infoInRk,
    price,
    priceDiscounted,
    priceNote,
    priceType,
    currency
}: MicrositeHeaderPriceProps) => {
    const { breakpoints } = useWindowSize()
    const currencySymbol = getCurrencySymbol(currency)

    const normalizedPriceType = useMemo(() => {
        if (priceType === 'meter') {
            return '/ m²'
        }
        if (priceType === 'month') {
            return '/ měsíc'
        }
        return null
    }, [priceType])

    const renderInfoInRK = () => {
        return <PriceRoot>Cena: Info u RK</PriceRoot>
    }

    const renderPriceDiscounted = () => {
        const canShowDiscount = price - priceDiscounted > 0
        const canShowSavedMoneyOnMobile = breakpoints?.isSMOrLesser && canShowDiscount
        const canShowSavedMoneyOnLaptop = breakpoints?.isMDOrGreater && canShowDiscount

        return (
            <div className='flex flex-col space-y-4'>
                {canShowDiscount && (
                    <PriceDiscountedRoot>{getNormalizedPrice(price, currencySymbol)}</PriceDiscountedRoot>
                )}
                {canShowSavedMoneyOnLaptop && (
                    <DiscountRoot>
                        {`Ušetříte ${getNormalizedPrice(price - priceDiscounted, currencySymbol)}`}
                    </DiscountRoot>
                )}
                <div className='flex flex-col lg:flex-row lg:items-center lg:ml-auto'>
                    {canShowDiscount && (
                        <DiscountTextRoot>
                            <span>SLEVA</span>
                        </DiscountTextRoot>
                    )}
                    {renderPrice(canShowDiscount ? priceDiscounted : price, currencySymbol)}
                </div>
                {priceNote && renderPriceNote()}
                {canShowSavedMoneyOnMobile && (
                    <DiscountRoot>
                        {`Ušetříte ${getNormalizedPrice(price - priceDiscounted, currencySymbol)}`}
                    </DiscountRoot>
                )}
            </div>
        )
    }

    const renderPrice = (value: number, currencyValue: string | null) => {
        const normalizedPrice = getNormalizedPrice(value, currencyValue)
        return (
            <PriceContainerRoot $isMeter={priceType === 'meter'} $isMonth={priceType === 'month'}>
                <PriceRoot>{normalizedPrice}</PriceRoot>
                {normalizedPriceType && <PriceSuffixRoot>{normalizedPriceType}</PriceSuffixRoot>}
            </PriceContainerRoot>
        )
    }

    const renderPriceNote = () => {
        return <PriceNoteRoot>{priceNote}</PriceNoteRoot>
    }

    if (infoInRk) {
        return renderInfoInRK()
    }
    if (priceDiscounted) {
        return renderPriceDiscounted()
    }
    if (price) {
        return (
            <div>
                {renderPrice(price, currencySymbol)}
                <div className='mt-4 text-left lg:text-right'>{renderPriceNote()}</div>
            </div>
        )
    }
}

export default MicrositeHeaderPrice
