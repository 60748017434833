import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { PostMortgageCalculatorFormRequest } from './MortgageCalculatorService.types'
import { apiConfig } from '../../config/api/apiConfig'

export const postMortgageCalculator = async (
    axiosInstance: AxiosInstance,
    id: string,
    data: PostMortgageCalculatorFormRequest,
    reCaptcha: string,
    config: AxiosRequestConfig = {}
): Promise<number> => {
    const {
        data: { status }
    } = await axiosInstance.post<AxiosResponse<number>>(
        apiConfig.mortgageCalculator.post.replace('{id}', id),
        {
            ...data,
            reCaptcha
        },
        config
    )
    return status
}
