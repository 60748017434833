import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const ForestIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <svg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M0.651855 17.2814C0.651855 17.0053 0.875713 16.7814 1.15186 16.7814H22.3354C22.6115 16.7814 22.8354 17.0053 22.8354 17.2814C22.8354 17.5576 22.6115 17.7814 22.3354 17.7814H1.15186C0.875713 17.7814 0.651855 17.5576 0.651855 17.2814Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M18.5361 12.5852C18.8123 12.5852 19.0361 12.8091 19.0361 13.0852L19.0361 17.1404C19.0361 17.4166 18.8123 17.6404 18.5361 17.6404C18.26 17.6404 18.0361 17.4166 18.0361 17.1404L18.0361 13.0852C18.0361 12.8091 18.26 12.5852 18.5361 12.5852Z'
                    fill={fillColor}
                />
                <path
                    d='M14.4514 13.4239H22.617C22.9937 13.4239 23.2351 13.0232 23.0591 12.6902L18.9499 4.91686C18.7616 4.56069 18.251 4.56205 18.0646 4.91922L14.0081 12.6926C13.8344 13.0255 14.0759 13.4239 14.4514 13.4239Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M5.24268 13.3065C5.51882 13.3065 5.74268 13.5303 5.74268 13.8065L5.74268 17.1404C5.74268 17.4166 5.51882 17.6404 5.24268 17.6404C4.96653 17.6404 4.74268 17.4166 4.74268 17.1404L4.74268 13.8065C4.74268 13.5303 4.96653 13.3065 5.24268 13.3065Z'
                    fill={fillColor}
                />
                <path
                    d='M1.80638 14.5637H8.67506C9.05725 14.5637 9.29813 14.1523 9.11109 13.819L5.6546 7.65985C5.46296 7.31838 4.97096 7.31973 4.7812 7.66225L1.36901 13.8214C1.18439 14.1547 1.4254 14.5637 1.80638 14.5637Z'
                    fill={fillColor}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.4751 8.87442C11.7512 8.87442 11.9751 9.09828 11.9751 9.37442L11.9751 17.1404C11.9751 17.4166 11.7512 17.6404 11.4751 17.6404C11.199 17.6404 10.9751 17.4166 10.9751 17.1404L10.9751 9.37442C10.9751 9.09828 11.199 8.87442 11.4751 8.87442Z'
                    fill={fillColor}
                />
                <path
                    d='M6.36917 12.7176H10.014L16.6831 12.7176C17.1313 12.7176 17.3531 12.1733 17.0326 11.86L14.3131 9.2021H15.654C16.0874 9.2021 16.3156 8.68828 16.0249 8.36678L13.1544 5.19184H14.8637C15.2854 5.19184 15.5177 4.70175 15.2507 4.37527L11.8298 0.193107C11.6354 -0.0445582 11.2747 -0.052489 11.0701 0.176402L7.33066 4.35856C7.04263 4.68069 7.27127 5.19184 7.70339 5.19184H9.40928L6.69377 8.37776C6.41709 8.70237 6.64777 9.2021 7.0743 9.2021H9.05216L6.03967 11.8415C5.69254 12.1457 5.90765 12.7176 6.36917 12.7176Z'
                    fill={fillColor}
                />
            </svg>
        </RootIcon>
    )
}

export default ForestIcon
