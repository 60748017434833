import { ComponentManagerComponentRow } from './ComponentManager.styles'
import { ComponentMaterPortProps } from './ComponentManager.types'
import { Element as ScrollElement } from 'react-scroll'
import Column from '../grid/column/Column'
import MatterPort from '../matterport/MatterPort'
import React from 'react'
import SectionTitle from '../section-title/SectionTitle'

const ComponentMaterPort = ({ component: { title, url, hash }, ...props }: ComponentMaterPortProps) => {
    return (
        url && (
            <ScrollElement name={hash}>
                <ComponentManagerComponentRow {...props}>
                    <Column cols={12}>
                        <SectionTitle>{title}</SectionTitle>
                    </Column>
                    <Column cols={12}>
                        <MatterPort src={url} />
                    </Column>
                </ComponentManagerComponentRow>
            </ScrollElement>
        )
    )
}

export default ComponentMaterPort
