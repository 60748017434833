import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const TikTokIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M2.95897 12.6919C4.59518 9.75329 8.42323 9.28419 10.1327 9.41696V13.3242C8.89182 12.958 7.7574 13.3874 7.3453 13.6478C6.72293 13.9796 5.53978 15.0896 5.78616 16.8756C6.03255 18.6615 7.60837 19.3995 8.36548 19.5452C8.80952 19.6651 9.98326 19.7143 11.1259 18.9521C12.2684 18.1899 12.4918 16.7267 12.4606 16.0904V1.00014C14.6947 0.999835 16.2077 1.00014 16.2077 1.00014C16.2077 4.76579 20.0692 6.2542 22 6.5277V10.0904C19.8374 10.194 17.2374 8.88122 16.2077 8.21186V16.2404C16.2077 16.7957 16.0101 17.69 15.9113 18.0677C15.5323 19.5165 14.1845 22.2344 10.1327 22.8965C6.08091 23.5586 3.52988 20.8827 2.76084 19.462C2.14513 18.4297 1.32275 15.6304 2.95897 12.6919Z'
                fill={getColorFromString(theme, color)}
            />
        </RootIcon>
    )
}

export default TikTokIcon
