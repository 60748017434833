import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const ArrowRightIcon = ({ color = 'text-badge', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.49183 4.27372C9.06737 3.85241 9.91816 3.9256 10.3921 4.43718L16.6921 11.2372C17.1026 11.6803 17.1026 12.3197 16.6921 12.7628L10.3921 19.5628C9.91816 20.0744 9.06737 20.1476 8.49183 19.7263C7.91629 19.305 7.83396 18.5487 8.30793 18.0371L13.9012 12L8.30793 5.96287C7.83396 5.45129 7.91629 4.69502 8.49183 4.27372Z'
                fill={getColorFromString(theme, color)}
            />
        </RootIcon>
    )
}

export default ArrowRightIcon
