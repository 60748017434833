import { ComponentManagerComponentRow } from './ComponentManager.styles'
import { ComponentVideoTourProps } from './ComponentManager.types'
import { Element as ScrollElement } from 'react-scroll'
import { VideoPlayer } from '../video-player/VideoPlayer'
import { extractVideoId } from '../../utils/Helper'
import Column from '../grid/column/Column'
import React, { useMemo } from 'react'
import SectionTitle from '../section-title/SectionTitle'

const ComponentVideoTour = ({ component: { title, url: videoUrl, hash }, ...props }: ComponentVideoTourProps) => {
    const videoId = useMemo<string | null>(() => {
        return extractVideoId(videoUrl)
    }, [videoUrl])

    if (videoId) {
        return (
            <ScrollElement name={hash}>
                <ComponentManagerComponentRow {...props}>
                    <Column cols={12}>
                        <SectionTitle>{title}</SectionTitle>
                    </Column>
                    <Column cols={12}>
                        <VideoPlayer videoId={videoId} />
                    </Column>
                </ComponentManagerComponentRow>
            </ScrollElement>
        )
    }
}

export default ComponentVideoTour
