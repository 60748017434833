import { GoogleMapProps } from './GoogleMap.types'
import { useEnvironment } from '../../hooks/useEnvironment'
import GoogleMapMarker from './GoogleMapMarker'
import GoogleMapReact from 'google-map-react'
import tw, { styled } from 'twin.macro'

const GoogleMap = ({ lat, lng }: GoogleMapProps) => {
    const { googleMapKey } = useEnvironment()

    return (
        <Container>
            <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals
                bootstrapURLKeys={{ key: googleMapKey }}
                defaultCenter={{ lat, lng }}
                defaultZoom={15}
            >
                <GoogleMapMarker lat={lat} lng={lng} />
            </GoogleMapReact>
        </Container>
    )
}

const Container = styled.div`
    ${tw`h-[500px] w-full`}

    div {
        border-radius: 1.563rem;
    }
`

export default GoogleMap
