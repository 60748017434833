import { ModalActions, ModalBody, ModalOverlay, ModalRoot, ModalTitle, ModalTitleCloseButton } from './Modal.styles'
import { ModalProps } from './Modal.types'
import { Portal, PortalWithState } from 'react-portal'
import ClientRender from '../client-render/ClientRender'
import CloseIcon from '../icon/CloseIcon'
import React from 'react'
import useScrollBlock from '../../hooks/useScrollBlock'

const Modal = ({ children, title, body, actions, ...props }: ModalProps) => {
    const { allowScroll, blockScroll } = useScrollBlock()

    return (
        <ClientRender>
            <PortalWithState closeOnOutsideClick closeOnEsc onOpen={blockScroll} onClose={allowScroll}>
                {({ openPortal, closePortal, isOpen, portal }) => {
                    return (
                        <>
                            {children({ openModal: openPortal, isOpened: isOpen })}
                            {isOpen && (
                                <Portal>
                                    <ModalOverlay />
                                </Portal>
                            )}
                            {portal(
                                <ModalRoot {...props}>
                                    <ModalTitle>
                                        <div className='flex items-center'>{title}</div>
                                        <ModalTitleCloseButton onClick={closePortal}>
                                            <CloseIcon color='basic-primary' />
                                        </ModalTitleCloseButton>
                                    </ModalTitle>
                                    <ModalBody>{body}</ModalBody>
                                    {actions && <ModalActions>{actions}</ModalActions>}
                                </ModalRoot>
                            )}
                        </>
                    )
                }}
            </PortalWithState>
        </ClientRender>
    )
}

export default Modal
