import { GoogleTranslateProps } from './GoogleTranslate.types'
import { RenderLogoAndReviews } from './RenderLogoAndReviews'
import { SeznamLogo } from './SeznamLogo'
import GoogleLogo from './GoogleLogo'
import React, { useEffect, useRef } from 'react'

// Extend the Window interface to include google and googleTranslateElementInit properties
declare global {
    interface Window {
        google?: {
            translate: {
                TranslateElement: new (
                    options: {
                        pageLanguage: string
                        includedLanguages: string
                        autoDisplay: boolean
                    },
                    googleWidgetElementId: string
                ) => void
            }
        }
        googleTranslateElementInit?: () => void
    }
}

/**
 * @see https://www.w3schools.com/howto/howto_google_translate.asp Google Translate how it works
 * @see https://stackoverflow.com/a/64095746 React component for Google Translate
 */
const GoogleTranslate = ({ googleReviews, seznamReviews }: GoogleTranslateProps) => {
    const googleWidgetElementId = 'google_translate_element'
    const scriptRef = useRef<HTMLScriptElement | null>()

    const googleTranslateElementInit = () => {
        if (window.google && window.google.translate) {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: 'cs',
                    includedLanguages: 'cs,en,de,fr,es,ru,uk,nl',
                    autoDisplay: true
                },
                googleWidgetElementId
            )
        }
    }

    useEffect(() => {
        window.googleTranslateElementInit = googleTranslateElementInit

        // Check if the script already exists to avoid adding it multiple times
        if (!scriptRef.current) {
            const script = document.createElement('script')
            script.src = `//translate.google.com/translate_a/element.js?cb=${googleTranslateElementInit.name}`
            scriptRef.current = script
            document.body.appendChild(script)
        }

        return () => {
            // Clean up the script and remove the global function reference
            if (scriptRef.current) {
                document.body.removeChild(scriptRef.current)
                scriptRef.current = null
            }
            delete window.googleTranslateElementInit
        }
    }, [])

    return (
        <div className='fixed left-0 right-0 top-0 h-12 bg-background-cta px-6 2xl:px-12 flex items-center justify-between sm:justify-end'>
            <div className='flex items-center text-text-cta gap-3.5 w-full justify-end lg-plus:justify-between'>
                <div className='flex gap-3.5 hidden lg-plus:flex'>
                    <div>
                        <RenderLogoAndReviews reviews={googleReviews} reviewLogo={<GoogleLogo />} />
                    </div>
                    <div>
                        <RenderLogoAndReviews reviews={seznamReviews} reviewLogo={<SeznamLogo />} />
                    </div>
                </div>
                <div className='flex items-center gap-3'>
                    <span className='hidden md:block text-xs'>Přeložit stránku? Použijte Google Translate!</span>
                    <div id={googleWidgetElementId}></div>
                    <div className='text-xs flex items-end gap-1'>
                        <span className='whitespace-nowrap'>Powered by</span>
                        <div className='shrink-0'>
                            <GoogleLogo />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GoogleTranslate
