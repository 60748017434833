import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const HouseIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M22 21.25H21V9.98C21 9.36 20.72 8.78 20.23 8.4L19 7.44L18.98 4.99C18.98 4.44 18.53 4 17.98 4H14.57L13.23 2.96C12.51 2.39 11.49 2.39 10.77 2.96L3.77 8.4C3.28 8.78 3 9.36 3 9.97L2.95 21.25H2C1.59 21.25 1.25 21.59 1.25 22C1.25 22.41 1.59 22.75 2 22.75H22C22.41 22.75 22.75 22.41 22.75 22C22.75 21.59 22.41 21.25 22 21.25ZM6.5 12.75V11.25C6.5 10.7 6.95 10.25 7.5 10.25H9.5C10.05 10.25 10.5 10.7 10.5 11.25V12.75C10.5 13.3 10.05 13.75 9.5 13.75H7.5C6.95 13.75 6.5 13.3 6.5 12.75ZM14.5 21.25H9.5V18.5C9.5 17.67 10.17 17 11 17H13C13.83 17 14.5 17.67 14.5 18.5V21.25ZM17.5 12.75C17.5 13.3 17.05 13.75 16.5 13.75H14.5C13.95 13.75 13.5 13.3 13.5 12.75V11.25C13.5 10.7 13.95 10.25 14.5 10.25H16.5C17.05 10.25 17.5 10.7 17.5 11.25V12.75Z'
                fill={getColorFromString(theme, color)}
            />
        </RootIcon>
    )
}

export default HouseIcon
