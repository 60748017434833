import { ComponentDescriptionWithImageProps } from './ComponentManager.types'
import { ComponentManagerComponentRow } from './ComponentManager.styles'
import Block from '../block/Block'
import Column from '../grid/column/Column'
import React from 'react'

const ComponentDescriptionWithImage = ({
    component: { componentNamePretty, title, text, image },
    ...props
}: ComponentDescriptionWithImageProps) => {
    return (
        <ComponentManagerComponentRow {...props}>
            {(componentNamePretty || title) && text && (
                <Column lg={12}>
                    <Block title={title || componentNamePretty} text={text} image={image} renderAsHtml />
                </Column>
            )}
        </ComponentManagerComponentRow>
    )
}

export default ComponentDescriptionWithImage
