import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const SearchIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3 10.875C3 15.2175 6.5325 18.75 10.875 18.75C15.2175 18.75 18.75 15.2175 18.75 10.875C18.75 6.5325 15.2175 3 10.875 3C6.5325 3 3 6.5325 3 10.875ZM5.25 10.875C5.25 7.77337 7.77337 5.25 10.875 5.25C13.9766 5.25 16.5 7.77337 16.5 10.875C16.5 13.9766 13.9766 16.5 10.875 16.5C7.77337 16.5 5.25 13.9766 5.25 10.875ZM17.9781 16.3875L20.6702 19.0796C21.1101 19.5195 21.1101 20.2305 20.6702 20.6704C20.4509 20.8897 20.1629 21 19.8749 21C19.5869 21 19.2989 20.8897 19.0795 20.6704L16.3874 17.9783C16.9825 17.5159 17.5157 16.9826 17.9781 16.3875Z'
                fill={getColorFromString(theme, color)}
            />
        </RootIcon>
    )
}

export default SearchIcon
