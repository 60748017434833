import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const ParkingIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7 3C4.79086 3 3 4.79086 3 7V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3H7ZM12.1608 11.7381H11.5333V9.06797H12.4008C12.9217 9.06797 13.3031 9.17461 13.5451 9.38789C13.7912 9.60117 13.9143 9.93135 13.9143 10.3784C13.9143 10.8214 13.7687 11.1598 13.4774 11.3936C13.1862 11.6232 12.7474 11.7381 12.1608 11.7381ZM14.9294 12.5317C15.5364 12.019 15.8399 11.2787 15.8399 10.3107C15.8399 9.37969 15.5549 8.68037 14.9848 8.21279C14.4187 7.74111 13.5902 7.50527 12.4992 7.50527H9.62607V16.5H11.5333V13.3008H12.3516C13.4672 13.3008 14.3265 13.0444 14.9294 12.5317Z'
                fill={fillColor}
            />
        </RootIcon>
    )
}

export default ParkingIcon
