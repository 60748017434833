import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const BrickIcon = ({ color = 'basic-black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect x='3' y='4' width='4.5' height='4.57143' rx='1' fill={fillColor} />
            <rect x='15.7002' y='4' width='4.5' height='4.57143' rx='1' fill={fillColor} />
            <rect x='16.1001' y='15.1429' width='4.5' height='4.57143' rx='1' fill={fillColor} />
            <rect x='11.6001' y='9.57141' width='9' height='4.57143' rx='1' fill={fillColor} />
            <rect x='8' y='4' width='7.2' height='4.57143' rx='1' fill={fillColor} />
            <rect x='3' y='9.57141' width='8.1' height='4.57143' rx='1' fill={fillColor} />
            <rect x='3' y='15.1429' width='12.6' height='4.57143' rx='1' fill={fillColor} />
        </RootIcon>
    )
}

export default BrickIcon
