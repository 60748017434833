import React, { HTMLAttributes } from 'react'

const Grid = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => {
    return (
        <div {...props} className={`mx-4 lg:mx-6 ${className || ''}`}>
            {children}
        </div>
    )
}

export default Grid
