import { LogoProps } from './Logo.types'
import React from 'react'

const GoogleLogo = ({ width = 132, height = 45, ...props }: LogoProps) => {
    return (
        <svg
            {...props}
            width={width}
            height={height}
            viewBox='0 0 132 45'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            {/* eslint-disable-next-line max-len */}
            <path
                d='M56.1727 23.0774C56.1727 29.3236 51.3248 33.9263 45.375 33.9263C39.4253 33.9263 34.5771 29.3236 34.5771 23.0774C34.5771 16.7872 39.4253 12.2285 45.375 12.2285C51.3248 12.2285 56.1727 16.7872 56.1727 23.0774ZM51.446 23.0774C51.446 19.1741 48.6362 16.5035 45.375 16.5035C42.1139 16.5035 39.304 19.1741 39.304 23.0774C39.304 26.9415 42.1139 29.6513 45.375 29.6513C48.6362 29.6513 51.446 26.9366 51.446 23.0774Z'
                fill='white'
            />
            {/* eslint-disable-next-line max-len */}
            <path
                d='M79.4667 23.0774C79.4667 29.3236 74.6186 33.9263 68.6689 33.9263C62.7192 33.9263 57.8711 29.3236 57.8711 23.0774C57.8711 16.7921 62.7192 12.2285 68.6689 12.2285C74.6186 12.2285 79.4667 16.7872 79.4667 23.0774ZM74.7399 23.0774C74.7399 19.1741 71.9301 16.5035 68.6689 16.5035C65.4077 16.5035 62.5979 19.1741 62.5979 23.0774C62.5979 26.9415 65.4077 29.6513 68.6689 29.6513C71.9301 29.6513 74.7399 26.9366 74.7399 23.0774Z'
                fill='white'
            />
            {/* eslint-disable-next-line max-len */}
            <path
                d='M101.79 12.8839V32.3611C101.79 40.373 97.1021 43.6453 91.56 43.6453C86.3431 43.6453 83.2032 40.1285 82.0191 37.2524L86.1344 35.5258C86.8672 37.2915 88.6628 39.3752 91.5551 39.3752C95.1026 39.3752 97.301 37.1692 97.301 33.0165V31.4562H97.136C96.0781 32.7719 94.0398 33.9214 91.4678 33.9214C86.0859 33.9214 81.1553 29.1964 81.1553 23.1165C81.1553 16.9926 86.0859 12.2285 91.4678 12.2285C94.035 12.2285 96.0732 13.3779 97.136 14.6546H97.301V12.8888H101.79V12.8839ZM97.6359 23.1165C97.6359 19.2964 95.1075 16.5035 91.89 16.5035C88.6288 16.5035 85.8966 19.2964 85.8966 23.1165C85.8966 26.8975 88.6288 29.6513 91.89 29.6513C95.1075 29.6513 97.6359 26.8975 97.6359 23.1165Z'
                fill='white'
            />
            <path d='M109.191 1.46729V33.2608H104.581V1.46729H109.191Z' fill='white' />
            {/* eslint-disable-next-line max-len */}
            <path
                d='M127.157 26.648L130.825 29.1133C129.641 30.879 126.788 33.9214 121.857 33.9214C115.742 33.9214 111.176 29.1573 111.176 23.0725C111.176 16.6209 115.781 12.2236 121.328 12.2236C126.914 12.2236 129.646 16.704 130.539 19.1252L131.029 20.3578L116.64 26.3643C117.742 28.541 119.455 29.6513 121.857 29.6513C124.264 29.6513 125.934 28.4578 127.157 26.648ZM115.864 22.7448L125.482 18.7192C124.953 17.3643 123.362 16.4203 121.488 16.4203C119.086 16.4203 115.742 18.5578 115.864 22.7448Z'
                fill='white'
            />
            {/* eslint-disable-next-line max-len */}
            <path
                d='M17.1261 20.2545V15.6518H32.5148C32.6652 16.454 32.7429 17.4029 32.7429 18.4301C32.7429 21.8833 31.8063 26.1535 28.7877 29.1958C25.8517 32.2774 22.1004 33.9208 17.131 33.9208C7.9201 33.9208 0.174805 26.3589 0.174805 17.0752C0.174805 7.79145 7.9201 0.229492 17.131 0.229492C22.2266 0.229492 25.8566 2.24471 28.5839 4.87134L25.3616 8.11917C23.4058 6.27026 20.7561 4.83221 17.1261 4.83221C10.4 4.83221 5.13936 10.2958 5.13936 17.0752C5.13936 23.8545 10.4 29.3181 17.1261 29.3181C21.4889 29.3181 23.9736 27.5524 25.5654 25.948C26.8563 24.6469 27.7055 22.7882 28.0404 20.2496L17.1261 20.2545Z'
                fill='white'
            />
        </svg>
    )
}

export default GoogleLogo
