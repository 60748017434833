import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const ChevronUpIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 10 5'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.171074 4.72678C-0.0922451 4.40703 -0.0465032 3.93437 0.273241 3.67105L4.52324 0.17105C4.80018 -0.0570194 5.19986 -0.0570195 5.4768 0.17105L9.7268 3.67105C10.0465 3.93437 10.0923 4.40703 9.82897 4.72678C9.56565 5.04652 9.09298 5.09226 8.77324 4.82895L5.00002 1.72159L1.2268 4.82895C0.907058 5.09226 0.434392 5.04652 0.171074 4.72678Z'
                fill={getColorFromString(theme, color)}
            />
        </RootIcon>
    )
}

export default ChevronUpIcon
