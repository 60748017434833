import { MatterPortProps } from './MatterPort.types'
import { MatterPortRoot } from './MatterPort.styles'
import useWindowSize from '../../hooks/useWindowSize'

const MatterPort = (props: MatterPortProps) => {
    const { width } = useWindowSize()

    return <MatterPortRoot $bigger={width >= 1200} {...props} />
}

export default MatterPort
