import { extractDataFromForms } from '../utils/Helper'
import { useEffect } from 'react'
import { useEnvironment } from './useEnvironment'
import { useRouter } from 'next/router'
import axios from 'axios'

const useSendFormsBeforeLeavePage = (type: 'project' | 'property') => {
    const router = useRouter()
    const { apiUrl } = useEnvironment()

    useEffect(() => {
        const onBeforeUnload = () => {
            const dataFromContactForms = extractDataFromForms('form.page-form')
            dataFromContactForms.forEach(({ data }) => {
                const isSomeElementSerialized: boolean = data.some(element => {
                    return element.isSerializableActivated
                })
                if (!isSomeElementSerialized) {
                    return
                }
                const dataWithoutBrokerEmail = data.filter(item => {
                    return item.name !== 'brokerEmail'
                })
                if (!dataWithoutBrokerEmail.length) {
                    return
                }
                const dataToSend = {
                    data: [
                        {
                            name: type === 'project' ? 'Projekt' : 'Nemovitost',
                            value: typeof window ? window.location.href : ''
                        },
                        ...dataWithoutBrokerEmail
                    ],
                    brokerEmail: data.find((item: { name: string; value: string }) => {
                        return item.name === 'brokerEmail'
                    })?.value
                }
                if (navigator.sendBeacon) {
                    const formData = new FormData()
                    Object.entries(dataToSend)
                        .filter(([, value]) => {
                            return !!value?.length
                        })
                        .forEach(([key, value]) => {
                            return formData.append(key, JSON.stringify(value))
                        })
                    navigator.sendBeacon(`${apiUrl}/beacon/form-handler`, formData)
                    return
                }
                axios.post(`${apiUrl}/site/form-handler`, dataToSend)
            })
        }

        const registerBeforeUnloadEvent = () => {
            window.addEventListener('beforeunload', onBeforeUnload)
            router.events.on('routeChangeComplete', onBeforeUnload)
        }

        const unregisterBeforeUnloadEvent = () => {
            window.removeEventListener('beforeunload', onBeforeUnload)
            router.events.off('routeChangeComplete', onBeforeUnload)
        }

        registerBeforeUnloadEvent()
        return () => {
            return unregisterBeforeUnloadEvent()
        }
    }, [])
}

export default useSendFormsBeforeLeavePage
