import { Toaster } from 'react-hot-toast'
import React from 'react'
import config from '../config/config.json'

export const Notification = () => {
    return (
        <Toaster
            position='bottom-center'
            reverseOrder={false}
            gutter={8}
            toastOptions={{
                duration: 7500,
                style: {
                    background: config.theme.colors.secondaryBlue['50'],
                    color: config.theme.colors.secondaryBlue['300']
                },
                success: {
                    icon: '✅',
                    style: {
                        background: config.theme.colors.success['100'],
                        color: config.theme.colors.success['500']
                    }
                },
                error: {
                    icon: '🚫',
                    style: {
                        background: config.theme.colors.error['100'],
                        color: config.theme.colors.error['500']
                    }
                }
            }}
        />
    )
}
