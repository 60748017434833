import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const StarIcon = ({ color = 'black', size = { height: 16, width: 16 }, ...props }: IconProps) => {
    const theme = useTheme()
    const themeColor = getColorFromString(theme, color)
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 17 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M8.5 0L10.9981 4.76393L16.584 5.52786L12.542 9.23607L13.4962 14.4721L8.5 12L3.50383 14.4721L4.45801 9.23607L0.416016 5.52786L6.00191 4.76393L8.5 0Z'
                fill={themeColor}
            />
        </RootIcon>
    )
}

export default StarIcon
