import { ButtonColor, ButtonVariant } from './Button.types'
import { DefaultTheme, ThemedCssFunction } from 'styled-components'
import tw, { css, styled } from 'twin.macro'

export const ButtonVariantStyles: Record<ButtonVariant, ReturnType<ThemedCssFunction<DefaultTheme>>> = {
    normal: css<{ $color: ButtonColor }>`
        ${tw`text-basic-white`}
        ${({ $color }) => {
            return $color === 'red' ? tw`bg-secondaryRed-300` : tw`bg-background-primary`
        }}
      :hover:not(:disabled) {
            ${({ $color }) => {
                return $color === 'red' ? tw`bg-secondaryRed-200` : tw`bg-secondaryBlue-200`
            }}
        }
    `,
    text: css<{ $color: ButtonColor }>`
        background: none;

        ${({ $color }) => {
            return $color === 'red' ? tw`text-secondaryRed-200` : tw`text-text-button`
        }}
        :hover:not(:disabled) {
            ${({ $color }) => {
                return $color === 'red' ? tw`bg-secondaryRed-100` : tw`bg-secondaryBlue-100`
            }}
        }
    `
}

export const ButtonLeftIconContainer = styled.span`
    ${tw`flex justify-center items-center`}
    padding-right: 0.313rem;
`

export const ButtonRightIconContainer = styled.span`
    ${tw`flex justify-center items-center`}
    padding-left: 0.313rem;
`

export const ButtonRoot = styled.button<{ $variant: ButtonVariant; $color: ButtonColor }>`
    /* Layout  */
    ${tw`flex justify-center items-center`}

    padding: 1.10rem 2.25rem;
    border-radius: 2.375rem;
    border: none;

    /* Font  */
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.25rem;
    text-align: center;
    letter-spacing: 0.004em;
    text-decoration: none;

    :disabled {
        opacity: 0.6;
        pointer-events: none;
    }

    @media only screen and (max-width: 700px) {
        font-size: 1rem;
    }

    ${({ $variant }) => {
        return ButtonVariantStyles[$variant]
    }}
`
