import { ColumnCols, MediaSize, SCColumnProps } from './Column.types'
import { DefaultTheme, ThemedCssFunction } from 'styled-components'
import tw, { css, styled, theme } from 'twin.macro'

export const ColumnStyles: Record<ColumnCols, ReturnType<ThemedCssFunction<DefaultTheme>>> = {
    auto: css`
        width: auto;
        max-width: unset;
    `,
    1: css`
        width: 8.33333333333%;
        max-width: 8.33333333333%;
    `,
    2: css`
        width: 16.6666666667%;
        max-width: 16.6666666667%;
    `,
    3: css`
        width: 25%;
        max-width: 25%;
    `,
    4: css`
        width: 33.3333333333%;
        max-width: 33.3333333333%;
    `,
    5: css`
        width: 41.6666666667%;
        max-width: 41.6666666667%;
    `,
    6: css`
        width: 50%;
        max-width: 50%;
    `,
    7: css`
        width: 58.3333333333%;
        max-width: 58.3333333333%;
    `,
    8: css`
        width: 66.6666666667%;
        max-width: 66.6666666667%;
    `,
    9: css`
        width: 75%;
        max-width: 75%;
    `,
    10: css`
        width: 83.3333333333%;
        max-width: 83.3333333333%;
    `,
    11: css`
        width: 91.6666666667%;
        max-width: 91.6666666667%;
    `,
    12: css`
        width: 100%;
        max-width: 100%;
    `
}

const responsiveMedia: Record<MediaSize, (cols: ColumnCols) => ReturnType<ThemedCssFunction<DefaultTheme>>> = {
    xs: cols => {
        return css`
            @media (min-width: ${theme`screens.xs`}) {
                ${ColumnStyles[cols]}
            }
        `
    },
    sm: cols => {
        return css`
            @media (min-width: ${theme`screens.sm`}) {
                ${ColumnStyles[cols]}
            }
        `
    },
    md: cols => {
        return css`
            @media (min-width: ${theme`screens.md`}) {
                ${ColumnStyles[cols]}
            }
        `
    },
    lg: cols => {
        return css`
            @media (min-width: ${theme`screens.lg`}) {
                ${ColumnStyles[cols]}
            }
        `
    },
    xl: cols => {
        return css`
            @media (min-width: ${theme`screens.xl`}) {
                ${ColumnStyles[cols]}
            }
        `
    },
    xl2: cols => {
        return css`
            @media (min-width: ${theme`screens.2xl`}) {
                ${ColumnStyles[cols]}
            }
        `
    }
}

export const ColumnRoot = styled.div<SCColumnProps>`
    ${({ $cols }) => {
        return ColumnStyles[$cols]
    }}
    ${({ $xs }) => {
        return $xs ? responsiveMedia.xs($xs) : ''
    }}
  ${({ $sm }) => {
        return $sm ? responsiveMedia.sm($sm) : ''
    }}
  ${({ $md }) => {
        return $md ? responsiveMedia.md($md) : ''
    }}
  ${({ $lg }) => {
        return $lg ? responsiveMedia.lg($lg) : ''
    }}
  ${({ $xl }) => {
        return $xl ? responsiveMedia.xl($xl) : ''
    }}
  ${({ $xl2 }) => {
        return $xl2 ? responsiveMedia.xl2($xl2) : ''
    }}
  ${({ $paddingLeft }) => {
        return $paddingLeft ? tw`pl-2 lg:pl-3` : ''
    }}
  ${({ $paddingRight }) => {
        return $paddingRight ? tw`pr-2 lg:pr-3` : ''
    }}
  ${({ $paddingTop }) => {
        return $paddingTop ? tw`pt-1 lg:pt-2` : ''
    }}
  ${({ $paddingBottom }) => {
        return $paddingBottom ? tw`pb-1 lg:pb-2` : ''
    }}
`
