import { StarsProps } from './Stars.types'
import { StarsRoot } from './Stars.styles'
import EmptyStarIcon from '../icon/EmptyStarIcon'
import HalfStarIcon from '../icon/HalfStarIcon'
import React, { ReactNode, useCallback } from 'react'
import StarIcon from '../icon/StarIcon'

const Stars = ({ value, maxValue = 5, color = 'secondaryBlue-100', ...props }: StarsProps) => {
    const renderStars = useCallback(() => {
        const starElements: ReactNode[] = []
        for (let i = 0; i < maxValue; i++) {
            if (i < value && Math.round(value) === i) {
                starElements.push(<HalfStarIcon color={color} key={i} />)
            } else if (i < value) {
                starElements.push(<StarIcon color={color} key={i} />)
            } else {
                starElements.push(<EmptyStarIcon color={color} key={i} />)
            }
        }
        return starElements
    }, [value, color, maxValue])

    return <StarsRoot {...props}>{renderStars()}</StarsRoot>
}

export default Stars
