import { ChipProps } from './Chip.types'
import { RootChip } from './Chip.styles'
import React from 'react'

const Chip = ({ children, color, className: classNameProp, ...props }: ChipProps) => {
    const className = `text-body-small bold${classNameProp ? ` ${classNameProp}` : ''}`

    return (
        <RootChip {...props} $color={color} className={className}>
            {children}
        </RootChip>
    )
}

export default Chip
