import { AxiosInstance, AxiosRequestConfig } from 'axios'

export const getInteractiveModel = async (
    axiosInstance: AxiosInstance,
    url: string,
    config: AxiosRequestConfig = {}
): Promise<string> => {
    const effectiveConfig = { ...config, baseURL: '' }
    const { data: interactiveModel } = await axiosInstance.get<string>(url, effectiveConfig)
    return interactiveModel
}
