import tw, { styled } from 'twin.macro'

export type stylesProps = {
    shouldDisplayContactHeader?: boolean
    smallScreen?: boolean
}

export const FooterRoot = styled.footer`
    ${tw`bg-text-blue`}

    .google-recaptcha-links {
        ${tw`text-xs text-center text-basic-footer leading-relaxed`}

        a {
            ${tw`text-secondaryBlue-50 hover:!text-opacity-60`}
        }
    }
`

export const FooterLinks = styled.ul`
    ${tw`my-0 mx-0`}
    li {
        ${tw`my-0 mx-0`}
        padding: 0.690rem 0;
        text-transform: uppercase;
    }
`

export const FooterLink = styled.a`
    ${tw`text-secondaryBlue-50 hover:!text-opacity-60`}
`

export const TopBorder = styled.span<stylesProps>`
    ${tw`block border-b border-b-secondaryBlue-300 absolute h-1.5 left-0 right-0`}
    ${({ shouldDisplayContactHeader }) => (shouldDisplayContactHeader ? 'top: 5.20rem;' : 'top: 7.20rem;')}
`

export const TitleBorder = styled.span<stylesProps>`
    ${tw`block bg-secondaryRed-400`}
    height: 0.188rem;
    width: 2rem;
    ${({ shouldDisplayContactHeader, smallScreen = false }) =>
        smallScreen
            ? 'position: relative;'
            : `position: absolute; ${shouldDisplayContactHeader ? 'top: 5.4rem;' : 'top: 7.4rem;'}`}
    z-index: 2;
`

export const TitleContainer = styled.div<stylesProps>`
    ${({ shouldDisplayContactHeader }) => shouldDisplayContactHeader && 'padding-top: 0.625rem;'}
    ${({ shouldDisplayContactHeader }) => shouldDisplayContactHeader && 'padding-bottom: 2.25rem;'}
`

export const Content = styled.div`
    padding-top: 2.6rem;
`

export const FooterRemaxLogo = styled.span`
    ${tw`flex flex-col items-center text-center`}
    h3 {
        padding-top: 0.875rem;
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.188rem;
    }
`

export const FooterDactylGroupLogo = styled.span`
    path {
        fill-opacity: 0.4;
    }

    :hover {
        path {
            fill-opacity: 1;
        }
    }
`

export const FooterLogoItem = styled.div`
    ${tw`flex justify-center lg:justify-start py-5 lg:py-0`}
`
