import tw, { styled } from 'twin.macro'

export const CheckboxContainerRoot = styled.div`
    padding: 0.75rem 0;
`

export const CheckboxRoot = styled.div`
    ${tw`flex items-start`}

    input {
        ${tw`block`}
        width: 1rem;
        accent-color: ${({ theme: { colors } }) => {
            return colors.basic.primary
        }};
    }

    label {
        ${tw`block relative`}
        top: -0.6rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: 0.004em;
        margin-left: 0.5rem;
        color: ${({ theme: { colors } }) => {
            return colors.text.blue
        }};
    }

    @media only screen and (max-width: 700px) {
        label {
            font-size: 0.88rem;
        }
    }
`
