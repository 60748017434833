import { ComponentManagerComponentRow } from './ComponentManager.styles'
import { ComponentParamsProps } from './ComponentManager.types'
import { InfoListItem } from '../info-list/InfoList.types'
import { Element as ScrollElement } from 'react-scroll'
import { chunkArray } from '../../utils/Helper'
import Column from '../grid/column/Column'
import InfoList from '../info-list/InfoList'
import React, { useMemo } from 'react'
import Row from '../grid/row/Row'
import SectionTitle from '../section-title/SectionTitle'

const ComponentParams = ({ component: { params, title, hash }, ...props }: ComponentParamsProps) => {
    const items = useMemo<[InfoListItem[]]>(() => {
        if (!params.length) {
            return
        }

        const mappedItems: InfoListItem[] = params.map(param => {
            return { key: param.name, value: param.value }
        })
        return chunkArray(mappedItems, Math.ceil(mappedItems.length / 2))
    }, [params])

    if (items.length) {
        return (
            <ScrollElement name={hash}>
                <ComponentManagerComponentRow {...props}>
                    <Column cols={12}>
                        <SectionTitle>{title}</SectionTitle>
                    </Column>
                    <Column cols={12}>
                        <Row>
                            {items.map((item, index) => {
                                return (
                                    <Column key={index} cols={12} lg={6}>
                                        <InfoList items={item} />
                                    </Column>
                                )
                            })}
                        </Row>
                    </Column>
                </ComponentManagerComponentRow>
            </ScrollElement>
        )
    }
}

export default ComponentParams
