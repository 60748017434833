import { CardContactOrientation, CardContactVariant } from './CardContact.types'
import { DefaultTheme, ThemedCssFunction, css } from 'styled-components'
import { getColorFromString } from '../../../utils/Helper'
import tw, { styled } from 'twin.macro'

export const CardContactVariantStyles: Partial<
    Record<CardContactVariant, ReturnType<ThemedCssFunction<DefaultTheme>>>
> = {
    normal: css<{ $color: string }>`
        background-color: ${({ $color, theme }) => {
            return $color ? getColorFromString(theme, $color) : 'white'
        }};
    `
}

export const CardContactRoot = styled.div<{
    $variant: CardContactVariant
    $orientation: CardContactOrientation
    $color: string
    $removePadding: boolean
    $shadow: boolean
}>`
  ${tw`flex relative`}
  ${({ $orientation }) => {
      return $orientation === 'vertical' ? tw`!text-center sm:!text-left` : tw`w-full`
  }}
  flex-direction: ${({ $orientation }) => {
      return $orientation === 'horizontal' ? 'row' : 'column'
  }};
  ${({ $orientation }) => {
      return $orientation === 'horizontal' ? 'align-items' : 'justify-content'
  }}: center;
  padding: ${({ $removePadding }) => {
      return $removePadding ? 0 : '1.25rem'
  }};
  border-radius: 1.563rem;
  border: 0.125rem solid ${({ $color, theme }) => {
      return $color ? getColorFromString(theme, $color) : 'white'
  }};
    }};
  ${({ $variant }) => {
      return CardContactVariantStyles[$variant]
  }}
  box-shadow: ${({ $shadow }) => {
      return $shadow && '0px 2.875rem 4rem rgba(0, 43, 92, 0.1)'
  }};
`

export const CardContactContainer = styled.div<{ $hasImage: boolean; $orientation: CardContactOrientation }>`
    ${tw`flex flex-col h-full`}
    ${({ $hasImage, $orientation }) => {
        return $hasImage && $orientation === 'horizontal' && tw`ml-[1rem] -mt-2`
    }}
`

export const CardContactName = styled.div<{
    $orientation: CardContactOrientation
    $hasImage: boolean
    $centered: boolean
}>`
    ${tw`flex flex-col`}

    ${({ $orientation, $hasImage }) => {
        return $orientation === 'vertical' && $hasImage && 'padding-top: 0.25rem;'
    }}
  ${({ $orientation }) => {
        return $orientation === 'horizontal' ? 'padding-bottom: 0.2rem;' : ''
    }}
  ${({ $orientation, $centered }) => {
        return $orientation === 'vertical' && $centered && tw`text-center`
    }}
  
  small {
        margin-top: -0.8rem;
    }
`

export const CardContactList = styled.ul<{ $orientation: CardContactOrientation; $centered: boolean }>`
    ${tw`flex flex-col m-0 p-0 list-none`}
    ${({ $centered }) => {
        return $centered && tw`items-center`
    }}
  li {
        ${tw`flex items-center gap-2 sm:gap-2`}
        span {
            ${tw`flex items-center md:items-start`}
        }
        a {
            word-break: break-word;
        }
    }
`

export const CardContactImageContainer = styled.div<{ $orientation: CardContactOrientation; $centered: boolean }>`
    ${tw`flex items-center`}
    ${({ $orientation }) => {
        return $orientation === 'vertical' ? tw`!mx-auto sm:!mx-0` : ''
    }}
  ${({ $orientation, $centered }) => {
        return $orientation === 'vertical' && $centered && tw`sm:!mx-auto`
    }}

  img {
        border-radius: 1.563rem;
        aspect-ratio: 65 / 84;
        object-fit: cover;
    }
`

export const CardContactCertificate = styled.div<{
    $orientation: CardContactOrientation
    $centered: boolean
    $appendStarsToImage: boolean
}>`
    ${({ $orientation }) => {
        return $orientation === 'horizontal' ? tw`mt-2` : tw`mt-2.5 flex flex-col items-center sm:block`
    }}
    ${({ $orientation, $centered }) => {
        return $orientation === 'vertical' && $centered && tw`mx-auto`
    }}
  
  img {
        ${tw`mt-3`}
        ${({ $orientation, $appendStarsToImage }) => {
            return $orientation === 'horizontal' && !$appendStarsToImage && tw`absolute right-5 top-1`
        }}
    }
`
