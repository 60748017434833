import { ImageIconSize } from './Image.types'
import { pxToRems } from '../../utils/Helper'
import tw, { css, styled } from 'twin.macro'

export const ImageRoot = styled.div<{
    $width: number | string
    $height: number | string
    $rounded: boolean
    $clickable: boolean
}>`
    ${tw`relative w-full h-full`}
    width: ${({ $width }) => {
        return typeof $width === 'string' ? $width : `${pxToRems($width)}rem`
    }};
    height: ${({ $height }) => {
        return typeof $height === 'string' ? $height : `${pxToRems($height)}rem`
    }};
    border-radius: ${({ $rounded }) => {
        return $rounded ? '1.563rem' : '0'
    }};
    cursor: ${({ $clickable }) => {
        return $clickable ? 'pointer' : 'auto'
    }};
`

export const ImageIconContainer = styled.span<{ $size: ImageIconSize }>`
    ${tw`hidden lg:flex absolute items-center justify-center`}
    left: ${({ $size }) => {
        return $size === 'small' ? '-0.5625rem' : '-1.125rem'
    }};
    top: ${({ $size }) => {
        return $size === 'small' ? '-1.875rem' : '-3.75rem'
    }};
    background: ${({ theme: { colors } }) => {
        return colors.secondaryBlue['100']
    }};
    min-width: ${({ $size }) => {
        return $size === 'small' ? '3rem' : '6rem'
    }};
    min-height: ${({ $size }) => {
        return $size === 'small' ? '3rem' : '6rem'
    }};
    border-radius: 0.625rem;
`

export const ImageOverlay = styled.div<{ $rounded: boolean }>`
    ${tw`flex justify-center items-center absolute top-0 left-0 w-full h-full`}
    background: ${({ theme: { colors } }) => {
        return colors.background.gradient
    }};
    border-radius: ${({ $rounded }) => {
        return $rounded ? '1.563rem' : '0'
    }};

    &.fadeOut {
        opacity: 0;
        transition: opacity 0.5s;
    }

    &.fadeIn {
        opacity: 1;
        transition: opacity 0.5s;
    }
`

export const Image = styled.img<{ $rounded: boolean; $aspectRatio?: string }>`
    ${tw`w-full h-full`}
    border-radius: ${({ $rounded }) => {
        return $rounded ? '1.563rem' : '0'
    }};
    object-fit: cover;
    ${({ $aspectRatio }) => {
        return (
            $aspectRatio &&
            css`
                aspect-ratio: ${$aspectRatio};
            `
        )
    }}
`
