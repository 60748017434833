import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const CooperativeHouseIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M23.3023 20.5903H22.3721V9.99901C22.3721 9.41635 22.1116 8.87127 21.6558 8.51416L20.5116 7.61197L20.493 5.30951C20.493 4.79263 20.0744 4.37913 19.5628 4.37913H16.3907L15.1442 3.40176C14.4744 2.86608 13.5256 2.86608 12.8558 3.40176L6.34419 8.51416C5.88837 8.87127 5.62791 9.41635 5.62791 9.98961L5.5814 20.5903H4.69767C4.31628 20.5903 4 20.9099 4 21.2952C4 21.6805 4.31628 22 4.69767 22H23.3023C23.6837 22 24 21.6805 24 21.2952C24 20.9099 23.6837 20.5903 23.3023 20.5903ZM16.3256 20.5903H11.6744V18.0059C11.6744 17.2259 12.2977 16.5963 13.0698 16.5963H14.9302C15.7023 16.5963 16.3256 17.2259 16.3256 18.0059V20.5903ZM19.1163 12.6022C19.1163 13.1191 18.6977 13.542 18.186 13.542H16.3256C15.814 13.542 15.3953 13.1191 15.3953 12.6022V11.1925C15.3953 10.6757 15.814 10.2528 16.3256 10.2528H18.186C18.6977 10.2528 19.1163 10.6757 19.1163 11.1925V12.6022Z'
                fill={fillColor}
            />
            <path
                d='M13 11C13 14.3137 10.3137 17 7 17C3.68629 17 1 14.3137 1 11C1 7.68629 3.68629 5 7 5C10.3137 5 13 7.68629 13 11Z'
                fill='white'
            />
            <path
                d='M10.8333 9.41667C10.8333 12.4082 8.40821 14.8333 5.41667 14.8333C2.42512 14.8333 0 12.4082 0 9.41667C0 6.42512 2.42512 4 5.41667 4C8.40821 4 10.8333 6.42512 10.8333 9.41667Z'
                fill='white'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7 16.5C10.0376 16.5 12.5 14.0376 12.5 11C12.5 7.96243 10.0376 5.5 7 5.5C3.96243 5.5 1.5 7.96243 1.5 11C1.5 14.0376 3.96243 16.5 7 16.5ZM7 18C10.866 18 14 14.866 14 11C14 7.13401 10.866 4 7 4C3.13401 4 0 7.13401 0 11C0 14.866 3.13401 18 7 18Z'
                fill={fillColor}
            />
            <path
                d='M10.0003 8C9.25599 8 8.65088 8.60511 8.65088 9.34943C8.65088 10.0795 9.2219 10.6705 9.96622 10.696C9.98895 10.6932 10.0117 10.6932 10.0287 10.696C10.0344 10.696 10.0372 10.696 10.0429 10.696C10.0458 10.696 10.0458 10.696 10.0486 10.696C10.7759 10.6705 11.3469 10.0795 11.3497 9.34943C11.3497 8.60511 10.7446 8 10.0003 8Z'
                fill={fillColor}
            />
            <path
                d='M11.4432 11.4517C10.6506 10.9233 9.35795 10.9233 8.55966 11.4517C8.19886 11.6932 8 12.0199 8 12.3693C8 12.7188 8.19886 13.0426 8.55682 13.2813C8.95455 13.5483 9.47727 13.6818 10 13.6818C10.5227 13.6818 11.0455 13.5483 11.4432 13.2813C11.8011 13.0398 12 12.7159 12 12.3636C11.9972 12.0142 11.8011 11.6903 11.4432 11.4517Z'
                fill={fillColor}
            />
            <path
                d='M4.00031 8C3.25599 8 2.65088 8.60511 2.65088 9.34943C2.65088 10.0795 3.2219 10.6705 3.96622 10.696C3.98895 10.6932 4.01167 10.6932 4.02872 10.696C4.0344 10.696 4.03724 10.696 4.04292 10.696C4.04576 10.696 4.04576 10.696 4.04861 10.696C4.77588 10.6705 5.3469 10.0795 5.34974 9.34943C5.34974 8.60511 4.74463 8 4.00031 8Z'
                fill={fillColor}
            />
            <path
                d='M5.44318 11.4517C4.65057 10.9233 3.35795 10.9233 2.55966 11.4517C2.19886 11.6932 2 12.0199 2 12.3693C2 12.7188 2.19886 13.0426 2.55682 13.2813C2.95455 13.5483 3.47727 13.6818 4 13.6818C4.52273 13.6818 5.04545 13.5483 5.44318 13.2813C5.80114 13.0398 6 12.7159 6 12.3636C5.99716 12.0142 5.80114 11.6903 5.44318 11.4517Z'
                fill={fillColor}
            />
            <path
                d='M7.00008 8.68182C6.1724 8.68182 5.49951 9.35471 5.49951 10.1824C5.49951 10.9943 6.13449 11.6514 6.96217 11.6798C6.98744 11.6766 7.01271 11.6766 7.03167 11.6798C7.03799 11.6798 7.04114 11.6798 7.04746 11.6798C7.05062 11.6798 7.05062 11.6798 7.05378 11.6798C7.86251 11.6514 8.49748 10.9943 8.50064 10.1824C8.50064 9.35471 7.82776 8.68182 7.00008 8.68182Z'
                fill={fillColor}
            />
            <path
                d='M5.4508 14.4715L5.45053 14.4714C5.07269 14.2195 4.87588 13.8874 4.87588 13.5405C4.87588 13.1935 5.07276 12.8584 5.45363 12.6034C5.87779 12.3227 6.4387 12.1794 7.00382 12.1794C7.56907 12.1794 8.12834 12.3227 8.54922 12.6033C8.92634 12.8547 9.12089 13.1867 9.12387 13.5346C9.12372 13.8846 8.92682 14.2166 8.54889 14.4716C8.12645 14.7552 7.56571 14.9 6.99987 14.9C6.43401 14.9 5.87325 14.7552 5.4508 14.4715Z'
                fill={fillColor}
                stroke='white'
                strokeWidth='0.2'
            />
        </RootIcon>
    )
}

export default CooperativeHouseIcon
