import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { MicroSite } from '../Microsite.types'
import { apiConfig } from '../../config/api/apiConfig'

export const getProjectMicroSite = async (
    axiosInstance: AxiosInstance,
    slug: string,
    config: AxiosRequestConfig = {}
): Promise<MicroSite> => {
    config = {
        ...config,
        maxRedirects: 0
    }

    const {
        data: { data: propertyMicroSite }
    } = await axiosInstance.get<AxiosResponse<MicroSite>>(
        apiConfig.projectMicroSite.get.replace('{slug}', slug),
        config
    )
    return propertyMicroSite
}

export const getProjectMicroSiteProperty = async (
    axiosInstance: AxiosInstance,
    id: number,
    config: AxiosRequestConfig = {}
): Promise<MicroSite> => {
    const {
        data: { data: propertyMicroSite }
    } = await axiosInstance.get<AxiosResponse<MicroSite>>(
        apiConfig.projectMicroSite.getProperty.replace('{id}', id.toString()),
        config
    )
    return propertyMicroSite
}
