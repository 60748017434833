import { ComponentFinancingProps } from './ComponentManager.types'
import { ComponentManagerComponentRow } from './ComponentManager.styles'
import { HtmlTextContainerRoot } from '../../styles/GlobalStyles'
import { Element as ScrollElement } from 'react-scroll'
import { extractImageUrl } from '../../utils/Helper'
import ArrowConnection from '../../assets/img/arrow-connection.svg'
import Card from '../card/Card'
import CardContact from '../card/contact/CardContact'
import Column from '../grid/column/Column'
import HalfFilledIcon from '../icon/HalfFilledIcon'
import React from 'react'
import Row from '../grid/row/Row'
import SectionTitle from '../section-title/SectionTitle'
import useWindowSize from '../../hooks/useWindowSize'

const CONTACT_IMAGE_SIZES = { width: 130, height: 168 }

const ComponentFinancing = ({
    component: {
        title,
        leftBoxText,
        leftBoxTitle,
        rightBoxTitle,
        rightBoxText,
        brokerName,
        brokerPosition,
        brokerPhoto,
        brokerEmail,
        brokerPhone,
        description,
        hash
    }
}: ComponentFinancingProps) => {
    const { width: windowWidth } = useWindowSize()

    return (
        <ScrollElement name={hash}>
            <ComponentManagerComponentRow>
                <Column cols={12}>
                    <SectionTitle>{title}</SectionTitle>
                </Column>
                <Column cols={12}>
                    <Row>
                        <Column cols={12} xl={5} className='flex justify-center md:block'>
                            <CardContact
                                color='transparent'
                                cardContactTextColor='text-basic-primary'
                                sellerPositionColor='text-text-secondary'
                                emailIconColor='basic-primary'
                                callIconColor='basic-primary'
                                removePadding
                                centered={windowWidth <= 700}
                                orientation={windowWidth >= 700 ? 'horizontal' : 'vertical'}
                                name={brokerName}
                                role={brokerPosition}
                                phoneNumber={brokerPhone}
                                emailAddress={brokerEmail}
                                image={extractImageUrl(brokerPhoto, CONTACT_IMAGE_SIZES)}
                            />
                        </Column>
                        <Column cols={12} xl={7}>
                            <HtmlTextContainerRoot>
                                <div
                                    className='text-body-normal font-normal text-basic-primary pt-10 xl:pt-0 text-center md:text-justify'
                                    dangerouslySetInnerHTML={{ __html: description }}
                                />
                            </HtmlTextContainerRoot>
                        </Column>
                    </Row>
                </Column>
                <Column cols={12} className='mt-11' paddingY={false}>
                    <Row marginX={false}>
                        <Column cols={12} lg={5} paddingX={false}>
                            <Card
                                title={leftBoxTitle}
                                icon={<HalfFilledIcon color='text-button' size={32} />}
                                className='bg-error-100'
                                iconContainerClassName='!bg-basic-white'
                                renderAsHtml
                            >
                                {leftBoxText}
                            </Card>
                        </Column>
                        <Column cols={12} lg={2} paddingX={false} paddingY={false} className='lg:relative lg:block'>
                            <ArrowConnection className='rotate-45 lg:rotate-0 mt-16 lg:mt-52 w-full lg:absolute' />
                        </Column>
                        <Column cols={12} lg={5} paddingX={false} className='mt-10 lg:mt-0'>
                            <Card
                                title={rightBoxTitle}
                                icon={<HalfFilledIcon color='text-button' size={32} />}
                                className='bg-secondaryBlue-100'
                                iconContainerClassName='!bg-basic-white'
                                renderAsHtml
                            >
                                {rightBoxText}
                            </Card>
                        </Column>
                    </Row>
                </Column>
            </ComponentManagerComponentRow>
        </ScrollElement>
    )
}

export default ComponentFinancing
