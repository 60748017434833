import { NavbarMobileMenuButtonRoot } from './Navbar.styles'
import { NavbarOpenMobileMenuButtonProps } from './Navbar.types'
import BurgerIcon from '../../components/icon/BurgerIcon'
import React, { HTMLAttributes } from 'react'

const NavbarOpenMobileMenuButton = ({ onClick, ...props }: NavbarOpenMobileMenuButtonProps) => {
    return (
        <NavbarMobileMenuButtonRoot {...(props as HTMLAttributes<HTMLButtonElement>)} onClick={onClick}>
            <span className='sr-only'>Otevřít hlavní nabídku</span>
            <BurgerIcon color='basic-primary' size={24} />
        </NavbarMobileMenuButtonRoot>
    )
}

export default NavbarOpenMobileMenuButton
