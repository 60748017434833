import tw, { styled } from 'twin.macro'

export const PriceDiscountedRoot = styled.span`
    ${tw`text-lg font-semibold text-gray-400 !my-0 !leading-none relative`}

    &::after {
        ${tw`bg-error-400 block absolute w-full h-0.5 top-0 right-0 transform -rotate-[14deg] mt-[.6rem]`}
        content: "";
    }
    @media screen and (max-width: 1200px) {
        ${tw`mr-0 ml-auto text-lg font-semibold text-gray-400 !my-0 !leading-none relative`}
    }
`

export const PriceContainerRoot = styled.div`
    ${tw`flex flex-col lg:flex-row lg:items-center justify-center`}
    @media screen and (max-width: 1200px) {
        ${tw`flex flex-col lg:flex-row lg:items-center justify-end`}
    }
`

export const PriceRoot = styled.h3`
    ${tw`text-lg font-bold text-basic-black !my-0 !leading-none`}
`
