import { pxToRems } from '../../utils/Helper'
import { styled } from 'twin.macro'

export const RootIcon = styled.svg<{ $height: number; $width: number }>`
    width: ${({ $width }) => {
        return pxToRems($width)
    }}rem;
    height: ${({ $height }) => {
        return pxToRems($height)
    }}rem;
`
