import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const PinterestIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.0988 16.3521C11.7878 16.3736 12.4101 17.2528 14.2325 17.2528C17.6551 17.2743 20.0109 14.7438 20.7443 11.4199C23.3001 -0.0959377 5.89831 -1.01806 4.12035 8.4605C3.69808 10.6907 4.38704 13.2641 6.18722 14.1005C7.56514 14.7438 7.65404 12.8995 7.23178 12.1061C5.36492 8.58916 7.60959 5.41535 10.5432 4.51467C13.2991 3.65688 15.2548 4.36456 16.7439 5.84424C18.6552 7.75282 17.7884 12.9424 15.5215 14.5937C14.7214 15.1727 13.2546 15.3014 12.499 14.6151C10.9433 13.2212 13.3435 10.2619 12.9879 8.09594C12.6323 5.95147 9.12086 6.27314 8.94306 9.42551C8.85417 11.0339 9.3431 11.5056 8.96529 13.114C8.36523 15.6874 6.54282 20.9413 7.83184 23C9.72092 22.1422 10.6544 16.9955 11.0988 16.3521Z'
                fill={getColorFromString(theme, color)}
            />
        </RootIcon>
    )
}

export default PinterestIcon
