import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const GarageIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M3 9.53232V20.7895C3 21.3418 3.44771 21.7895 4 21.7895H5.41379C5.96608 21.7895 6.41379 21.3418 6.41379 20.7895V12.0528C6.41379 11.5005 6.86151 11.0528 7.41379 11.0528H16.5862C17.1385 11.0528 17.5862 11.5005 17.5862 12.0528V20.7895C17.5862 21.3418 18.0339 21.7895 18.5862 21.7895H20C20.5523 21.7895 21 21.3418 21 20.7895V9.49102C21 8.86 20.7022 8.266 20.1966 7.88846L13.4666 2.86333C12.7762 2.34786 11.8333 2.33243 11.1265 2.82503L3.85649 7.89146C3.31985 8.26545 3 8.87822 3 9.53232Z'
                fill={fillColor}
            />
            <rect x='7.73682' y='12.3659' width='8.52632' height='1.88471' rx='0.942356' fill={fillColor} />
            <rect x='7.73682' y='15.193' width='8.52632' height='1.88471' rx='0.942356' fill={fillColor} />
            <rect x='7.73682' y='18.0201' width='8.52632' height='1.88471' rx='0.942356' fill={fillColor} />
        </RootIcon>
    )
}

export default GarageIcon
