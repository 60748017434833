import { ComponentDocumentsProps } from './ComponentManager.types'
import { ComponentManagerComponentRow } from './ComponentManager.styles'
import { Element as ScrollElement } from 'react-scroll'
import CardFile from '../card/file/CardFile'
import Column from '../grid/column/Column'
import React from 'react'
import SectionTitle from '../section-title/SectionTitle'

const ComponentDocuments = ({
    component: { title, files, hash },
    darkerBackground,
    ...props
}: ComponentDocumentsProps) => {
    if (files.length) {
        return (
            <ScrollElement name={hash}>
                <ComponentManagerComponentRow {...props}>
                    <Column cols={12}>
                        <SectionTitle>{title}</SectionTitle>
                    </Column>
                    {files.map(({ name, url }, index) => {
                        return (
                            <Column key={index} cols={12} md={6} lg={4} xl={3}>
                                <CardFile
                                    title={name}
                                    href={url}
                                    className={darkerBackground ? 'bg-secondaryBlue-100' : 'bg-basic-white'}
                                />
                            </Column>
                        )
                    })}
                </ComponentManagerComponentRow>
            </ScrollElement>
        )
    }
}

export default ComponentDocuments
