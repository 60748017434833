import {
    CardContactCertificate,
    CardContactContainer,
    CardContactImageContainer,
    CardContactList,
    CardContactName,
    CardContactRoot
} from './CardContact.styles'
import { CardContactProps } from './CardContact.types'
import { buildClasses } from '../../../utils/Helper'
import CallIcon from '../../icon/CallIcon'
import CardContactDetailUrl from './CardContactDetailUrl'
import CardContactListItem from './CardContactListItem'
import EmailIcon from '../../icon/EmailIcon'
import React from 'react'
import Stars from '../../stars/Stars'
import Typography from '../../typography/Typography'

const CardContact = ({
    variant = 'normal',
    orientation = 'horizontal',
    color,
    callIconColor,
    emailIconColor,
    cardContactTextColor,
    sellerPositionColor,
    removePadding = false,
    image,
    name,
    role,
    phoneNumber,
    emailAddress,
    starCount,
    certificateImage,
    certificateImageAlt,
    certificateImageTitle,
    centeredCertificates,
    centered,
    detailUrl,
    shadow,
    ...props
}: CardContactProps) => {
    const renderImage = () => {
        return (
            image && (
                <CardContactImageContainer $orientation={orientation} $centered={centered}>
                    <CardContactDetailUrl url={detailUrl}>
                        <img src={image} alt={name} />
                    </CardContactDetailUrl>
                </CardContactImageContainer>
            )
        )
    }

    const renderName = () => {
        if (detailUrl) {
            return (
                <CardContactDetailUrl showUnderline url={detailUrl}>
                    {name}
                </CardContactDetailUrl>
            )
        }
        return name
    }

    const renderCertificate = () => {
        return (
            (starCount > 0 || certificateImage) && (
                <CardContactCertificate
                    $orientation={orientation}
                    $centered={centered}
                    $appendStarsToImage={centeredCertificates}
                    className={buildClasses({ 'ml-28': centeredCertificates })}
                >
                    {starCount > 0 && (
                        <Stars
                            value={starCount}
                            maxValue={starCount}
                            color='warning-200'
                            className={buildClasses({ 'justify-center': centered })}
                        />
                    )}
                    {certificateImage && (
                        <img src={certificateImage} alt={certificateImageAlt} title={certificateImageTitle} />
                    )}
                </CardContactCertificate>
            )
        )
    }

    return (
        <CardContactRoot
            {...props}
            $variant={variant}
            $orientation={orientation}
            $color={color}
            $removePadding={removePadding}
            $shadow={shadow}
        >
            {renderImage()}
            <CardContactContainer $hasImage={!!image} $orientation={orientation}>
                <CardContactName $orientation={orientation} $hasImage={!!image} $centered={centered}>
                    <Typography as='h4' level='h4' className={`${cardContactTextColor}`}>
                        {renderName()}
                    </Typography>
                    <Typography
                        as='small'
                        level='body'
                        size='small'
                        type='semibold'
                        className={`${sellerPositionColor} pt-3`}
                    >
                        {role}
                    </Typography>
                </CardContactName>
                <CardContactList $orientation={orientation} $centered={centered}>
                    <CardContactListItem
                        centered={centered}
                        icon={<CallIcon color={callIconColor} size={24} />}
                        value={
                            <a className={`hover:underline ${cardContactTextColor}`} href={`tel:${phoneNumber}`}>
                                {phoneNumber}
                            </a>
                        }
                    />
                    <CardContactListItem
                        centered={centered}
                        icon={<EmailIcon color={emailIconColor} size={24} />}
                        value={
                            <a className={`hover:underline ${cardContactTextColor}`} href={`mailto:${emailAddress}`}>
                                {emailAddress}
                            </a>
                        }
                    />
                </CardContactList>
                {!centeredCertificates && renderCertificate()}
            </CardContactContainer>
            {centeredCertificates && renderCertificate()}
        </CardContactRoot>
    )
}

export default CardContact
