import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const GardenIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3 19.25C3 18.8358 3.33579 18.5 3.75 18.5L20.5577 18.5C20.9719 18.5 21.3077 18.8358 21.3077 19.25C21.3077 19.6642 20.9719 20 20.5577 20L3.75 20C3.33579 20 3 19.6642 3 19.25Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.1538 9.44231C12.568 9.44231 12.9038 9.7781 12.9038 10.1923L12.9038 18.6923C12.9038 19.1065 12.568 19.4423 12.1538 19.4423C11.7396 19.4423 11.4038 19.1065 11.4038 18.6923L11.4038 10.1923C11.4038 9.7781 11.7396 9.44231 12.1538 9.44231Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.74162 3.76896C5.65408 3.76409 5.59169 3.82375 5.5836 3.89665C5.4206 5.36592 5.59093 6.98088 6.39569 8.18002C7.16373 9.32445 8.61175 10.2393 11.3604 10.1454C11.4437 10.1426 11.508 10.0792 11.5161 10.0045C11.6699 8.58665 11.4917 7.52699 11.1474 6.73025C10.8044 5.93643 10.2778 5.35986 9.66384 4.93398C8.40975 4.06403 6.79692 3.82773 5.74162 3.76896ZM4.09275 3.73125C4.19023 2.85253 4.95659 2.22292 5.82502 2.27128C6.9599 2.33448 8.91626 2.58982 10.5188 3.7015C11.3332 4.26644 12.0568 5.05319 12.5243 6.13524C12.9907 7.21437 13.1835 8.54312 13.0073 10.1663C12.9178 10.991 12.2325 11.6165 11.4116 11.6445C8.26806 11.7519 6.26938 10.6836 5.15017 9.0159C4.06768 7.40292 3.9102 5.37676 4.09275 3.73125Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M19.2863 7.88696C19.2912 7.79943 19.2315 7.73704 19.1587 7.72895C17.6894 7.56595 16.0744 7.73628 14.8753 8.54103C13.7308 9.30908 12.816 10.7571 12.9099 13.5058C12.9127 13.589 12.9761 13.6533 13.0508 13.6614C14.4686 13.8153 15.5283 13.637 16.325 13.2928C17.1189 12.9497 17.6954 12.4231 18.1213 11.8092C18.9913 10.5551 19.2276 8.94227 19.2863 7.88696ZM19.324 6.2381C20.2028 6.33558 20.8324 7.10194 20.784 7.97037C20.7208 9.10525 20.4655 11.0616 19.3538 12.6642C18.7889 13.4786 18.0021 14.2021 16.9201 14.6697C15.8409 15.136 14.5122 15.3288 12.889 15.1527C12.0642 15.0632 11.4388 14.3779 11.4108 13.557C11.3034 10.4134 12.3717 8.41472 14.0394 7.29552C15.6524 6.21302 17.6785 6.05554 19.324 6.2381Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.00824 7.70044C9.30114 7.40755 9.77601 7.40755 10.0689 7.70044L12.0304 9.66198C12.3233 9.95488 12.3233 10.4297 12.0304 10.7226C11.7375 11.0155 11.2627 11.0155 10.9698 10.7226L9.00824 8.7611C8.71535 8.46821 8.71535 7.99334 9.00824 7.70044Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.355 11.1535C15.6479 11.4464 15.6479 11.9213 15.355 12.2142L13.3935 14.1757C13.1006 14.4686 12.6257 14.4686 12.3328 14.1757C12.0399 13.8828 12.0399 13.408 12.3328 13.1151L14.2944 11.1535C14.5873 10.8606 15.0621 10.8606 15.355 11.1535Z'
                fill={fillColor}
            />
        </RootIcon>
    )
}

export default GardenIcon
