import { ColumnProps } from './Column.types'
import { ColumnRoot } from './Column.styles'
import React from 'react'

const Column = ({
    as,
    children,
    removeIfChildrenIsNull = false,
    paddingX = true,
    paddingY = true,
    paddingTop = paddingY,
    paddingBottom = paddingY,
    paddingLeft = paddingX,
    paddingRight = paddingX,
    cols = 'auto',
    xs,
    sm,
    md,
    lg,
    xl,
    xl2,
    ...props
}: ColumnProps) => {
    if (removeIfChildrenIsNull && !children) {
        return null
    }
    return (
        <ColumnRoot
            as={as || 'div'}
            $paddingLeft={paddingLeft}
            $paddingRight={paddingRight}
            $paddingTop={paddingTop}
            $paddingBottom={paddingBottom}
            $cols={cols}
            $xs={xs}
            $sm={sm}
            $md={md}
            $lg={lg}
            $xl={xl}
            $xl2={xl2}
            {...props}
        >
            {children}
        </ColumnRoot>
    )
}

export default Column
