import { MenuItem, NavbarMenuProps } from './Navbar.types'
import { NavbarMenuRoot } from './Navbar.styles'
import NavbarMenuItem from './NavbarMenuItem'
import React, { ForwardedRef, HTMLAttributes, forwardRef } from 'react'

const NavbarMenu = ({ items, onClose, ...props }: NavbarMenuProps, ref: ForwardedRef<HTMLDivElement>) => {
    const renderItems = () => {
        return items.map((item: MenuItem, index: number) => {
            if (!item?.name) {
                return null
            }
            return (
                <NavbarMenuItem
                    key={item?.key ? item.key : index.toString()}
                    scrollId={item.scrollId}
                    onClick={onClose}
                >
                    {item.name}
                </NavbarMenuItem>
            )
        })
    }

    return (
        <NavbarMenuRoot ref={ref} {...(props as HTMLAttributes<HTMLDivElement>)}>
            {renderItems()}
        </NavbarMenuRoot>
    )
}

export default forwardRef(NavbarMenu)
