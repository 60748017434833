import { GradientBackgroundProps } from './Layout.types'
import tw, { styled } from 'twin.macro'

export const GradientBackground = styled.div<GradientBackgroundProps>`
    ${tw`absolute`}

    ${({ theme, themeValue }) => {
        if (themeValue === 'template_default' || themeValue === 'template_red') {
            return `
            background: radial-gradient(circle at 69% 11%, ${theme.colors.background.gradient} 0%, 
              ${theme.colors.background.primary}
              74%);
            filter: blur(20.875rem);
            border-radius: 0 0 0 15rem;
            height: 82.063rem;
            width: 100%;
            z-index: -1;
        `
        } else if (themeValue === 'template_dark') {
            return `
            background: 
            radial-gradient(circle at 69% 31%, rgb(107, 135, 153) 0%, ${theme.colors.background.gradient} 44%),
            ${theme.colors.background.gradient};
          height: 100%;
          width: 100%;
          z-index: -11;
          background-repeat: no-repeat, repeat;
          background-size: 100% 82rem, 100% 100%;
        `
        }
    }}
`

export const LayoutTitleStatusRoot = styled.div`
    ${tw`inline-flex flex-col justify-center lg:justify-start flex-wrap gap-3.5`}
`

export const LayoutTitleStatusImageTitleContainer = styled.div`
    ${tw`flex flex-row flex-wrap lg:flex-nowrap items-center justify-center gap-3.5`}
`
