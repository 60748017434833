import { buildClassesWithDefault, getColorFromString } from '../../utils/Helper'
import Form from './Form'
import tw, { styled } from 'twin.macro'

export const RoundedFormCard = styled(Form).attrs(props => {
    return {
        className: buildClassesWithDefault('page-form', props.className)
    }
})<{ $color?: string }>`
    ${tw`relative`}
    background-color: ${({ $color, theme }) => {
        return $color ? getColorFromString(theme, $color) : 'white'
    }};
    z-index: 2;
    padding: 2.3rem;
    border-radius: 1.563rem;
`
