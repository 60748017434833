import { Container } from '../styles/GlobalStyles'
import { GradientBackground } from './Layout.styles'
import { LayoutProps } from './Layout.types'
import { Notification } from './Notification'
import { scroller } from 'react-scroll'
import { useEnvironment } from '../hooks/useEnvironment'
import { useRouter } from 'next/router'
import ClientRender from '../components/client-render/ClientRender'
import Column from '../components/grid/column/Column'
import Footer from '../components/footer/Footer'
import Grid from '../components/grid/Grid'
import Head from 'next/head'
import LayoutTitleStatus from './LayoutTitleStatus'
import Logo from '../components/logo/Logo'
import MicrositeHeader from '../components/microsite-header/MicrositeHeader'
import Navbar from './navbar/Navbar'
import React, { PropsWithChildren, useEffect } from 'react'
import Row from '../components/grid/row/Row'
import ScrollTopButton from '../components/scroll-top-button/ScrollTopButton'
import parseHTML from 'html-react-parser'

const Layout = ({
    children,
    title,
    titleImg,
    gradient,
    showLogo = true,
    status,
    statusColor = 'success-500',
    contact,
    header,
    previewVideoUrl,
    seo,
    hideBackgroundColor,
    shadowedContact,
    externalScripts,
    components,
    footer,
    themeValue
}: PropsWithChildren<LayoutProps>) => {
    const router = useRouter()
    const { frontendBasePath } = useEnvironment()

    useEffect(() => {
        if (router?.query?.scrollId) {
            const { scrollId } = router.query
            scroller.scrollTo(scrollId, {
                duration: 1000,
                smooth: 'easeInOutQuint',
                offset: -70,
                delay: 100
            })
        }
    }, [router?.query?.scrollId])

    const renderOptionalSEO = () => {
        if (!seo) {
            return
        }
        const { metaTitle, metaDescription, metaImageUrl, metaUrl } = seo
        return (
            <>
                {metaTitle && <meta name='og:title' property='og:title' content={metaTitle} />}
                {metaDescription && (
                    <>
                        <meta name='description' property='description' content={metaDescription} />
                        <meta name='og:description' property='og:description' content={metaDescription} />
                    </>
                )}
                {metaImageUrl && <meta name='og:image' property='og:image' content={metaImageUrl} />}
                {metaUrl && <meta name='og:url' property='og:url' content={metaUrl} />}
            </>
        )
    }

    const renderNavbar = () => {
        const {
            template: { companyLogoUrl, companyUrl }
        } = header
        const logo = companyLogoUrl?.length ? <img src={companyLogoUrl} alt='logo' className='max-h-24' /> : <Logo />
        return (
            <Navbar
                showLogo={showLogo}
                components={components}
                logo={logo}
                logoLocationHref={companyUrl || undefined}
                isLogoFromApi={!!companyLogoUrl}
                googleReviews={footer.googleReviews}
                seznamReviews={footer.seznamReviews}
            />
        )
    }

    return (
        <>
            <Head>
                <meta
                    name='viewport'
                    property='viewport'
                    content='width=device-width, initial-scale=1.0, minimum-scale=1.0'
                />
                <title>{seo?.metaTitle || `RE/MAX - ${title}`}</title>
                {renderOptionalSEO()}
                <meta name='og:locale' property='og:locale' content='cs_CZ' />
                <meta name='og:type' property='og:type' content='website' />
                <meta name='og:site_name' property='og:site_name' content='REMAX Delux' />
                <meta name='keywords' property='keywords' content='RE/MAX, Delux' />
                <meta name='author' property='author' content='Tomáš Šimek' />
                <meta name='robots' property='robots' content='index, follow' />
                <link rel='apple-touch-icon' sizes='180x180' href={`${frontendBasePath}/apple-touch-icon.png`} />
                <link rel='icon' type='image/x-icon' href={`${frontendBasePath}/favicon.ico`} />
                <link rel='icon' type='image/png' sizes='32x32' href={`${frontendBasePath}/favicon-32x32.png`} />
                <link rel='icon' type='image/png' sizes='16x16' href={`${frontendBasePath}/favicon-16x16.png`} />
                {parseHTML(externalScripts)}
            </Head>
            {gradient && <GradientBackground themeValue={themeValue} />}
            <Container>
                <Grid>
                    <ClientRender>{renderNavbar()}</ClientRender>
                    <Row align='center' className='pt-[10.5rem] text-center lg:text-left'>
                        <Column>
                            <LayoutTitleStatus
                                titleImg={titleImg}
                                title={title}
                                statusColor={statusColor}
                                status={status}
                            />
                        </Column>
                    </Row>
                    <MicrositeHeader
                        contact={contact}
                        info={header}
                        shadowedContact={shadowedContact}
                        previewVideoUrl={previewVideoUrl}
                    />
                    {children}
                    <Notification />
                    <Row justify='end'>
                        <Column>
                            <ScrollTopButton />
                        </Column>
                    </Row>
                </Grid>
            </Container>
            {/* Background color 55% */}
            {!hideBackgroundColor && (
                <div className='absolute bottom-0 left-0 right-0 h-[55%] w-full -z-10 bg-background-secondary lg:rounded-r-[22.5rem]' />
            )}
            <Footer className='lg:px-6 pb-6 mt-24' {...footer} />
        </>
    )
}

export default Layout
