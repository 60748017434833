import tw, { css, styled } from 'twin.macro'

export const LightboxRoot = styled.div`
    ${tw`flex items-center fixed top-0 left-0 h-full w-full bg-basic-black bg-opacity-90 px-16 box-border`}
    z-index: 1000;

    @media (max-width: 900px) {
        ${tw`p-0`}
    }
`

export const LightboxClose = styled.span`
    ${tw`text-white absolute top-4 right-4 cursor-pointer`}
    font-size: 2.5rem;
    font-weight: bold;
    opacity: 0.2;

    &:hover {
        opacity: 1;
    }
`

export const LightboxNavigationStyles = css`
    ${tw`h-[80%] text-basic-white cursor-pointer absolute flex items-center px-4`}
    font-size: 3.75rem;
    line-height: 3.75rem;
    font-weight: bold;
    opacity: 0.2;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    &:hover {
        opacity: 1;
    }

    @media (hover: none) {
        &:hover {
            opacity: 0.2;
        }
    }
`

export const LightboxNavigationPreviousButton = styled.button`
    ${tw`left-0`}
    ${LightboxNavigationStyles}
`

export const LightboxNavigationNextButton = styled.button`
    ${tw`right-0`}
    ${LightboxNavigationStyles}
`

export const LightboxImage = styled.img`
    ${tw`max-h-full max-w-full`}
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
`

export const LightboxContent = styled.div`
    ${tw`p-0 m-auto w-[90%] h-full max-h-full text-center`}
`

export const LightboxSlide = styled.div`
    ${tw`relative h-full flex items-center justify-center`}
`
