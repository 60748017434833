import TextField from '../text-field/TextField'
import Typography from '../typography/Typography'
import tw, { styled } from 'twin.macro'

export const MortgageCalculatorContainerRoot = styled.div`
    ${tw`flex items-center`}
`

export const MortgageCalculatorInputRoot = styled(TextField)`
    ${tw`bg-basic-white rounded-full h-10`}
`

export const MortgageCalculatorInputLabel = styled(Typography)`
    ${tw`text-text-blue !text-2xl`}
`
