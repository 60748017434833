import { CardContactListItemProps } from './CardContact.types'
import { buildClassesWithDefault } from '../../../utils/Helper'
import React from 'react'
import Typography from '../../typography/Typography'

const CardContactListItem = ({ centered, icon, value }: CardContactListItemProps) => {
    const classes = {
        'm-auto': centered,
        'flex-col': centered,
        'xl:flex-row': centered
    }

    return (
        <li className={buildClassesWithDefault(classes, 'flex items-start xl:m-0 xl:items-center pt-2 sm:pt-4')}>
            <span className='sm:mb-0 mt-2 sm:mt-0'>{icon}</span>
            <Typography level='body' size='small' type='semibold'>
                {value}
            </Typography>
        </li>
    )
}

export default CardContactListItem
