import { MicrositeHeaderPreviewVideo } from './MicrositeHeaderPreviewVideo'
import { MicrositeHeaderProps } from './MicrositeHeader.types'
import { buildClassesWithDefault } from '../../utils/Helper'
import CardContact from '../card/contact/CardContact'
import Column from '../grid/column/Column'
import MicrositeHeaderEnergyInfo from './MicrositeHeaderEnergyInfo'
import MicrositeHeaderParams from './MicrositeHeaderParams'
import MicrositeHeaderPreviewImage from './MicrositeHeaderPreviewImage'
import MicrositeHeaderPrice from './MicrositeHeaderPrice'
import React from 'react'
import Row from '../grid/row/Row'
import useWindowSize from '../../hooks/useWindowSize'

const MicrositeHeader = ({
    previewVideoUrl,
    paramsFullWidth,
    contact,
    info,
    paramsBgColor,
    shadowedContact,
    type = 'property'
}: MicrositeHeaderProps) => {
    const { width } = useWindowSize()
    const isForProperty = type === 'property'
    const hasPreviewImages = info.previewImageUrls.length > 0
    const showBrokerMainCard = contact.showCard
    const paramsPriceColumnXl = !paramsFullWidth ? (!contact ? 12 : 9) : 12

    const renderPreview = () => {
        if (!previewVideoUrl) {
            return renderPreviewImage()
        } else if (previewVideoUrl) {
            return (
                <MicrositeHeaderPreviewVideo
                    previewVideoUrl={previewVideoUrl}
                    contact={contact}
                    showBrokerMainCard={showBrokerMainCard}
                />
            )
        }
    }

    const renderPreviewImage = () => {
        const hasMorePreviewImages = info.previewImageUrls.length > 1
        if (hasPreviewImages) {
            return (
                <MicrositeHeaderPreviewImage
                    hasMorePreviewImages={hasMorePreviewImages}
                    info={info}
                    contact={contact}
                    showBrokerMainCard={showBrokerMainCard}
                />
            )
        }
        return null
    }

    const renderContact = () => {
        if (!showBrokerMainCard) {
            return null
        }
        if (type === 'property' && contact) {
            return (
                <Column
                    cols={12}
                    lg={hasPreviewImages || previewVideoUrl ? 4 : 9}
                    xl={hasPreviewImages || previewVideoUrl ? 3 : 9}
                    className='order-4 lg:order-none'
                >
                    <CardContact
                        centered={hasPreviewImages || width < 1024 || previewVideoUrl !== null}
                        className={hasPreviewImages || previewVideoUrl ? 'h-[510px]' : undefined}
                        orientation={
                            hasPreviewImages || width < 1024 || previewVideoUrl !== null ? 'vertical' : 'horizontal'
                        }
                        centeredCertificates={width > 1023 && !hasPreviewImages}
                        color='basic-white'
                        callIconColor='text-blue'
                        emailIconColor='text-blue'
                        cardContactTextColor='text-text-blue'
                        sellerPositionColor='text-grey-600'
                        name={contact.name}
                        role={contact.role}
                        phoneNumber={contact.phoneNumber}
                        emailAddress={contact.email}
                        starCount={contact.stars}
                        image={contact.image}
                        certificateImage={contact.certificate?.url}
                        certificateImageAlt={contact.certificate?.alt}
                        certificateImageTitle={contact.certificate?.title}
                        detailUrl={contact.detailUrl}
                        shadow={shadowedContact}
                    />
                </Column>
            )
        }
        return null
    }

    const renderParams = () => {
        if (!info.params) {
            return null
        }
        return (
            <div className='order-2 lg:order-0 lg:order-none empty:hidden'>
                <MicrositeHeaderParams info={info} paramsBgColor={paramsBgColor} />
            </div>
        )
    }

    const renderPriceSection = () => {
        if (!info.price && !info.infoInRk) {
            return null
        }

        const isPriceTypeExists = info.priceType === 'meter' || info.priceType === 'month'
        const classes = {
            'lg:mr-24': isPriceTypeExists
        }
        return (
            <div
                className={buildClassesWithDefault(
                    classes,
                    'order-0 lg:order-1 lg:order-none flex mt-1 items-start lg:justify-end min-h-[50px]'
                )}
            >
                <MicrositeHeaderPrice
                    infoInRk={info.infoInRk}
                    price={info.price}
                    priceDiscounted={info.priceDiscounted}
                    priceNote={info.priceNote}
                    priceType={info.priceType}
                    currency={info.currency}
                />
            </div>
        )
    }

    const renderEnergyLabelForProperty = () => {
        if (!info?.energyLabel?.category || !info?.energyLabel?.label) {
            return null
        }
        const classes = {
            'mt-16': !info.price
        }
        const { energyLabel } = info
        return (
            <div
                className={buildClassesWithDefault(classes, 'lg:order-2 order-1 flex md:justify-end lg:justify-start')}
            >
                <span className='block text-left'>
                    <MicrositeHeaderEnergyInfo isForProperty={isForProperty} {...energyLabel} />
                </span>
            </div>
        )
    }

    const params = renderParams()
    const priceSection = renderPriceSection()
    const energyLabel = renderEnergyLabelForProperty()

    const preview = renderPreview()
    const agentContact = renderContact()

    const shouldRenderColumn = params || priceSection || energyLabel
    const shouldRenderPreviewAndContact = preview || agentContact
    const shouldRenderRow = shouldRenderPreviewAndContact || shouldRenderColumn

    return (
        <div className={shouldRenderPreviewAndContact ? 'mt-10 lg:mt-16' : '-mb-8'}>
            {shouldRenderRow && (
                <Row className='text-center lg:text-left !justify-center lg:!justify-between'>
                    {shouldRenderPreviewAndContact && (
                        <>
                            {preview}
                            {agentContact}
                        </>
                    )}
                    {shouldRenderColumn && (
                        <Column cols={12} xl={paramsPriceColumnXl} className='order-3 lg:order-none'>
                            <div className='grid md:grid-cols-[auto_auto] lg:gap-10 gap-5 my-5 lg:my-10'>
                                {params}
                                {priceSection}
                                {energyLabel}
                            </div>
                        </Column>
                    )}
                </Row>
            )}
        </div>
    )
}

export default MicrositeHeader
