import { SeoConfig } from './seoConfig.types'

export const seoConfig: SeoConfig = {
    property: {
        'og-url': '{frontend-url}/nemovitost/{slug}'
    },
    project: {
        'og-url': '{frontend-url}/projekt/{slug}'
    }
}
