import { ComponentManagerComponentRow } from './ComponentManager.styles'
import { ComponentMortgageProps } from './ComponentManager.types'
import { ContactFormRef } from '../contact-form/ContactForm.types'
import { MortgageCalculatorContactFormType } from '../mortgage-calculator/MortgageCalculator.types'
import { PostMortgageCalculatorFormRequest } from '../../services/mortgage-calculator/MortgageCalculatorService.types'
import { Element as ScrollElement } from 'react-scroll'
import MortgageCalculator from '../mortgage-calculator/MortgageCalculator'
import React, { useRef } from 'react'
import useMortgageCalculatorPost from '../../hooks/api/mortgage-calculator/useMortgageCalculatorPost'

const ComponentMortgage = ({
    component: { title, price, currency, interestRate, mortgageAmount, repaymentPeriod, hash },
    ...props
}: ComponentMortgageProps) => {
    const mortgageCalculatorId = hash.split('_')[1]

    const mortgageCalculatorRef = useRef<ContactFormRef>(null)
    const { postMortgageCalculatorForm } = useMortgageCalculatorPost(mortgageCalculatorId)

    const submit = (data: MortgageCalculatorContactFormType) => {
        const requestData: PostMortgageCalculatorFormRequest = {
            price: data.propertyPrice,
            currency: data.currency,
            mortgageAmount: data.mortgageAmount,
            repaymentPeriod: data.paymentPeriod,
            name: data.fullName,
            phone: data.phone,
            email: data.email,
            checkbox: data.agreeProcessingPersonalData ? 1 : 0
        }
        postMortgageCalculatorForm(requestData).then(() => {
            return mortgageCalculatorRef?.current?.resetForm()
        })
    }

    return (
        <ScrollElement name={hash}>
            <ComponentManagerComponentRow {...props}>
                <MortgageCalculator
                    title={title}
                    ref={mortgageCalculatorRef}
                    initialPropertyPrice={price}
                    currency={currency}
                    initialMortgageAmount={mortgageAmount}
                    initialInterestRate={interestRate}
                    initialPaymentPeriod={repaymentPeriod}
                    submit={submit}
                />
            </ComponentManagerComponentRow>
        </ScrollElement>
    )
}

export default ComponentMortgage
