import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const BurgerIcon = ({ color = 'basic-black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect x='3' y='5' width='18' height='2' rx='1' fill={fillColor} />
            <rect x='3' y='11' width='18' height='2' rx='1' fill={fillColor} />
            <rect x='3' y='17' width='18' height='2' rx='1' fill={fillColor} />
        </RootIcon>
    )
}

export default BurgerIcon
