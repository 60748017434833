import { MicroSite } from '../../../services/Microsite.types'
import { getProjectMicroSiteProperty } from '../../../services/project-microsite/ProjectMicrositeService'
import { useAxios } from '../../useAxios'
import { useQuery } from '@tanstack/react-query'

export const PROJECT_PROPERTY_MICRO_SITE_QUERY_KEY = 'project-property-micro-site'

const useProjectPropertyMicroSite = (id: number, initialData?: MicroSite) => {
    const axiosInstance = useAxios()

    return useQuery(
        [PROJECT_PROPERTY_MICRO_SITE_QUERY_KEY, id],
        async () => {
            return getProjectMicroSiteProperty(axiosInstance, id)
        },
        { initialData }
    )
}

export default useProjectPropertyMicroSite
