import { SectionTitleProps } from './SectionTitle.types'
import React from 'react'
import Typography from '../typography/Typography'

const SectionTitle = ({ children, show = true, ...props }: SectionTitleProps) => {
    if (typeof children === 'string' && children?.length && show) {
        return (
            <Typography {...props} as='h2' level='h2' className='text-text-primary text-center lg:text-left'>
                {children}
            </Typography>
        )
    }
}

export default SectionTitle
