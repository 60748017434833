import { Element as ScrollElement, scroller } from 'react-scroll'
import React, { useEffect, useRef } from 'react'

const TableExpansionRow = ({ colSpan, row, rowScrollId, getExpansionPanel, scrollIntoView: enabledScrollIntoView }) => {
    const element = useRef<HTMLTableRowElement>(null)

    useEffect(() => {
        if (!element.current || !enabledScrollIntoView) {
            return
        }

        const scrollToElement = () => {
            scroller.scrollTo(rowScrollId, {
                duration: 1000,
                smooth: 'easeInOutQuart',
                delay: 0,
                offset: -300
            })
        }

        const handleScroll = () => {
            if (document.readyState === 'complete') {
                scrollToElement()
            } else {
                window.addEventListener('load', scrollToElement)
            }
        }

        const timeoutId = setTimeout(handleScroll, 0)

        return () => {
            clearTimeout(timeoutId)
            window.removeEventListener('load', scrollToElement)
        }
    }, [rowScrollId, enabledScrollIntoView])

    return (
        <tr className='expand-row' ref={element}>
            <td data-label='' colSpan={colSpan}>
                <ScrollElement name={rowScrollId}>{getExpansionPanel(row)}</ScrollElement>
            </td>
        </tr>
    )
}

export default TableExpansionRow
