import { useCallback, useRef } from 'react'

const rawSafeDocument: Document | {} = typeof document !== 'undefined' ? document : {}

const useScrollBlock = () => {
    const scrollBlocked = useRef<boolean>()
    const { documentElement, body } = rawSafeDocument as Document
    const html = documentElement as Document['documentElement']

    const blockScroll = useCallback(() => {
        if (!body || !body.style || scrollBlocked.current) {
            return
        }
        const scrollBarWidth = window.innerWidth - html.clientWidth
        const bodyPaddingRight = parseInt(window.getComputedStyle(body).getPropertyValue('padding-right')) || 0
        html.style.position = 'relative'
        html.style.overflow = 'hidden'
        body.style.position = 'relative'
        body.style.overflow = 'hidden'
        body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`
        scrollBlocked.current = true
    }, [])

    const allowScroll = useCallback(() => {
        if (!body || !body.style || !scrollBlocked.current) {
            return
        }
        html.style.position = ''
        html.style.overflow = ''
        body.style.position = ''
        body.style.overflow = ''
        body.style.paddingRight = ''
        scrollBlocked.current = false
    }, [])

    return { blockScroll, allowScroll }
}

export default useScrollBlock
