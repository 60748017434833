import { ComponentDescriptionProps } from './ComponentManager.types'
import { ComponentManagerComponentRow } from './ComponentManager.styles'
import { Element as ScrollElement } from 'react-scroll'
import Block from '../block/Block'
import Column from '../grid/column/Column'
import React from 'react'
import SectionTitle from '../section-title/SectionTitle'

const ComponentDescription = ({
    component: { title, text, hash },
    customCols,
    ...props
}: ComponentDescriptionProps) => {
    return (
        <ScrollElement name={hash}>
            <ComponentManagerComponentRow {...props}>
                <Column cols={12}>
                    <SectionTitle>{title}</SectionTitle>
                </Column>
                {text && (
                    <Column lg={customCols || 12} xl2={customCols || 12}>
                        <Block text={text} renderAsHtml />
                    </Column>
                )}
            </ComponentManagerComponentRow>
        </ScrollElement>
    )
}

export default ComponentDescription
