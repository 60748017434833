import {
    Image,
    ImageBlockDotList,
    ImageBlockDotListContainer,
    ImageBlockNavigation,
    ImageBlockRoot,
    ImageContainer,
    ImageContent
} from './ImageBlock.styles'
import { ImageBlockImage, ImageBlockProps, ImageBlockViewerResolution } from './ImageBlock.types'
import { RenderPaginatedNavigation } from '../render-paginated-navigation/RenderPaginatedNavigation'
import { extractImageUrl, getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import ImageViewer from '../image-viewer/ImageViewer'
import React, { useMemo, useState } from 'react'

const viewerResolutions: ImageBlockViewerResolution[] = [
    {
        resolution: 'sd',
        width: 640,
        height: 480
    },
    {
        resolution: 'hd',
        width: 1280,
        height: 720
    },
    {
        resolution: 'full-hd',
        width: 1920,
        height: 1080
    },
    {
        resolution: '4k',
        width: 3840,
        height: 2160
    }
]

const ImageBlock = ({ images, viewerResolution: propViewerResolution = 'full-hd', customLink }: ImageBlockProps) => {
    const theme = useTheme()
    const [imageIndex, setImageIndex] = useState(0)
    const [showImageViewer, setShowImageViewer] = useState<boolean>(false)

    const fullHdResolutionImages = useMemo<ImageBlockImage[]>(() => {
        return images.map(image => {
            return {
                ...image,
                url: customLink
                    ? image.url
                    : extractImageUrl(
                          image.url,
                          viewerResolutions.find(({ resolution }) => {
                              return resolution === propViewerResolution
                          })
                      )
            }
        })
    }, [customLink, images, propViewerResolution])

    const image = fullHdResolutionImages[imageIndex]

    const primaryColor = getColorFromString(theme, 'text-blue')
    const whiteColor = getColorFromString(theme, 'white')

    const lengthOfImages = fullHdResolutionImages.length - 1
    const canNext = imageIndex !== lengthOfImages
    const canPrevious = imageIndex !== 0

    const nextImage = () => {
        return setImageIndex(prev => {
            return prev >= lengthOfImages ? 0 : prev + 1
        })
    }
    const previousImage = () => {
        return setImageIndex(prev => {
            return prev === 0 ? lengthOfImages : prev - 1
        })
    }

    const renderDots = () => {
        return (
            fullHdResolutionImages.length > 1 && (
                <ImageBlockDotListContainer>
                    <ImageBlockDotList>
                        {fullHdResolutionImages.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    onClick={() => {
                                        return setImageIndex(index)
                                    }}
                                >
                                    <svg
                                        width='10'
                                        height='10'
                                        viewBox='0 0 10 10'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <circle
                                            cx='5'
                                            cy='5'
                                            r='5'
                                            fill={imageIndex === index ? primaryColor : whiteColor}
                                        />
                                    </svg>
                                </li>
                            )
                        })}
                    </ImageBlockDotList>
                </ImageBlockDotListContainer>
            )
        )
    }

    return (
        <>
            {showImageViewer && (
                <ImageViewer
                    currentIndex={imageIndex}
                    onChangeCurrentIndex={setImageIndex}
                    src={fullHdResolutionImages.map(item => {
                        return item.url
                    })}
                    onClose={() => {
                        return setShowImageViewer(false)
                    }}
                />
            )}
            <ImageBlockRoot>
                {fullHdResolutionImages.length > 1 && (
                    <ImageBlockNavigation>
                        <RenderPaginatedNavigation
                            leftArrowClick={previousImage}
                            leftArrowDisabled={!canPrevious}
                            rightArrowClick={nextImage}
                            rightArrowDisabled={!canNext}
                        />
                    </ImageBlockNavigation>
                )}
                <ImageContainer>
                    <ImageContent>
                        <Image
                            src={image?.url}
                            alt={image?.alt}
                            onClick={() => {
                                return setShowImageViewer(true)
                            }}
                        />
                    </ImageContent>
                </ImageContainer>
                {renderDots()}
            </ImageBlockRoot>
        </>
    )
}

export default ImageBlock
