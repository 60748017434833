import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const LayersIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M20.3554 9.30402L12.469 13.4917C12.1757 13.6474 11.8243 13.6474 11.531 13.4917L3.64464 9.30402C2.94037 8.93005 2.93565 7.92259 3.63638 7.54204L11.5228 3.25917C11.8204 3.09754 12.1796 3.09754 12.4772 3.25917L20.3636 7.54204C21.0644 7.92259 21.0596 8.93005 20.3554 9.30402Z'
                fill={fillColor}
            />
            <path
                d='M20.3554 13.3608L12.469 17.5484C12.1757 17.7042 11.8243 17.7042 11.531 17.5484L3.64464 13.3608C2.94037 12.9868 2.93565 11.9793 3.63639 11.5988L4.67314 11.0357C4.96955 10.8748 5.32713 10.8741 5.62417 11.0339L11.5262 14.2092C11.822 14.3684 12.178 14.3684 12.4738 14.2092L18.3758 11.0339C18.6729 10.8741 19.0305 10.8748 19.3269 11.0357L20.3636 11.5988C21.0644 11.9793 21.0596 12.9868 20.3554 13.3608Z'
                fill={fillColor}
            />
            <path
                d='M20.3554 17.4175L12.469 21.6052C12.1757 21.7609 11.8243 21.7609 11.531 21.6052L3.64464 17.4175C2.94037 17.0435 2.93565 16.0361 3.63639 15.6555L4.67314 15.0925C4.96955 14.9315 5.32713 14.9308 5.62417 15.0906L11.5262 18.266C11.822 18.4251 12.178 18.4251 12.4738 18.266L18.3758 15.0906C18.6729 14.9308 19.0305 14.9315 19.3269 15.0925L20.3636 15.6555C21.0644 16.0361 21.0596 17.0435 20.3554 17.4175Z'
                fill={fillColor}
            />
        </RootIcon>
    )
}

export default LayersIcon
