import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const HalfStarIcon = ({ color = 'black', size = { height: 16, width: 16 }, ...props }: IconProps) => {
    const theme = useTheme()
    const themeColor = getColorFromString(theme, color)
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 17 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d='M7 4L8.5 1.5V11.5L4 14L5 9.5L4 8L2 6L6 5L7 4Z' fill={themeColor} />
            <path
                d='M8.5 1.07666L10.5553 4.99613L10.675 5.22439L10.9303 5.25932L15.4616 5.87902L12.204 8.86763L12.0006 9.0542L12.0501 9.32571L12.8259 13.5826L8.72174 11.5519L8.5 11.4421L8.27826 11.5519L4.17417 13.5826L4.94991 9.32571L4.99938 9.0542L4.79602 8.86763L1.5384 5.87902L6.06966 5.25932L6.32502 5.22439L6.44472 4.99613L8.5 1.07666Z'
                stroke={themeColor}
            />
        </RootIcon>
    )
}

export default HalfStarIcon
