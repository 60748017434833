import { TableRootProps } from './Table.types'
import tw, { css, styled } from 'twin.macro'

export const TableRoot = styled.div<TableRootProps>(({ isSmallTable, minHeight }) => [
    tw`bg-basic-white text-grey-600 overflow-hidden text-center`,
    css`
        border-radius: 1.563rem;

        table {
            border-collapse: collapse;
            margin: 0;
            padding: 0;
            width: 100%;
            table-layout: auto;
        }

        table thead {
            ${tw`bg-secondaryBlue-100 leading-[1.188rem] font-semibold`}
        }

        table th,
        table td {
            padding: 1rem 0.5rem;
        }

        table tr {
            ${minHeight ? `height: ${minHeight};` : ''}
        }

        tbody tr:not(:last-child) {
            ${tw`border-b border-grey-300`}
        }

        .expand-row {
            ${tw`bg-background-secondary`}
        }

        @media screen and (max-width: ${isSmallTable ? '800px' : '1200px'}) {
            table thead {
                border: none;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            table th,
            table td {
                padding: 0.5rem 1rem;
            }

            table tr {
                display: block;

                &:not(:last-child) {
                    ${tw`border-b border-grey-300`};
                }
            }

            table td {
                display: block;
                text-align: right;
            }

            table td::before {
                content: attr(data-label);
                float: left;
                font-weight: bold;
            }

            table td:last-child {
                border-bottom: 0;
            }
        }
    `
])
