import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const TerraceIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2.29053 22.452C2.29053 22.0378 2.62631 21.702 3.04053 21.702H22.2742C22.6884 21.702 23.0242 22.0378 23.0242 22.452C23.0242 22.8662 22.6884 23.202 22.2742 23.202H3.04053C2.62631 23.202 2.29053 22.8662 2.29053 22.452Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.4648 6.2381C12.8791 6.2381 13.2148 6.57388 13.2148 6.9881L13.2148 22.452C13.2148 22.8662 12.8791 23.202 12.4648 23.202C12.0506 23.202 11.7148 22.8662 11.7148 22.452L11.7148 6.9881C11.7148 6.57388 12.0506 6.2381 12.4648 6.2381Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.9146 18.9964C13.9146 18.5822 14.2503 18.2464 14.6646 18.2464L18.9239 18.2464C19.3381 18.2464 19.6739 18.5822 19.6739 18.9964C19.6739 19.4107 19.3381 19.7464 18.9239 19.7464L14.6646 19.7464C14.2503 19.7464 13.9146 19.4107 13.9146 18.9964Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.2925 18.961C15.7067 18.961 16.0425 19.2968 16.0425 19.711L16.0425 22.452C16.0425 22.8662 15.7067 23.202 15.2925 23.202C14.8783 23.202 14.5425 22.8662 14.5425 22.452L14.5425 19.711C14.5425 19.2968 14.8783 18.961 15.2925 18.961Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.7495 19C19.1637 19 19.4995 19.3358 19.4995 19.75L19.4995 22.491C19.4995 22.9052 19.1637 23.241 18.7495 23.241C18.3353 23.241 17.9995 22.9052 17.9995 22.491L17.9995 19.75C17.9995 19.3358 18.3353 19 18.7495 19Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.8912 19.686C18.4826 19.6179 18.2066 19.2314 18.2747 18.8229L18.8763 15.2133C18.9444 14.8047 19.3308 14.5287 19.7394 14.5968C20.1479 14.6649 20.4239 15.0513 20.3559 15.4599L19.7543 19.0695C19.6862 19.478 19.2997 19.754 18.8912 19.686Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10.1123 18.9964C10.1123 18.5822 9.77652 18.2464 9.3623 18.2464L5.10296 18.2464C4.68874 18.2464 4.35296 18.5822 4.35296 18.9964C4.35296 19.4107 4.68874 19.7464 5.10296 19.7464L9.3623 19.7464C9.77652 19.7464 10.1123 19.4107 10.1123 18.9964Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.73438 18.961C8.32016 18.961 7.98438 19.2968 7.98438 19.711L7.98438 22.452C7.98438 22.8662 8.32016 23.202 8.73438 23.202C9.14859 23.202 9.48438 22.8662 9.48438 22.452L9.48438 19.711C9.48438 19.2968 9.14859 18.961 8.73438 18.961Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.24951 19C4.8353 19 4.49951 19.3358 4.49951 19.75L4.49951 22.491C4.49951 22.9052 4.8353 23.241 5.24951 23.241C5.66373 23.241 5.99951 22.9052 5.99951 22.491L5.99951 19.75C5.99951 19.3358 5.66373 19 5.24951 19Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.1352 19.686C5.54378 19.6179 5.8198 19.2314 5.7517 18.8229L5.1501 15.2133C5.08201 14.8047 4.69559 14.5287 4.28701 14.5968C3.87843 14.6649 3.60242 15.0513 3.67051 15.4599L4.27211 19.0695C4.34021 19.478 4.72663 19.754 5.1352 19.686Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.8877 14.9125C8.8877 14.4983 9.22348 14.1625 9.6377 14.1625L14.7348 14.1625C15.149 14.1625 15.4848 14.4983 15.4848 14.9125C15.4848 15.3267 15.149 15.6625 14.7348 15.6625L9.6377 15.6625C9.22348 15.6625 8.8877 15.3267 8.8877 14.9125Z'
                fill={fillColor}
            />
            <path
                d='M21.5658 9.58923H2.43393C1.43348 9.58923 1.05301 8.28268 1.89705 7.74556L11.463 1.65815C11.7906 1.4497 12.2092 1.4497 12.5367 1.65815L22.1027 7.74556C22.9467 8.28268 22.5663 9.58923 21.5658 9.58923Z'
                fill={fillColor}
            />
        </RootIcon>
    )
}

export default TerraceIcon
