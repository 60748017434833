import { FormProps } from './Form.types'
import React, { ForwardedRef, forwardRef } from 'react'

const Form = ({ children, ...props }: FormProps, ref: ForwardedRef<HTMLFormElement>) => {
    return (
        <form {...props} ref={ref}>
            {children}
        </form>
    )
}

export default forwardRef(Form)
