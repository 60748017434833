import { InteractiveModelTableProps } from '../InteractiveModel.types'
import ArrowLeftIcon from '../../icon/ArrowLeftIcon'
import React, { forwardRef } from 'react'
import Table from '../../table/Table'

const InteractiveModelTable = (
    { property, headers, singleImage, backToBuildingImage, onRowClick }: InteractiveModelTableProps,
    ref: React.ForwardedRef<HTMLTableElement>
) => {
    const defaultHeaders = [
        {
            title: 'info',
            value: () =>
                'Pro více informací o nemovitostech prozkoumejte dostupné jednotky v interaktivním modelu najetím kurzoru.'
        }
    ]

    const finalHeaders = headers ? [...headers] : defaultHeaders

    if (!singleImage) {
        finalHeaders.unshift({
            title: !property ? 'Zpět na vizualizaci projektu' : 'Zpět',
            value: () => (
                <button
                    onClick={event => {
                        event.stopPropagation()
                        backToBuildingImage()
                    }}
                    className='relative top-1.5 ml-2'
                >
                    <ArrowLeftIcon size={32} color='basic-black' />
                </button>
            )
        })
    }

    const handleRowClick = () => {
        if (onRowClick && property) {
            onRowClick(property)
        }
    }

    return (
        <div>
            <Table
                ref={ref}
                headers={finalHeaders}
                data={[property]}
                keyExtractor={index => index.toString()}
                expandedRowId={0}
                initialExpandedRowValue={[]}
                isSmallTable
                minHeight='5.5rem'
                onRowClick={handleRowClick}
            />
        </div>
    )
}

export default forwardRef(InteractiveModelTable)
