import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const SquareMetersIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7ZM14.6464 4.64645C14.8417 4.45118 15.1583 4.45118 15.3536 4.64645L17.3536 6.64645L17.7071 7L17.3536 7.35355L15.3536 9.35355C15.1583 9.54882 14.8417 9.54882 14.6464 9.35355C14.4512 9.15829 14.4512 8.84171 14.6464 8.64645L15.7929 7.5H8C7.72386 7.5 7.5 7.27614 7.5 7C7.5 6.72386 7.72386 6.5 8 6.5H15.7929L14.6464 5.35355C14.4512 5.15829 14.4512 4.84171 14.6464 4.64645ZM19.3536 16.3536C19.5488 16.1583 19.5488 15.8417 19.3536 15.6464C19.1583 15.4512 18.8417 15.4512 18.6464 15.6464L17.5 16.7929V11C17.5 10.7239 17.2761 10.5 17 10.5C16.7239 10.5 16.5 10.7239 16.5 11V16.7929L15.3536 15.6464C15.1583 15.4512 14.8417 15.4512 14.6464 15.6464C14.4512 15.8417 14.4512 16.1583 14.6464 16.3536L16.6464 18.3536L17 18.7071L17.3536 18.3536L19.3536 16.3536Z'
                fill={getColorFromString(theme, color)}
            />
        </RootIcon>
    )
}

export default SquareMetersIcon
