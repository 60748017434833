import * as yup from 'yup'
import { ContactFormData, ContactFormProps, ContactFormRef } from './ContactForm.types'
import { FieldPath, useForm } from 'react-hook-form'
import { RoundedFormCard } from '../form/Form.styles'
import { buildClassesWithDefault, extractImageUrl } from '../../utils/Helper'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '../button/Button'
import CardContact from '../card/contact/CardContact'
import Checkbox from '../checkbox/Checkbox'
import Column from '../grid/column/Column'
import ContactSelectIcon from '../../assets/img/contact-select-icon.svg'
import HiddenInput from '../hidden-input/HiddenInput'
import PersonalDataModal from '../personal-data-modal/PersonalDataModal'
import React, { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react'
import Row from '../grid/row/Row'
import TextField from '../text-field/TextField'
import Textarea from '../textarea/Textarea'
import useWindowSize from '../../hooks/useWindowSize'
import validator from 'validator'

const CONTACT_CARD_WIDTH = '34.375rem'
const CONTACT_IMAGE_SIZES = { width: 130, height: 168 }

const ContactForm = (
    {
        firstContact,
        secondContact,
        text,
        className,
        submit: propSubmit,
        customImage,
        id,
        bannerEmail,
        ...props
    }: ContactFormProps,
    ref: ForwardedRef<ContactFormRef>
) => {
    const contactCardStyle = { minWidth: CONTACT_CARD_WIDTH }
    const { width: windowWidth } = useWindowSize()
    const [selectedContact, setSelectedContact] = useState(0)
    const isBothContactsExists = firstContact && secondContact

    const {
        handleSubmit,
        register,
        formState: { errors },
        reset
    } = useForm<ContactFormData>({
        resolver: yupResolver(
            yup.object({
                email: yup
                    .string()
                    .test('is-email', 'E-mail není platný.', value => {
                        return validator.isEmail(value || '')
                    })
                    .required('E-mail je vyžadován.'),
                agreeProcessingPersonalData: yup
                    .boolean()
                    .required('Musíte souhlasit s podmínkami.')
                    .oneOf([true], 'Musíte souhlasit s podmínkami.')
            })
        ),
        reValidateMode: 'onChange',
        defaultValues: {
            agreeProcessingPersonalData: true
        }
    })

    useImperativeHandle(
        ref,
        () => {
            return {
                resetForm: () => {
                    return reset()
                }
            }
        },
        [reset]
    )

    const getContactEmailAddress = () => {
        if (bannerEmail?.length) {
            return bannerEmail
        }
        if (selectedContact === 0) {
            return firstContact?.emailAddress
        }
        return secondContact?.emailAddress
    }

    const submit = (data: ContactFormData) => {
        return propSubmit?.({
            ...data,
            brokerEmail: getContactEmailAddress()
        })
    }

    const getFieldError = (path: FieldPath<ContactFormData>) => {
        return errors[path]?.message as string | string[]
    }

    const renderContacts = () => {
        return (
            isBothContactsExists && (
                <Row {...props} wrap={false} className={buildClassesWithDefault('overflow-auto', className)}>
                    <Column cols={12} lg={6} style={contactCardStyle}>
                        <CardContact
                            variant={selectedContact === 0 ? 'normal' : 'outlined'}
                            color={selectedContact === 0 ? 'secondaryBlue-10' : 'secondaryBlue-10'}
                            callIconColor={selectedContact === 0 ? 'text-blue' : 'basic-primary'}
                            emailIconColor={selectedContact === 0 ? 'text-blue' : 'basic-primary'}
                            cardContactTextColor={selectedContact === 0 ? 'text-text-blue' : 'text-basic-primary'}
                            sellerPositionColor={selectedContact === 0 ? 'text-grey-600' : 'text-text-secondary'}
                            image={
                                customImage
                                    ? firstContact.image
                                    : extractImageUrl(firstContact.image, CONTACT_IMAGE_SIZES)
                            }
                            name={firstContact.name}
                            role={firstContact.role}
                            phoneNumber={firstContact.phoneNumber}
                            emailAddress={firstContact.emailAddress}
                            starCount={firstContact.stars}
                            certificateImage={firstContact.certificate?.url}
                            certificateImageAlt={firstContact.certificate?.alt}
                            certificateImageTitle={firstContact.certificate?.title}
                            detailUrl={firstContact.detailUrl}
                            className='cursor-pointer'
                            onClick={() => {
                                return setSelectedContact(0)
                            }}
                        />
                    </Column>
                    <Column cols={12} lg={6} style={contactCardStyle}>
                        <CardContact
                            variant={selectedContact === 1 ? 'normal' : 'outlined'}
                            color={selectedContact === 1 ? 'secondaryBlue-10' : 'secondaryBlue-10'}
                            callIconColor={selectedContact === 1 ? 'text-blue' : 'basic-primary'}
                            emailIconColor={selectedContact === 1 ? 'text-blue' : 'basic-primary'}
                            cardContactTextColor={selectedContact === 1 ? 'text-text-blue' : 'text-basic-primary'}
                            sellerPositionColor={selectedContact === 1 ? 'text-grey-600' : 'text-text-secondary'}
                            image={
                                customImage
                                    ? secondContact.image
                                    : extractImageUrl(secondContact.image, CONTACT_IMAGE_SIZES)
                            }
                            name={secondContact.name}
                            role={secondContact.role}
                            phoneNumber={secondContact.phoneNumber}
                            emailAddress={secondContact.emailAddress}
                            starCount={secondContact.stars}
                            certificateImage={secondContact.certificate?.url}
                            certificateImageAlt={secondContact.certificate?.alt}
                            certificateImageTitle={secondContact.certificate?.title}
                            detailUrl={secondContact.detailUrl}
                            className='cursor-pointer'
                            onClick={() => {
                                return setSelectedContact(1)
                            }}
                        />
                    </Column>
                </Row>
            )
        )
    }

    const renderSelectedContactIcon = () => {
        return (
            isBothContactsExists && (
                <Row className='relative -bottom-3 !hidden lg:!flex'>
                    {selectedContact === 1 && <Column cols={6} />}
                    <Column cols={6} className='flex justify-center'>
                        <ContactSelectIcon className='fill-secondaryBlue-marker' />
                    </Column>
                </Row>
            )
        )
    }

    const renderSingleContact = () => {
        return (
            firstContact &&
            !secondContact && (
                <Column cols={12} lg={4} className='flex justify-center lg:justify-start'>
                    <CardContact
                        removePadding
                        centered={windowWidth <= 700}
                        orientation={windowWidth <= 1020 && windowWidth >= 700 ? 'horizontal' : 'vertical'}
                        color='secondaryBlue-10'
                        callIconColor='text-blue'
                        emailIconColor='text-blue'
                        cardContactTextColor='text-text-blue'
                        sellerPositionColor='text-grey-500'
                        image={
                            customImage ? firstContact.image : extractImageUrl(firstContact.image, CONTACT_IMAGE_SIZES)
                        }
                        name={firstContact.name}
                        role={firstContact.role}
                        phoneNumber={firstContact.phoneNumber}
                        emailAddress={firstContact.emailAddress}
                        starCount={firstContact.stars}
                        certificateImage={firstContact.certificate?.url}
                        certificateImageAlt={firstContact.certificate?.alt}
                        certificateImageTitle={firstContact.certificate?.title}
                        detailUrl={firstContact.detailUrl}
                    />
                </Column>
            )
        )
    }

    const renderAgreeWithProcessingPersonalDataLabel = () => {
        return (
            <span>
                Souhlasím se&nbsp;
                <PersonalDataModal>
                    {({ openModal }) => {
                        return (
                            <button
                                type='button'
                                className='underline hover:no-underline text-secondaryRed-400'
                                onClick={openModal}
                            >
                                zpracováním osobních údajů
                            </button>
                        )
                    }}
                </PersonalDataModal>
            </span>
        )
    }

    const getContactId = () => {
        if (selectedContact === 0) {
            if (!firstContact) {
                return id
            }
            return firstContact.id
        }
        return secondContact.id
    }

    return (
        <>
            {renderContacts()}
            {renderSelectedContactIcon()}
            <Row className='!mt-1 lg:!mt-0'>
                <Column cols={12}>
                    <RoundedFormCard onSubmit={handleSubmit(submit)} id={getContactId()} $color='secondaryBlue-10'>
                        <HiddenInput label='brokerEmail' name='brokerEmail' value={getContactEmailAddress()} />
                        <Row justify='center' className='lg:!justify-between'>
                            {renderSingleContact()}
                            <Column cols={12} lg={firstContact && !secondContact ? 8 : text ? 6 : 12}>
                                <Row>
                                    <Column cols={12} lg={6}>
                                        <TextField
                                            label='Jméno a příjmení (nepovinné)'
                                            className='serializableActivator'
                                            invalidMessage={getFieldError('fullName')}
                                            {...register('fullName')}
                                        />
                                    </Column>

                                    <Column cols={12} lg={6}>
                                        <TextField
                                            required
                                            label='Email'
                                            className='serializableActivator'
                                            invalidMessage={getFieldError('email')}
                                            {...register('email')}
                                        />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column cols={12}>
                                        <TextField
                                            label='Telefon (nepovinné)'
                                            className='serializableActivator'
                                            invalidMessage={getFieldError('phone')}
                                            {...register('phone')}
                                        />
                                    </Column>
                                    <Column cols={12}>
                                        <Textarea
                                            rows={9}
                                            label='Zpráva (nepovinné)'
                                            className='serializableActivator'
                                            invalidMessage={getFieldError('message')}
                                            {...register('message')}
                                        />
                                    </Column>
                                    <Column cols={12}>
                                        <Checkbox
                                            label={renderAgreeWithProcessingPersonalDataLabel()}
                                            required
                                            invalidMessage={getFieldError('agreeProcessingPersonalData')}
                                            {...register('agreeProcessingPersonalData')}
                                        />
                                    </Column>
                                    <Column cols={12} className='flex justify-center lg:justify-start'>
                                        <Button color='red' type='submit'>
                                            Odeslat
                                        </Button>
                                    </Column>
                                </Row>
                            </Column>
                        </Row>
                    </RoundedFormCard>
                </Column>
            </Row>
        </>
    )
}

export default forwardRef(ContactForm)
