import { LayoutTitleStatusImageTitleContainer, LayoutTitleStatusRoot } from './Layout.styles'
import { LayoutTitleStatusProps } from './LayoutTitleStatus.types'
import { useRouter } from 'next/router'
import Chip from '../components/chip/Chip'
import React, { useCallback } from 'react'
import Typography from '../components/typography/Typography'

const LayoutTitleStatus = ({ titleImg, title, statusColor, status }: LayoutTitleStatusProps) => {
    const router = useRouter()

    const redirectToBaseURL = useCallback(() => {
        if (typeof window === 'undefined') {
            return
        }

        let targetUrl
        const splitUrl = window.location.href.split('?')
        if (splitUrl.length) {
            targetUrl = splitUrl[0]
        } else {
            targetUrl = window.location.href
        }
        if (targetUrl) {
            router.push(targetUrl).then(router.reload)
        }
    }, [router])

    return (
        <LayoutTitleStatusRoot>
            <LayoutTitleStatusImageTitleContainer>
                {titleImg && (
                    <img
                        onClick={redirectToBaseURL}
                        className='max-h-[6.25rem] cursor-pointer'
                        src={titleImg}
                        alt={title}
                    />
                )}
                <Typography as='h1' level='h1' className='text-text-primary'>
                    {title}
                </Typography>
            </LayoutTitleStatusImageTitleContainer>
            {status && (
                <span>
                    <Chip color={statusColor}>{status}</Chip>
                </span>
            )}
        </LayoutTitleStatusRoot>
    )
}

export default LayoutTitleStatus
