import { MicroSite } from '../../services/Microsite.types'
import Chip from '../chip/Chip'
import React from 'react'

export const StandardHeadersChip = ({ item }: { item: MicroSite }) => {
    switch (item.state) {
        case 0:
            return <Chip color='success-400'>Dostupné</Chip>
        case 1:
            return <Chip color='warning-400'>Rezervováno</Chip>
        case 2:
            return <Chip color='error-400'>Prodáno</Chip>
        case 3:
            return <Chip color='grey-400'>Nedostupné</Chip>
        case 4:
            return <Chip color='secondaryBlue-200'>V přípravě</Chip>
        default:
            return null
    }
}
