import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const NationalHouseIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M23.3023 20.5903H22.3721V9.99901C22.3721 9.41635 22.1116 8.87127 21.6558 8.51416L20.5116 7.61197L20.493 5.30951C20.493 4.79263 20.0744 4.37913 19.5628 4.37913H16.3907L15.1442 3.40176C14.4744 2.86608 13.5256 2.86608 12.8558 3.40176L6.34419 8.51416C5.88837 8.87127 5.62791 9.41635 5.62791 9.98961L5.5814 20.5903H4.69767C4.31628 20.5903 4 20.9099 4 21.2952C4 21.6805 4.31628 22 4.69767 22H23.3023C23.6837 22 24 21.6805 24 21.2952C24 20.9099 23.6837 20.5903 23.3023 20.5903ZM16.3256 20.5903H11.6744V18.0059C11.6744 17.2259 12.2977 16.5963 13.0698 16.5963H14.9302C15.7023 16.5963 16.3256 17.2259 16.3256 18.0059V20.5903ZM19.1163 12.6022C19.1163 13.1191 18.6977 13.542 18.186 13.542H16.3256C15.814 13.542 15.3953 13.1191 15.3953 12.6022V11.1925C15.3953 10.6757 15.814 10.2528 16.3256 10.2528H18.186C18.6977 10.2528 19.1163 10.6757 19.1163 11.1925V12.6022Z'
                fill={fillColor}
            />
            <path
                d='M13.9 7H2V15H13.9L10.5 11L13.9 7Z'
                fill='white'
                stroke={fillColor}
                strokeWidth='1.5'
                strokeLinecap='round'
            />
            <path d='M2 11L2 21' stroke={fillColor} strokeWidth='1.5' strokeLinecap='round' />
        </RootIcon>
    )
}

export default NationalHouseIcon
