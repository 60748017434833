import { RowProps } from './Row.types'
import { RowRoot } from './Row.styles'
import React, { ElementType, ForwardedRef, forwardRef } from 'react'

const Row = <Element extends ElementType = 'div'>(
    {
        as,
        children,
        justify = 'start',
        align = 'start',
        direction = 'row',
        autoWidth = true,
        wrap = true,
        marginY = true,
        marginX = true,
        ...props
    }: RowProps<Element>,
    ref: ForwardedRef<Element>
) => {
    return (
        <RowRoot
            as={as || 'div'}
            ref={ref as unknown as ForwardedRef<HTMLDivElement>}
            $direction={direction}
            $justify={justify}
            $align={align}
            $autoWidth={autoWidth}
            $wrap={wrap}
            $marginX={marginX}
            $marginY={marginY}
            {...props}
        >
            {children}
        </RowRoot>
    )
}

export default forwardRef(Row)
