import { HiddenInputProps } from './HiddenInput.types'
import React, { useId } from 'react'

const HiddenInput = ({ label, name, propId, value }: HiddenInputProps) => {
    const id = useId()

    if (!name || !value) {
        return
    }

    return (
        <>
            <label htmlFor={id || propId} hidden>
                {label || name}
            </label>
            <input hidden readOnly id={id} type='text' name={name} value={value} />
        </>
    )
}

export default HiddenInput
