import { ScrollTopButtonProps } from './ScrollTopButton.types'
import { ScrollTopButtonRoot } from './ScrollTopButton.styles'
import { animateScroll as scroll } from 'react-scroll'
import ChevronUpIcon from '../icon/ChevronUpIcon'
import React from 'react'

const ScrollTopButton = ({ ...props }: ScrollTopButtonProps) => {
    const scrollTop = () => {
        return scroll.scrollToTop()
    }

    return (
        <ScrollTopButtonRoot {...props} onClick={scrollTop}>
            <ChevronUpIcon size={12} color='white' />
        </ScrollTopButtonRoot>
    )
}

export default ScrollTopButton
