import { MortgageCalculatorInputLabel } from './MortgageCalculator.styles'
import { MortgageCalculatorSlidersProps } from './MortgageCalculator.types'
import Column from '../grid/column/Column'
import MortgageCalculatorInput from './MortgageCalculatorInput'
import React from 'react'
import Row from '../grid/row/Row'
import Slider from '../slider/Slider'

const MortgageCalculatorSliders = ({
    inputCurrency,
    paymentPeriodUnit = 'roků',
    changeMortgagePrice,
    mortgagePriceValue,
    changePaymentPeriod,
    paymentPeriodValue,
    minMortgagePrice = 0,
    maxMortgagePrice,
    minPaymentPeriod = 1,
    maxPaymentPeriod,
    ...props
}: MortgageCalculatorSlidersProps) => {
    const onChangeMortgagePriceSlider = (value: number | number[]) => {
        if (!Array.isArray(value)) {
            changeMortgagePrice?.(value as number)
        }
    }

    const onChangePaymentPeriodSlider = (value: number | number[]) => {
        if (!Array.isArray(value)) {
            changePaymentPeriod?.(value as number)
        }
    }

    const renderMaxPrice = () => {
        return (
            <Column cols={12} xl={5} className='space-y-5'>
                <Row className='space-y-4'>
                    <Column cols={12}>
                        <MortgageCalculatorInputLabel>Výše hypotéky</MortgageCalculatorInputLabel>
                    </Column>
                    <Column cols={12}>
                        <Row align='center'>
                            <Column cols={12} md={7} lg={7}>
                                <Slider
                                    className='mb-3'
                                    value={mortgagePriceValue}
                                    onChange={onChangeMortgagePriceSlider}
                                    min={minMortgagePrice}
                                    max={maxMortgagePrice}
                                />
                            </Column>
                            <Column cols={12} md={3}>
                                <MortgageCalculatorInput
                                    className='md:w-36'
                                    unit={inputCurrency}
                                    change={changeMortgagePrice}
                                    value={mortgagePriceValue}
                                    min={minMortgagePrice}
                                    max={maxMortgagePrice}
                                    hiddenLabel='Výše hypotéky'
                                    hiddenName='mortgagePrice'
                                    hiddenValue={`${mortgagePriceValue} ${inputCurrency}`}
                                />
                            </Column>
                        </Row>
                    </Column>
                </Row>
            </Column>
        )
    }

    const renderDuration = () => {
        return (
            <Column cols={12} xl={5} className='space-y-5'>
                <Row className='space-y-4'>
                    <Column cols={12}>
                        <MortgageCalculatorInputLabel>Doba splácení</MortgageCalculatorInputLabel>
                    </Column>
                    <Column cols={12}>
                        <Row align='center'>
                            <Column cols={12} md={7} lg={7}>
                                <Slider
                                    className='mb-3'
                                    onChange={onChangePaymentPeriodSlider}
                                    value={paymentPeriodValue}
                                    min={minPaymentPeriod}
                                    max={maxPaymentPeriod}
                                />
                            </Column>
                            <Column cols={12} md={3}>
                                <MortgageCalculatorInput
                                    className='md:w-36'
                                    unit={paymentPeriodUnit}
                                    change={changePaymentPeriod}
                                    value={paymentPeriodValue}
                                    min={minPaymentPeriod}
                                    max={maxPaymentPeriod}
                                    hiddenLabel='Doba splácení'
                                    hiddenName='repaymentPeriod'
                                    hiddenValue={`${paymentPeriodValue} ${paymentPeriodUnit}`}
                                />
                            </Column>
                        </Row>
                    </Column>
                </Row>
            </Column>
        )
    }

    return (
        <Column cols={12} {...props}>
            <Row className='justify-between space-y-3 md:space-y-0'>
                {renderMaxPrice()}
                <Column cols={1}>
                    <div className='w-2' />
                </Column>
                {renderDuration()}
            </Row>
        </Column>
    )
}

export default MortgageCalculatorSliders
