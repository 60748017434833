import { PersonalDataModalBodyRoot } from './PersonalDataModal.styles'
import { PersonalDataModalProps } from './PersonalDataModal.types'
import Modal from '../modal/Modal'
import React from 'react'
import useMicrositeContactFormTerms from '../../hooks/api/shared-microsite/useMicrositeContactFormTerms'

const PersonalDataModal = ({ children, ...props }: PersonalDataModalProps) => {
    const { data } = useMicrositeContactFormTerms()

    const renderModalTitle = () => {
        return (
            <h6
                className='text-body-large text-basic-primary'
                dangerouslySetInnerHTML={{ __html: data?.title || '' }}
            />
        )
    }

    const renderModalBody = () => {
        return (
            <PersonalDataModalBodyRoot
                className='text-body-normal'
                dangerouslySetInnerHTML={{ __html: data?.content || '' }}
            />
        )
    }

    return (
        <Modal {...props} title={renderModalTitle()} body={renderModalBody()}>
            {children}
        </Modal>
    )
}

export default PersonalDataModal
