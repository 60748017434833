import { PriceContainerRoot, PriceDiscountedRoot, PriceRoot } from './ComponentPropertiesTable.styles'
import { StandardHeadersChip } from '../../StandardHeadersChip/StandardHeadersChip'
import { getCurrencySymbol, getNormalizedPrice } from '../../../utils/Helper'
import ArrowDownIcon from '../../icon/ArrowDownIcon'
import ArrowUpIcon from '../../icon/ArrowUpIcon'

export const StandardHeaders = (
    displayedCols: string | string[],
    isTableForSmallDevice: boolean,
    showDetail: boolean
) => {
    const formatNullOrEmptyCellValue = (value?: string) => {
        return !value || value.length === 0 ? '-' : value
    }

    return [
        {
            title: 'Název',
            visible: displayedCols.includes('name'),
            value: item => (
                <span className='lg:block lgw-28 lg:whitespace-normal'>{formatNullOrEmptyCellValue(item.name)}</span>
            )
        },
        {
            title: 'Typ',
            visible: !isTableForSmallDevice && displayedCols.includes('unitType'),
            value: item => formatNullOrEmptyCellValue(item.unitType)
        },
        {
            title: 'Plocha',
            visible: !isTableForSmallDevice && displayedCols.includes('area'),
            value: item => (item.area ? `${item.area} m²` : '-')
        },
        {
            title: 'Dispozice',
            visible: !isTableForSmallDevice && displayedCols.includes('disposition'),
            value: item => formatNullOrEmptyCellValue(item.disposition)
        },
        {
            title: 'Podlaží',
            visible: !isTableForSmallDevice && displayedCols.includes('floor'),
            value: item => formatNullOrEmptyCellValue(item.floor)
        },
        {
            title: !isTableForSmallDevice ? (
                'Lodžie, Balkon, Terasa'
            ) : (
                <>
                    Lodžie, <br />
                    Balkon, <br />
                    Terasa
                </>
            ),
            visible: !isTableForSmallDevice && displayedCols.includes('hasBalconyOrHasLoggiaOrHasTerrace'),
            value: ({ params: { hasBalcony, hasLoggia, hasTerrace } }) =>
                hasBalcony || hasLoggia || hasTerrace ? 'Ano' : '-'
        },
        {
            title: 'Zahrada',
            visible: !isTableForSmallDevice && displayedCols.includes('hasGarden'),
            value: item => (item.params.hasGarden ? 'Ano' : '-')
        },
        {
            title: 'Garáž',
            visible: !isTableForSmallDevice && displayedCols.includes('hasGarage'),
            value: item => (item.params.hasGarage ? 'Ano' : '-')
        },
        {
            title: 'Sklep',
            visible: !isTableForSmallDevice && displayedCols.includes('hasCellar'),
            value: item => (item.params.hasCellar ? 'Ano' : '-')
        },
        {
            title: 'Cena',
            visible: displayedCols.includes('price'),
            value: ({ info_in_rk, price, price_discounted, currency }) => {
                if (info_in_rk) {
                    return <PriceRoot>Info u RK</PriceRoot>
                }
                if (!price) {
                    return '-'
                }
                return (
                    <div className='flex flex-col space-y-4'>
                        {price_discounted && (
                            <PriceDiscountedRoot>
                                {getNormalizedPrice(price, getCurrencySymbol(currency))}
                            </PriceDiscountedRoot>
                        )}
                        <PriceContainerRoot>
                            <PriceRoot>
                                {getNormalizedPrice(price_discounted || price, getCurrencySymbol(currency))}
                            </PriceRoot>
                        </PriceContainerRoot>
                    </div>
                )
            }
        },
        {
            title: 'Dostupnost',
            visible: displayedCols.includes('state'),
            value: item => <StandardHeadersChip item={item} />
        },
        {
            title: 'Detail',
            visible: showDetail,
            value: (_, { toggleExpand, isExpanded }) => (
                <button onClick={toggleExpand} className='relative top-1.5 ml-2'>
                    {isExpanded ? (
                        <ArrowUpIcon size={24} color='basic-black' />
                    ) : (
                        <ArrowDownIcon size={24} color='basic-black' />
                    )}
                </button>
            )
        }
    ]
}
