import {
    LightboxClose,
    LightboxContent,
    LightboxImage,
    LightboxNavigationNextButton,
    LightboxNavigationPreviousButton,
    LightboxRoot,
    LightboxSlide
} from './Lightbox.styles'
import { LightboxProps } from './Lightbox.types'
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import useHover from '../../hooks/useHover'
import useScrollBlock from '../../hooks/useScrollBlock'

const ReactSimpleImageViewer = ({
    currentIndex: currentIndexProp,
    src,
    closeOnClickOutside,
    onClose,
    backgroundStyle,
    leftArrowComponent,
    rightArrowComponent,
    closeComponent,
    onChangeCurrentIndex
}: LightboxProps) => {
    const { blockScroll, allowScroll } = useScrollBlock()
    const rootRef = useRef<HTMLDivElement>(null)
    const slideRef = useRef<HTMLDivElement>(null)
    const [currentIndex, setCurrentIndex] = useState(currentIndexProp ?? 0)

    useEffect(() => {
        blockScroll()
        return () => {
            return allowScroll()
        }
    }, [blockScroll, allowScroll])

    const changeImage = useCallback(
        (delta: number) => {
            let nextIndex = (currentIndex + delta) % src.length
            if (nextIndex < 0) {
                nextIndex = src.length - 1
            }
            setCurrentIndex(nextIndex)
            onChangeCurrentIndex?.(nextIndex)
        },
        [currentIndex]
    )

    const handleClick = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            if (!event.target || !closeOnClickOutside) {
                return
            }

            if (event.target === rootRef.current || event.target === slideRef.current) {
                event.stopPropagation()
                onClose?.()
            }
        },
        [onClose]
    )

    const handleKeyDown = useCallback(
        (event: KeyboardEvent | React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Escape') {
                onClose?.()
            }
            if (['ArrowLeft', 'h'].includes(event.key)) {
                changeImage(-1)
            }
            if (['ArrowRight', 'l'].includes(event.key)) {
                changeImage(1)
            }
        },
        [onClose, changeImage]
    )

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            return document.removeEventListener('keydown', handleKeyDown)
        }
    }, [handleKeyDown])

    const getElementMousePosition = useCallback((element: HTMLImageElement, mouseClientXPosition: number) => {
        const centerWidth = element.offsetWidth / 2
        const xCoordinateInClickTarget = mouseClientXPosition - element.getBoundingClientRect().left
        if (centerWidth > xCoordinateInClickTarget) {
            return 'left'
        }
        return 'right'
    }, [])

    const [imageRef] = useHover<HTMLImageElement>(({ target, clientX }, mouseOver) => {
        const element = target as HTMLImageElement
        if (mouseOver && getElementMousePosition(element, clientX)) {
            element.classList.add('cursor-pointer')
            return
        }
        element.classList.remove('cursor-pointer')
    })

    const onImageClick = useCallback(
        ({ target, clientX }: MouseEvent<HTMLImageElement>) => {
            if (getElementMousePosition(target as HTMLImageElement, clientX) === 'left') {
                changeImage(-1)
                return
            }
            changeImage(1)
        },
        [changeImage, getElementMousePosition]
    )

    return (
        <LightboxRoot ref={rootRef} onKeyDown={handleKeyDown} onClick={handleClick} style={backgroundStyle}>
            <LightboxClose
                onClick={() => {
                    return onClose?.()
                }}
            >
                {closeComponent || '×'}
            </LightboxClose>

            {src.length > 1 && (
                <LightboxNavigationPreviousButton
                    onClick={() => {
                        return changeImage(-1)
                    }}
                >
                    {leftArrowComponent || '❮'}
                </LightboxNavigationPreviousButton>
            )}

            {src.length > 1 && (
                <LightboxNavigationNextButton
                    onClick={() => {
                        return changeImage(1)
                    }}
                >
                    {rightArrowComponent || '❯'}
                </LightboxNavigationNextButton>
            )}

            <LightboxContent>
                <LightboxSlide ref={slideRef}>
                    <LightboxImage src={src[currentIndex]} alt='' ref={imageRef} onClick={onImageClick} />
                </LightboxSlide>
            </LightboxContent>
        </LightboxRoot>
    )
}

export default ReactSimpleImageViewer
