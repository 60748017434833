import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const LinkedInIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7ZM16.7315 18H19V12.9531C19 10.5772 17.5092 9.27274 15.419 9.27274C14.2685 9.27274 13.2963 9.73861 12.7129 10.4685V9.39697H10.4444V18H12.7129V13.2481C12.7129 11.8661 13.5069 11.1207 14.7384 11.1207C15.9375 11.1207 16.7315 11.8661 16.7315 13.2481V18ZM8.65224 17.9739H6.25667V9.38782H8.65224V17.9739ZM8.94313 7.2524C8.94313 7.95494 8.31002 8.57002 7.47157 8.57002C6.616 8.57002 6 7.95494 6 7.2524C6 6.54985 6.616 5.90259 7.47157 5.90259C8.31002 5.90259 8.94313 6.54985 8.94313 7.2524Z'
                fill={getColorFromString(theme, color)}
            />
        </RootIcon>
    )
}

export default LinkedInIcon
