import { GalleryProps } from './Gallery.types'
import { RenderPaginatedNavigation } from '../render-paginated-navigation/RenderPaginatedNavigation'
import { extractImageUrl } from '../../utils/Helper'
import Column from '../grid/column/Column'
import Image from '../image/Image'
import React, { useState } from 'react'
import Row from '../grid/row/Row'
import useGallery from '../../hooks/useGallery'

export const Gallery = ({ images, pagination, ...props }: GalleryProps) => {
    const { setCurrentImage, renderImageViewer, getResolutionParams } = useGallery(images.map(image => image.url))
    const [currentPage, setCurrentPage] = useState(0)

    const totalPages = pagination ? Math.ceil(images.length / pagination) : 1

    const nextPage = () => {
        setCurrentPage(prev => (prev + 1) % totalPages)
    }

    const previousPage = () => {
        setCurrentPage(prev => (prev - 1 + totalPages) % totalPages)
    }

    const start = currentPage * pagination
    const currentImages = pagination ? images.slice(start, start + pagination) : images

    return (
        <>
            {renderImageViewer()}
            <Row {...props}>
                {currentImages.map(({ url: imageUrl }, index) => (
                    <Column key={imageUrl} cols={6} sm={2} md={3}>
                        <Image
                            onClick={() => setCurrentImage(start + index)}
                            src={extractImageUrl(imageUrl, getResolutionParams('sd'))}
                            height='100%'
                            width='100%'
                            aspectRatio='149 / 133'
                        />
                    </Column>
                ))}
            </Row>
            {pagination && (
                <RenderPaginatedNavigation
                    className='mt-8 justify-center'
                    leftArrowClick={previousPage}
                    rightArrowClick={nextPage}
                />
            )}
        </>
    )
}
