import { NavigationButton, NavigationContainer } from '../image-block/ImageBlock.styles'
import ArrowLeftIcon from '../icon/ArrowLeftIcon'
import ArrowRightIcon from '../icon/ArrowRightIcon'

export const RenderPaginatedNavigation = ({
    leftArrowClick,
    leftArrowDisabled = false,
    rightArrowClick,
    rightArrowDisabled = false,
    ...props
}) => {
    return (
        <NavigationContainer {...props}>
            <NavigationButton disabled={leftArrowDisabled} onClick={leftArrowClick}>
                <ArrowLeftIcon size={16} color='text-cta' />
            </NavigationButton>
            <NavigationButton disabled={rightArrowDisabled} onClick={rightArrowClick}>
                <ArrowRightIcon size={16} color='text-cta' />
            </NavigationButton>
        </NavigationContainer>
    )
}
