import { useEffect, useMemo, useState } from 'react'

export type WindowSize = {
    width: number | undefined
    height: number | undefined
}

export type WindowSizeBreakpoints = {
    isXS: boolean
    isSM: boolean
    isMD: boolean
    isXL: boolean
    isXL2: boolean
    isMDOrGreater: boolean
    isMDOrLesser: boolean
    isSMOrGreater: boolean
    isSMOrLesser: boolean
    isXSOrGreater: boolean
    isXSOrLesser: boolean
    isXLOrGreater: boolean
    isXLOrLesser: boolean
    isXL2OrGreater: boolean
    isXL2OrLesser: boolean
    is1024rGreater: boolean
}

export type UseWindowSizeSize = WindowSize & { breakpoints?: WindowSizeBreakpoints }

const useWindowSize = (): UseWindowSizeSize => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined
    })

    const breakpoints = useMemo<WindowSizeBreakpoints | undefined>(() => {
        if (!windowSize?.width) {
            return
        }

        const { width } = windowSize
        const isXS = width < 576
        const isSM = width >= 576 && width < 768
        const isMD = width >= 768 && width < 992
        const isXL = width >= 992 && width < 1200
        const isXL2 = width >= 1200
        const isMDOrGreater = width >= 768
        const isMDOrLesser = width < 992
        const isSMOrGreater = width >= 576
        const isSMOrLesser = width < 768
        const isXSOrGreater = width < 576
        const isXSOrLesser = width >= 0
        const isXLOrGreater = width >= 992
        const isXLOrLesser = width < 1200
        const isXL2OrGreater = width >= 1200
        const isXL2OrLesser = width < 1200
        const is1024rGreater = width >= 1024

        return {
            isXS,
            isXSOrGreater,
            isXSOrLesser,
            isSM,
            isSMOrGreater,
            isSMOrLesser,
            isMD,
            isMDOrGreater,
            isMDOrLesser,
            isXL,
            isXLOrGreater,
            isXLOrLesser,
            isXL2,
            isXL2OrGreater,
            isXL2OrLesser,
            is1024rGreater
        }
    }, [windowSize])

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => {
            return window.removeEventListener('resize', handleResize)
        }
    }, [])

    return { ...windowSize, breakpoints }
}

export default useWindowSize
