import { MutableRefObject, useEffect, useRef, useState } from 'react'

const useHover = <T extends HTMLElement>(
    onHover?: (event: MouseEvent, mouseOver: boolean) => void
): [MutableRefObject<T>, boolean] => {
    const [value, setValue] = useState<boolean>(false)

    const ref = useRef<T>()

    const handleMouseOver = (event: MouseEvent) => {
        onHover?.(event, true)
        setValue(true)
    }
    const handleMouseOut = (event: MouseEvent) => {
        onHover?.(event, false)
        setValue(false)
    }

    useEffect(() => {
        if (ref.current) {
            const node = ref.current

            node.addEventListener('mouseover', handleMouseOver)
            node.addEventListener('mouseout', handleMouseOut)

            return () => {
                node.removeEventListener('mouseover', handleMouseOver)
                node.removeEventListener('mouseout', handleMouseOut)
            }
        }
    }, [])

    return [ref, value]
}

export default useHover
