import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const ArrowLeftIcon = ({ color = 'basic-black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.5082 19.7263C14.9326 20.1476 14.0818 20.0744 13.6079 19.5628L7.30789 12.7628C6.89737 12.3197 6.89737 11.6803 7.30789 11.2372L13.6079 4.43718C14.0818 3.9256 14.9326 3.85241 15.5082 4.27372C16.0837 4.69502 16.166 5.45129 15.6921 5.96288L10.0988 12L15.6921 18.0371C16.166 18.5487 16.0837 19.305 15.5082 19.7263Z'
                fill={getColorFromString(theme, color)}
            />
        </RootIcon>
    )
}

export default ArrowLeftIcon
