import { PostMortgageCalculatorFormRequest } from '../../../services/mortgage-calculator/MortgageCalculatorService.types'
import { postMortgageCalculator } from '../../../services/mortgage-calculator/MortgageCalculatorService'
import { useAxios } from '../../useAxios'
import { useCallback, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import toast from 'react-hot-toast'

const useMortgageCalculatorPost = (hash: string) => {
    const axios = useAxios()
    const { executeRecaptcha } = useGoogleReCaptcha()
    const [isLoading, setLoading] = useState<boolean>()
    const [notFound, setNotFound] = useState<boolean>(false)

    const postMortgageCalculatorForm = useCallback(
        async (data: PostMortgageCalculatorFormRequest, throwError = true) => {
            setLoading(true)
            setNotFound(false)
            try {
                const googleRecaptchaToken = await executeRecaptcha('mortgage_calculator')
                const response = await postMortgageCalculator(axios, hash, data, googleRecaptchaToken)
                setNotFound(false)
                toast.success('Formulář byl odeslán.')
                return response
            } catch (e) {
                toast.error('Formulář byl odeslán.')
                setNotFound(true)
                if (throwError) {
                    return Promise.reject(e)
                }
            } finally {
                setLoading(false)
            }
        },
        [axios, executeRecaptcha]
    )

    return {
        isLoading,
        notFound,
        postMortgageCalculatorForm
    }
}

export default useMortgageCalculatorPost
