import { CardFileContainer, CardFileIconContainer, CardFileRoot } from './CardFile.styles'
import { CardFileProps } from './CardFile.types'
import Button from '../../button/Button'
import DownloadIcon from '../../icon/DownloadIcon'
import NextLink from 'next/link'
import PdfIcon from '../../icon/PdfIcon'
import React from 'react'
import Typography from '../../typography/Typography'

const CardFile = ({ title, href, icon = <PdfIcon size={64} />, ...props }: CardFileProps) => {
    return (
        <CardFileRoot {...props}>
            <NextLink href={href} target='_blank'>
                <CardFileIconContainer>{icon}</CardFileIconContainer>
                <CardFileContainer>
                    <Typography level='body' size='large' type='semibold'>
                        {title}
                    </Typography>
                    <Button color='blue' variant='text' leftIcon={<DownloadIcon color='text-button' />}>
                        Stáhnout soubor
                    </Button>
                </CardFileContainer>
            </NextLink>
        </CardFileRoot>
    )
}

export default CardFile
