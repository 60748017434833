import { NavbarMenuItemProps } from './Navbar.types'
import { NavbarMenuItemRoot } from './Navbar.styles'
import { useRouter } from 'next/router'
import React, { HTMLAttributes, MouseEvent, PropsWithChildren } from 'react'

const NavbarMenuItem = ({
    children,
    scrollId,
    onClick: propOnClick,
    ...props
}: PropsWithChildren<NavbarMenuItemProps>) => {
    const router = useRouter()

    const onClick = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault()
        propOnClick?.()
        router.push({ query: { ...router.query, scrollId } }, undefined, { scroll: false })
    }

    return (
        <NavbarMenuItemRoot
            {...(props as HTMLAttributes<HTMLSpanElement>)}
            $isFocused={router?.query?.scrollId === scrollId}
            onClick={onClick}
        >
            {children}
        </NavbarMenuItemRoot>
    )
}

export default NavbarMenuItem
