import { ReactNode } from 'react'
import { Reviews } from '../../services/Microsite.types'
import Stars from '../stars/Stars'
import Typography from '../typography/Typography'

export type RenderLogoAndReviewsProps = {
    reviewLogo: ReactNode
    reviews: Reviews
}

export const RenderLogoAndReviews = ({ reviewLogo, reviews }: RenderLogoAndReviewsProps) => {
    const { reviewsRating, reviewsCount, reviewsRatingUrl } = reviews
    if (!reviewsRating && !reviewsCount) {
        return
    }
    return (
        <a href={reviewsRatingUrl} className='hidden md:flex flex-row items-center'>
            {reviewLogo}
            <div className='flex items-center ml-2'>
                <Typography className='text-secondaryBlue-50' size='small' type='semibold'>
                    {reviewsRating?.toFixed(1)}
                </Typography>

                <Stars color='gold' value={reviewsRating} className='px-1.5' />
                <span className='text-xs text-grey-200'>{`${reviewsCount} recenzí`}</span>
            </div>
        </a>
    )
}
