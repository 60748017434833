import { GoogleReviewsProps } from './GoogleReviews.types'
import { GoogleReviewsRoot } from './GoogleReviews.styles'
import GoogleLogo from '../logo/GoogleLogo'
import React from 'react'
import Stars from '../stars/Stars'
import Typography from '../typography/Typography'

const GoogleReviews = ({ stars, ...props }: GoogleReviewsProps) => {
    return (
        <GoogleReviewsRoot {...props}>
            <GoogleLogo />
            <div className='flex items-center mt-2'>
                <Typography className='text-secondaryBlue-50' size='small' type='semibold'>
                    Recenze | {stars.toFixed(1)}
                </Typography>
                <Stars value={stars} className='px-1.5' />
            </div>
        </GoogleReviewsRoot>
    )
}

export default GoogleReviews
