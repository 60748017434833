import tw, { styled } from 'twin.macro'

export const CardIconContainer = styled.span`
    ${tw`flex justify-center items-center`}
    border-radius: 0.625rem;
    width: 3.25rem;
    height: 3.25rem;
    margin-bottom: 0.75rem !important;
    ${tw`bg-text-badge`}
`

export const CardRoot = styled.div`
    ${tw`flex justify-start items-start flex-col`}
    border-radius: 1.563rem;
    box-shadow: 0 2.875rem 4rem rgba(0, 43, 92, 0.1);
    padding: 3.125rem 1.875rem;
    flex-direction: column;
`
