import { TextFieldProps } from './TextField.types'
import { TextFieldRoot } from './TextField.styles'
import React, { ForwardedRef, forwardRef, useId } from 'react'
import TextFieldValidationMessage from '../text-field-validation-message/TextFieldValidationMessage'

const TextField = (
    { label, id: idProp, containerClassName, required, invalidMessage, ...props }: TextFieldProps,
    ref: ForwardedRef<HTMLInputElement>
) => {
    const id = useId()

    return (
        <TextFieldRoot className={containerClassName}>
            <label htmlFor={idProp || id}>
                {label} {required ? <span className='text-error-400'>*</span> : null}
            </label>
            <input {...props} ref={ref} id={idProp || id} type='text' />
            {invalidMessage && <TextFieldValidationMessage message={invalidMessage} />}
        </TextFieldRoot>
    )
}

export default forwardRef(TextField)
