import { MortgageCalculatorInputLabel } from './MortgageCalculator.styles'
import { MortgageCalculatorPriceProps } from './MortgageCalculator.types'
import Column from '../grid/column/Column'
import MortgageCalculatorInput from './MortgageCalculatorInput'
import React from 'react'
import Row from '../grid/row/Row'

const MortgageCalculatorPrice = ({ change, value, currency, ...props }: MortgageCalculatorPriceProps) => {
    return (
        <Column cols={12} xl={5} className='space-y-5' {...props}>
            <Row align='center' className='space-y-4'>
                <Column cols={12}>
                    <Row align='center'>
                        <Column cols={12} md={7} lg={7}>
                            <MortgageCalculatorInputLabel>Cena nemovitosti</MortgageCalculatorInputLabel>
                        </Column>
                        <Column cols={12} md={3}>
                            <MortgageCalculatorInput
                                containerClassName='md:!-mr-[35px]'
                                className='md:w-36 md:ml-2.5'
                                unit={currency}
                                change={change}
                                value={value}
                                hiddenLabel='Cena nemovitosti'
                                hiddenName='price'
                                hiddenValue={`${value} ${currency}`}
                            />
                        </Column>
                    </Row>
                </Column>
            </Row>
        </Column>
    )
}

export default MortgageCalculatorPrice
