import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const PdfIcon = ({ color = 'black', size = { height: 19, width: 16 }, ...props }: IconProps) => {
    const theme = useTheme()
    const themeColor = getColorFromString(theme, color)
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 64 69'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_2_1516)'>
                <path
                    d='M15.1647 67.5625H60.8094C61.8709 67.5625 62.6291 66.7639 62.6291 65.6458V15.0139C62.6291 14.5347 62.4775 14.0556 62.1742 13.7361L51.1042 2.0764C50.8009 1.75695 50.346 1.59723 49.8911 1.59723H15.0131C13.9516 1.59723 13.1934 2.39584 13.1934 3.5139L13.345 65.8056C13.345 66.6042 14.2549 67.5625 15.1647 67.5625Z'
                    fill='white'
                />
                <path
                    d='M60.8095 69H15.1648C13.4967 69 11.9803 67.5625 11.9803 65.6458L11.8286 3.35417C11.8286 2.39583 12.1319 1.59722 12.7385 0.958334C13.345 0.319445 14.1033 0 15.0131 0H49.7395C50.6493 0 51.4076 0.319445 52.0141 0.958334L63.0841 12.6181C63.6907 13.2569 63.994 14.0556 63.994 15.0139V65.6458C63.994 67.4028 62.6292 69 60.8095 69ZM15.0131 2.875C14.8615 2.875 14.7098 3.03472 14.7098 3.03472C14.7098 3.03472 14.5582 3.19444 14.5582 3.35417L14.7098 65.6458C14.7098 65.9653 14.8615 66.125 15.1648 66.125H60.8095C61.1128 66.125 61.2644 65.9653 61.2644 65.6458V15.0139C61.2644 14.8542 61.2644 14.6944 61.1128 14.6944L50.0428 3.03472C49.8911 2.875 49.8911 2.875 49.7395 2.875H15.0131Z'
                    fill={themeColor}
                />
                <path
                    d='M62.326 14.2153H51.7109C51.1043 14.2153 50.6494 13.7361 50.6494 13.0973V1.9167C50.6494 1.75697 50.8011 1.75697 50.9527 1.75697L62.4776 13.8959C62.4776 14.0556 62.4776 14.2153 62.326 14.2153Z'
                    fill='#CF1534'
                />
                <path
                    d='M62.326 15.6528H51.711C50.3462 15.6528 49.2847 14.5348 49.2847 13.0973V1.9167C49.2847 1.27781 49.588 0.63892 50.1945 0.479197C50.8011 0.159753 51.4077 0.319475 51.8626 0.798642L63.5392 13.0973C63.9941 13.5764 64.1457 14.2153 63.8424 14.8542C63.3875 15.1736 62.9326 15.6528 62.326 15.6528ZM51.8626 12.7778H59.4448L51.8626 4.7917V12.7778Z'
                    fill={themeColor}
                />
                <path
                    d='M37.3042 50.9514H2.72954C1.97132 50.9514 1.36475 50.3125 1.36475 49.5139V29.2292C1.36475 28.4306 1.97132 27.7917 2.72954 27.7917H37.4559C38.2141 27.7917 38.8207 28.4306 38.8207 29.2292V49.5139C38.669 50.3125 38.0625 50.9514 37.3042 50.9514Z'
                    fill='white'
                />
                <path
                    d='M36.8494 52.3889H3.18451C1.51644 52.3889 0 50.9514 0 49.0347V29.7084C0 27.9514 1.36479 26.3542 3.18451 26.3542H37.001C38.6691 26.3542 40.1855 27.7917 40.1855 29.7084V49.0347C40.0339 50.9514 38.6691 52.3889 36.8494 52.3889ZM3.18451 29.2292C2.88123 29.2292 2.72958 29.3889 2.72958 29.7084V49.0347C2.72958 49.3542 2.88123 49.5139 3.18451 49.5139H37.001C37.3043 49.5139 37.4559 49.3542 37.4559 49.0347V29.7084C37.4559 29.3889 37.3043 29.2292 37.001 29.2292H3.18451Z'
                    fill={themeColor}
                />
                <path
                    d='M8.46978 39.1765H9.28242C10.0419 39.1765 10.6103 39.036 10.9874 38.7551C11.3645 38.4692 11.553 38.0554 11.553 37.5137C11.553 36.9669 11.3937 36.5632 11.075 36.3023C10.7616 36.0415 10.2677 35.9111 9.59313 35.9111H8.46978V39.1765ZM14.0467 37.4309C14.0467 38.6147 13.6537 39.5201 12.8676 40.1471C12.0868 40.7741 10.9741 41.0876 9.5294 41.0876H8.46978V45H6V34H9.7206C11.1334 34 12.2063 34.2884 12.9393 34.8653C13.6776 35.4371 14.0467 36.2923 14.0467 37.4309Z'
                    fill={themeColor}
                />
                <path
                    d='M25.9096 39.3947C25.9096 41.2054 25.3625 42.5923 24.2684 43.5554C23.1796 44.5185 21.6048 45 19.544 45H16.2456V34H19.9025C21.8039 34 23.2805 34.474 24.3321 35.422C25.3838 36.37 25.9096 37.6943 25.9096 39.3947ZM23.3442 39.4549C23.3442 37.0923 22.2395 35.9111 20.0299 35.9111H18.7154V43.0739H19.775C22.1545 43.0739 23.3442 41.8675 23.3442 39.4549Z'
                    fill={themeColor}
                />
                <path
                    d='M30.7536 45H28.3236V34H35V35.9111H30.7536V38.7476H34.7052V40.6512H30.7536V45Z'
                    fill={themeColor}
                />
            </g>
            <defs>
                <clipPath id='clip0_2_1516'>
                    <rect width='64' height='69' fill='white' />
                </clipPath>
            </defs>
        </RootIcon>
    )
}

export default PdfIcon
