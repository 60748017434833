import { CardContactDetailUrlProps } from './CardContact.types'
import { buildClasses } from '../../../utils/Helper'
import React from 'react'

const CardContactDetailUrl = ({ children, showUnderline, url, ...props }: CardContactDetailUrlProps) => {
    return (
        <a
            {...props}
            href={url}
            className={buildClasses({ 'hover:underline': showUnderline })}
            target='_blank'
            rel='noreferrer noopener'
        >
            {children}
        </a>
    )
}

export default CardContactDetailUrl
