import { Content, FooterLink, TitleBorder, TitleContainer } from './Footer.styles'
import { FooterContactProps } from './Footer.types'
import CallIcon from '../icon/CallIcon'
import Column from '../grid/column/Column'
import EmailIcon from '../icon/EmailIcon'
import React, { Fragment } from 'react'
import Row from '../grid/row/Row'
import SocialIcon from '../icon/SocialIcon'
import Typography from '../typography/Typography'
import useWindowSize from '../../hooks/useWindowSize'

const FooterContact = ({ contact, socials, shouldDisplayContactHeader }: FooterContactProps) => {
    const { breakpoints } = useWindowSize()

    const renderContactAddressLines = () =>
        shouldDisplayContactHeader ? (
            <Typography size='small' type='semibold' className='text-basic-white'>
                {contact.addressLines.map((line, index) => (
                    <Fragment key={index}>
                        {line} <br />
                        <br />
                    </Fragment>
                ))}
            </Typography>
        ) : (
            <div className='flex flex-col mb-5'>
                {contact.addressLines.map((line, index) => (
                    <Typography
                        key={index}
                        size={index === 0 ? 'large' : 'small'}
                        type={index === 0 ? 'bold' : 'semibold'}
                        className='text-basic-white m-6'
                    >
                        {line}
                    </Typography>
                ))}
            </div>
        )

    const renderPhoneDetails = () => (
        <div className='flex-grow flex-shrink'>
            <FooterLink
                className={`flex items-center ${socials.length > 0 ? 'justify-start' : 'justify-center'}`}
                href={`tel:${contact.phone}`}
                title={contact.phone}
            >
                <CallIcon size={24} color='secondaryBlue-50' className='mr-2' />
                <Typography size='normal' type='semibold' className='text-basic-white'>
                    {contact.phone}
                </Typography>
            </FooterLink>
        </div>
    )

    const renderEmailDetails = () => (
        <div className='flex-grow flex-shrink'>
            <FooterLink
                className={`flex items-center ${socials.length > 0 ? 'justify-start' : 'justify-end'}`}
                href={`mailto:${contact.email}`}
                title={contact.email}
            >
                <EmailIcon size={24} color='secondaryBlue-50' className='mr-2' />
                <Typography size='normal' type='semibold' className='text-basic-white'>
                    {contact.email}
                </Typography>
            </FooterLink>
        </div>
    )

    const renderSocialMedia = () => (
        <div className='flex flex-wrap items-center justify-center lg:justify-start gap-6 mt-3 lg:mt-0'>
            {socials.map(({ type, title, url }) => (
                <FooterLink
                    title={title}
                    key={type}
                    href={url}
                    target='_blank'
                    className='!text-basic-white block !no-underline'
                >
                    <SocialIcon socialType={type} size={24} color='white' />
                </FooterLink>
            ))}
        </div>
    )
    if (!shouldDisplayContactHeader) {
        return (
            <div className='flex flex-row flex-wrap'>
                <div className='flex-grow flex-shrink'>
                    <Content className='flex flex-row items-center justify-between'>
                        {renderContactAddressLines()}
                    </Content>
                </div>
                <div className='flex-grow flex-shrink'>
                    <Content className='flex flex-row items-center justify-between'>{renderPhoneDetails()}</Content>
                </div>
                <div className='flex-grow flex-shrink'>
                    <Content className='flex flex-row items-center justify-between'>{renderEmailDetails()}</Content>
                </div>
                {socials.length > 0 && (
                    <div className='flex-grow flex-shrink'>
                        <Content className='items-center text-end'>{renderSocialMedia()}</Content>
                    </div>
                )}
            </div>
        )
    } else {
        return (
            <Row marginY={false}>
                <Column cols={12} lg={3} paddingY={false} />
                <Column cols={12} lg={9} paddingY={false}>
                    <div className='flex flex-col justify-center items-center lg:justify-start lg:items-start'>
                        <TitleContainer shouldDisplayContactHeader={shouldDisplayContactHeader}>
                            <Typography type='bold' className='text-basic-white'>
                                Kontakt
                            </Typography>
                        </TitleContainer>
                        <TitleBorder
                            shouldDisplayContactHeader={shouldDisplayContactHeader}
                            smallScreen={!breakpoints?.is1024rGreater}
                        />
                    </div>

                    <Content>
                        <Row direction='column' justify='evenly'>
                            <Column cols={12}>{renderContactAddressLines()}</Column>
                            <Column cols={12}>
                                <FooterLink
                                    className='underline block'
                                    href={`mailto:${contact.email}`}
                                    title={contact.email}
                                >
                                    {contact.email}
                                </FooterLink>
                                <FooterLink
                                    className='underline block'
                                    href={`tel:${contact.phone}`}
                                    title={contact.phone}
                                >
                                    {contact.phone}
                                </FooterLink>
                            </Column>
                            <Column cols={12}>{renderSocialMedia()}</Column>
                        </Row>
                    </Content>
                </Column>
            </Row>
        )
    }
}

export default FooterContact
