import { MenuItem } from './Navbar.types'
import { MicroSiteContent } from '../../services/Microsite.types'

export const getMenuItemsByRenderedSections = (components: MicroSiteContent[]): MenuItem[] => {
    const componentNameToMenuItem = (componentName: string, rawTitle: string, hash: string): MenuItem | null => {
        const title = rawTitle.replace(/^\d+\.\s*/, '')
        switch (componentName) {
            case 'description':
                return {
                    key: 'description',
                    name: title,
                    scrollId: hash
                }
            case 'params':
                return {
                    key: 'paramsScrollContainer',
                    name: title,
                    scrollId: hash
                }
            case 'interactive':
                return {
                    key: 'interactive',
                    name: title,
                    scrollId: hash
                }
            case 'gallery':
                return {
                    key: 'gallery',
                    name: title,
                    scrollId: hash
                }
            case 'floorplan':
                return {
                    key: 'floorplan',
                    name: title,
                    scrollId: hash
                }
            case 'matterport':
                return {
                    key: 'matterport',
                    name: title,
                    scrollId: hash
                }
            case 'location':
                return {
                    key: 'location',
                    name: title,
                    scrollId: hash
                }
            case 'videotour':
                return {
                    key: 'videotour',
                    name: title,
                    scrollId: hash
                }
            case 'mortgagecalculator':
                return {
                    key: 'mortgagecalculator',
                    name: title,
                    scrollId: hash
                }
            case 'documents':
                return {
                    key: 'documents',
                    name: title,
                    scrollId: hash
                }
            case 'contactform':
                return {
                    key: 'contactform',
                    name: title,
                    scrollId: hash
                }
            case 'devprojectproperties':
                return {
                    key: 'devprojectproperties',
                    name: title,
                    scrollId: hash
                }
            case 'devprojectfinancing':
                return {
                    key: 'devprojectfinancing',
                    name: title,
                    scrollId: hash
                }
            default:
                return null
        }
    }

    components = components.filter((item: MicroSiteContent) => {
        return item.menuTitle
    })

    return components.map((item: MicroSiteContent) => {
        const { componentName, menuTitle, hash } = item
        return componentNameToMenuItem(componentName, menuTitle, hash)
    })
}
