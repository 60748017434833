import tw, { styled } from 'twin.macro'

export const CardFileRoot = styled.div`
    ${tw`flex justify-center items-center flex-col`}
    border-radius: 1.563rem;
    box-shadow: 0 2.875rem 4rem rgba(0, 43, 92, 0.1);
    min-width: 18.625rem;
    padding: 1.875rem 1.25rem 1rem;
`

export const CardFileIconContainer = styled.span`
    ${tw`flex justify-center items-center w-full`}
    margin-bottom: 1.625rem !important;
`

export const CardFileContainer = styled.div`
    ${tw`flex items-center flex-col`}
    gap: 5.25rem;
`
