import { InfoBadgeContent, InfoBadgeTitle } from './InfoBadge.styles'
import { InfoBadgeProps } from './InfoBadge.types'
import React from 'react'

const InfoBadge = ({ isForProperty, children, color, bgColor, title, ...props }: InfoBadgeProps) => {
    return (
        <div {...props} title={title} className='inline-block'>
            {title && (
                <InfoBadgeTitle $isForProperty={isForProperty} className='text-body-small '>
                    {title}
                </InfoBadgeTitle>
            )}
            {children && (
                <InfoBadgeContent $color={color} $bgColor={bgColor} className='text-body-normal semibold'>
                    {children}
                </InfoBadgeContent>
            )}
        </div>
    )
}

export default InfoBadge
