import { ComponentContactProps } from './ComponentManager.types'
import { ComponentManagerComponentRow } from './ComponentManager.styles'
import { Contact, ContactFormData, ContactFormRef } from '../contact-form/ContactForm.types'
import { MicroSiteDetailComponentContact } from '../../services/Microsite.types'
import { Element as ScrollElement } from 'react-scroll'
import Column from '../grid/column/Column'
import ContactForm from '../contact-form/ContactForm'
import React, { useRef } from 'react'
import SectionTitle from '../section-title/SectionTitle'
import useMicrositeContactFormPost from '../../hooks/api/shared-microsite/useMicrositeContactFormPost'

const ComponentContact = ({
    title,
    firstContactComponent,
    secondContactComponent,
    bgColor,
    hash
}: ComponentContactProps) => {
    const { postContactForm: postPropertyMicroSiteContactForm } = useMicrositeContactFormPost()
    const contactForm = useRef<ContactFormRef>(null)

    const mapContact = (component: MicroSiteDetailComponentContact): Contact => {
        return {
            name: component.brokerName,
            role: component.brokerPosition,
            emailAddress: component.brokerEmail,
            phoneNumber: component.brokerPhone,
            image: component.brokerPhoto,
            id: component.hash,
            certificate: component.certificate,
            stars: component.stars,
            detailUrl: component.detailUrl
        }
    }

    const formSubmit = (data: ContactFormData) => {
        postPropertyMicroSiteContactForm({
            name: data.fullName,
            email: data.email,
            phone: data.phone,
            text: data.message,
            checkbox: data.agreeProcessingPersonalData ? 1 : 0,
            brokerEmail: data.brokerEmail
        }).then(() => {
            return contactForm.current.resetForm()
        })
    }

    return (
        <ScrollElement name={hash}>
            <ComponentManagerComponentRow>
                <Column cols={12}>
                    <SectionTitle>{title}</SectionTitle>
                </Column>
                <Column cols={12}>
                    <ContactForm
                        ref={contactForm}
                        bgColor={bgColor}
                        submit={formSubmit}
                        firstContact={firstContactComponent && mapContact(firstContactComponent)}
                        secondContact={secondContactComponent && mapContact(secondContactComponent)}
                    />
                </Column>
            </ComponentManagerComponentRow>
        </ScrollElement>
    )
}

export default ComponentContact
