import { MortgageCalculatorInputLabel } from './MortgageCalculator.styles'
import { MortgageCalculatorTotalProps } from './MortgageCalculator.types'
import Button from '../button/Button'
import Column from '../grid/column/Column'
import HiddenInput from '../hidden-input/HiddenInput'
import React from 'react'
import Row from '../grid/row/Row'
import Typography from '../typography/Typography'

const MortgageCalculatorTotal = ({
    totalPrice,
    totalPriceCurrency,
    interestRate,
    openMenu,
    ...props
}: MortgageCalculatorTotalProps) => {
    const renderTotalPrice = () => {
        if (totalPrice) {
            const groupTotalPrice = totalPrice.toLocaleString('cs-CZ', { useGrouping: true })
            const totalPriceWithCurrency = `${groupTotalPrice} ${totalPriceCurrency}`
            return (
                <>
                    <HiddenInput label='Vypočítaná měsíční splátka' name='totalPrice' value={totalPriceWithCurrency} />
                    <Typography className='!text-4xl text-text-blue !font-bold'>{totalPriceWithCurrency}</Typography>
                </>
            )
        }
        return <Typography className='!text-4xl text-text-blue !font-bold'>0 {totalPriceCurrency}</Typography>
    }

    return (
        <Column cols={12} {...props}>
            <Row justify='between' className='space-y-4'>
                <Column md={4} xl={4}>
                    <div className='flex flex-col'>
                        <MortgageCalculatorInputLabel className='!font-bold'>
                            Vypočítaná měsíční splátka
                        </MortgageCalculatorInputLabel>
                        <HiddenInput label='Úroková sazba' name='interestRate' value={`${interestRate} %`} />
                        <Typography className='!text-lg text-text-blue'>{`Úroková sazba ${interestRate}%`}</Typography>
                    </div>
                </Column>
                <Column md={3} xl={7}>
                    {renderTotalPrice()}
                </Column>
                <Column cols={12}>
                    <Button color='red' className='mt-10' onClick={openMenu} type='button'>
                        Zařídit hypotéku
                    </Button>
                </Column>
            </Row>
        </Column>
    )
}

export default MortgageCalculatorTotal
