import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const BarrierFreeIcon = ({ color = 'basic-black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.75 17.25V11.2431C9.34928 11.3764 8.96599 11.5633 8.61104 11.8005C7.82991 12.3225 7.22109 13.0643 6.86157 13.9323C6.50206 14.8002 6.40799 15.7553 6.59127 16.6767C6.77455 17.5981 7.22695 18.4445 7.89125 19.1088C8.55554 19.7731 9.40191 20.2254 10.3233 20.4087C11.2447 20.592 12.1998 20.4979 13.0677 20.1384C13.9357 19.7789 14.6775 19.1701 15.1995 18.389C15.4366 18.034 15.6236 17.6507 15.7569 17.25H9.75ZM9.75 9.68267C9.05075 9.85554 8.38292 10.1489 7.77769 10.5533C6.74988 11.2401 5.9488 12.2162 5.47576 13.3582C5.00271 14.5003 4.87894 15.7569 5.12009 16.9693C5.36125 18.1817 5.95651 19.2953 6.83059 20.1694C7.70466 21.0435 8.81831 21.6387 10.0307 21.8799C11.2431 22.1211 12.4997 21.9973 13.6418 21.5242C14.7838 21.0512 15.7599 20.2501 16.4467 19.2223C16.9473 18.473 17.2778 17.6278 17.4202 16.7458C17.5082 16.2005 17.0523 15.75 16.5 15.75H11.25V9.5C10.7418 9.5 10.2382 9.56196 9.75 9.68267Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10.8319 8.22782C11.2461 8.22695 11.5826 8.56202 11.5835 8.97623L11.5978 15.7304L16.5044 15.7472C17.3479 15.75 18.0693 16.3542 18.2202 17.1841L19.0714 21.8658C19.1455 22.2734 18.8752 22.6638 18.4677 22.7379C18.0601 22.812 17.6697 22.5417 17.5956 22.1342L16.7444 17.4524C16.7228 17.3339 16.6198 17.2476 16.4993 17.2472L10.1009 17.2253L10.0835 8.97941C10.0826 8.5652 10.4177 8.2287 10.8319 8.22782Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10.0835 12.8333C10.0835 12.4191 10.4193 12.0833 10.8335 12.0833H15.0002C15.4144 12.0833 15.7502 12.4191 15.7502 12.8333C15.7502 13.2475 15.4144 13.5833 15.0002 13.5833H10.8335C10.4193 13.5833 10.0835 13.2475 10.0835 12.8333Z'
                fill={fillColor}
            />
            <path
                d='M13.3335 4.5C13.3335 5.88071 12.2142 7 10.8335 7C9.45278 7 8.3335 5.88071 8.3335 4.5C8.3335 3.11929 9.45278 2 10.8335 2C12.2142 2 13.3335 3.11929 13.3335 4.5Z'
                fill={fillColor}
            />
        </RootIcon>
    )
}

export default BarrierFreeIcon
