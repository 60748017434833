import { LayoutSEO } from '../layout/Layout.types'
import { MicroSite } from '../services/Microsite.types'
import { MicrositeHeaderContact, MicrositeHeaderInfo } from '../components/microsite-header/MicrositeHeader.types'
import { extractImageUrl } from '../utils/Helper'
import { seoConfig } from '../config/seo/seoConfig'
import { useEnvironment } from './useEnvironment'
import { useMemo } from 'react'

type UseMicrositePageMetadataConfig = {
    isForProperty?: boolean
    isForProject?: boolean
    data?: MicroSite
    slug?: string
}

const useMicrositePageMetadata = ({ data, slug, isForProject, isForProperty }: UseMicrositePageMetadataConfig) => {
    const { frontendUrl } = useEnvironment()

    const contact = useMemo<MicrositeHeaderContact | undefined>(() => {
        return (
            data?.broker?.usePropertyBroker && {
                showCard: data?.broker?.showBrokerMainCard,
                name: data?.broker?.brokerName,
                email: data?.broker?.brokerEmail,
                phoneNumber: data?.broker?.brokerPhone,
                role: data?.broker?.brokerPosition,
                certificate: data?.broker?.certificate,
                image: data?.broker?.brokerPhoto
                    ? extractImageUrl(data.broker.brokerPhoto, { width: 130, height: 168 })
                    : '',
                stars: data?.broker?.stars || 0,
                detailUrl: data?.broker?.detailUrl
            }
        )
    }, [data])

    const header = useMemo<MicrositeHeaderInfo>(() => {
        return {
            params: data?.params,
            customParams: data?.customParams,
            energyLabel: data?.energyLabel,
            infoInRk: data?.infoInRk,
            price: data?.price,
            currency: data?.currency,
            priceDiscounted: data?.priceDiscounted,
            priceNote: data?.priceNote,
            priceType: data?.priceType,
            ownership: data?.ownership,
            logoImageUrl: data?.logoImageUrl,
            previewImageUrl: data?.previewImageUrl,
            previewImageUrls: data?.previewImageUrls,
            area: data?.area,
            building: data?.building,
            disposition: data?.disposition,
            template: data?.template
        }
    }, [data])

    const seo = useMemo<LayoutSEO>(() => {
        if (isForProject) {
            return {
                ...data?.seo,
                metaUrl: seoConfig.project['og-url']
                    .replace('{frontend-url}', frontendUrl)
                    .replace('{slug}', slug?.toString())
            }
        }
        if (isForProperty) {
            return {
                ...data?.seo,
                metaUrl: seoConfig.property['og-url']
                    .replace('{frontend-url}', frontendUrl)
                    .replace('{slug}', slug?.toString())
            }
        }
        return data?.seo
    }, [data, slug, frontendUrl])

    return {
        seo,
        contact,
        header
    }
}

export default useMicrositePageMetadata
