import { ComponentManagerComponentRow } from '../ComponentManager.styles'
import { ComponentPropertiesTableProps } from '../ComponentManager.types'
import { MicroSiteDetailComponentPropertiesItem } from '../../../services/Microsite.types'
import { Element as ScrollElement, scroller } from 'react-scroll'
import { StandardHeaders } from './StandardHeaders'
import { useRouter } from 'next/router'
import Column from '../../grid/column/Column'
import ComponentPropertiesTableExpansion from './ComponentPropertiesTableExpansion'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import SectionTitle from '../../section-title/SectionTitle'
import Table from '../../table/Table'
import useWindowSize from '../../../hooks/useWindowSize'
import validator from 'validator'

const ComponentPropertiesTable = ({
    component: { title, properties, displayedCols, displayedDynamicCols, hash },
    ...props
}: ComponentPropertiesTableProps) => {
    const { width } = useWindowSize()
    const sectionRef = useRef<HTMLDivElement>()
    const router = useRouter()
    const [expandedRowId, setExpandedRowId] = useState(null)

    const isTableForSmallDevice = useMemo<boolean>(() => {
        return width <= 1200
    }, [width])

    const initialExpandedRows = useMemo<number[]>(() => {
        if (!router.query.property) {
            return []
        }
        if (!Array.isArray(router.query.property)) {
            return validator.isNumeric(router.query.property) ? [parseInt(router.query.property)] : []
        }
        return (router.query.property as string[])
            .filter(item => {
                return validator.isNumeric(item)
            })
            .map(item => {
                return parseInt(item)
            })
    }, [router.query])

    const [enabledScrollIntoView, setEnabledScrollIntoView] = useState(initialExpandedRows.length === 1)

    useEffect(() => {
        if (sectionRef.current && initialExpandedRows.length && expandedRowId) {
            scroller.scrollTo(hash, {
                duration: 1000,
                smooth: 'easeInOutQuart',
                offset: 100,
                delay: 0
            })
        }
        setEnabledScrollIntoView(true)
    }, [])

    const onExpandPropertyRows = useCallback(
        (value: (number | string)[]) => {
            if (typeof value !== 'undefined' && value.length) {
                const lastExpandedRowId = value[value.length - 1]
                setExpandedRowId(lastExpandedRowId)

                router.replace(
                    {
                        pathname: router.pathname,
                        query: {
                            ...router.query,
                            property: value.filter(item => {
                                return properties.find(property => {
                                    return property.id === parseInt(item as string)
                                })
                            }) as string[]
                        }
                    },
                    null,
                    { shallow: true }
                )
                return
            }

            setExpandedRowId(null)

            router.replace(
                {
                    pathname: router.pathname,
                    query: {
                        ...Object.fromEntries(
                            Object.entries(router.query).filter(([key]) => {
                                return key.toLowerCase().trim() !== 'property'
                            })
                        )
                    }
                },
                null,
                { shallow: true }
            )
        },
        [properties, router]
    )

    useEffect(() => {
        const updateExpandedRowFromQuery = () => {
            const propertyValue = router.query.property
            if (typeof propertyValue === 'string' && validator.isNumeric(propertyValue)) {
                const propertyNumber = parseInt(propertyValue, 10)
                setExpandedRowId(propertyNumber)
                setEnabledScrollIntoView(true)
            } else {
                setExpandedRowId(null)
                setEnabledScrollIntoView(false)
            }
        }

        updateExpandedRowFromQuery()
        router.events.on('routeChangeComplete', updateExpandedRowFromQuery)

        return () => {
            router.events.off('routeChangeComplete', updateExpandedRowFromQuery)
        }
    }, [router.events, router.query.property])

    const standardHeaders = useMemo(() => {
        return StandardHeaders(displayedCols, isTableForSmallDevice, true)
    }, [displayedCols, isTableForSmallDevice])

    const dynamicHeaders = useMemo(() => {
        return displayedDynamicCols.map(dynamicCol => {
            return {
                title: dynamicCol.name,
                visible: !isTableForSmallDevice,
                value: item => {
                    const dynamicParam = item.dynamicParams.find(param => {
                        return param.id === dynamicCol.id
                    })
                    return dynamicParam ? dynamicParam.value : '-'
                }
            }
        })
    }, [displayedDynamicCols, isTableForSmallDevice])

    const tableHeaders = useMemo(() => {
        const priceIndex = standardHeaders.findIndex(header => {
            return header.title === 'Cena'
        })
        if (priceIndex >= 0) {
            return [...standardHeaders.slice(0, priceIndex), ...dynamicHeaders, ...standardHeaders.slice(priceIndex)]
        }

        return [...standardHeaders, ...dynamicHeaders]
    }, [standardHeaders, dynamicHeaders])

    const getExpansionPanel = (data: MicroSiteDetailComponentPropertiesItem) => {
        return <ComponentPropertiesTableExpansion data={data} />
    }

    return (
        <ScrollElement name={hash}>
            <ComponentManagerComponentRow {...props} ref={sectionRef}>
                <Column cols={12}>
                    <SectionTitle>{title}</SectionTitle>
                </Column>
                <Column cols={12}>
                    <Table
                        expandOnClickRow
                        headers={tableHeaders}
                        data={properties}
                        keyExtractor={(index, data: MicroSiteDetailComponentPropertiesItem) => {
                            return data.id
                        }}
                        getExpansionPanel={getExpansionPanel}
                        initialExpandedRowValue={initialExpandedRows}
                        onExpandRows={onExpandPropertyRows}
                        expandedRowId={expandedRowId}
                        scrollIntoExpandedRow={enabledScrollIntoView}
                    />
                </Column>
            </ComponentManagerComponentRow>
        </ScrollElement>
    )
}

export default ComponentPropertiesTable
