import { VideoPlayerProps } from './VideoPlayer.types'
import YouTube, { YouTubeEvent, YouTubeProps } from 'react-youtube'
import useWindowSize from '../../hooks/useWindowSize'

export const VideoPlayer = ({ videoId, shouldAutoPlay = 0, height }: VideoPlayerProps) => {
    const { width } = useWindowSize()

    const opts: YouTubeProps['opts'] = {
        // INFO:
        // We can't use the em value because the external package only supports the pixel value.
        height: height || `${width >= 1200 ? 700 : 500}px`,
        width: '100%',
        playerVars: {
            autoplay: shouldAutoPlay
        }
    }

    const onPlayerReady: YouTubeProps['onReady'] = (event: YouTubeEvent) => {
        return event.target.pauseVideo()
    }

    if (!videoId) {
        return null
    }

    return (
        <div className='rounded-[20px] overflow-hidden'>
            <YouTube videoId={videoId} opts={opts} onReady={onPlayerReady} />
        </div>
    )
}
