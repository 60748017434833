import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const BalconyIcon = ({ color = 'basic-black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const themeColor = getColorFromString(theme, color)
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M2 16.5C1.17157 16.5 0.5 17.1716 0.5 18C0.5 18.8284 1.17157 19.5 2 19.5V16.5ZM22 19.5C22.8284 19.5 23.5 18.8284 23.5 18C23.5 17.1716 22.8284 16.5 22 16.5V19.5ZM2 19.5H22V16.5H2V19.5Z'
                fill={themeColor}
            />
            <path
                d='M2 6.72222C1.17157 6.72222 0.5 7.39379 0.5 8.22222C0.5 9.05065 1.17157 9.72222 2 9.72222L2 6.72222ZM22 9.72222C22.8284 9.72222 23.5 9.05065 23.5 8.22222C23.5 7.39379 22.8284 6.72222 22 6.72222L22 9.72222ZM2 9.72222L22 9.72222L22 6.72222L2 6.72222L2 9.72222Z'
                fill={themeColor}
            />
            <path d='M4.22217 16.7778V7' stroke={themeColor} strokeWidth='3' strokeLinecap='round' />
            <path d='M12 16.7778V7' stroke={themeColor} strokeWidth='3' strokeLinecap='round' />
            <path d='M19.7778 16.7778V7' stroke={themeColor} strokeWidth='3' strokeLinecap='round' />
        </RootIcon>
    )
}

export default BalconyIcon
