import 'rc-slider/assets/index.css'
import * as yup from 'yup'
import { FieldPath, useForm } from 'react-hook-form'
import {
    MortgageCalculatorContactFormType,
    MortgageCalculatorProps,
    MortgageCalculatorRef
} from './MortgageCalculator.types'
import { RoundedFormCard } from '../form/Form.styles'
import { getCurrencySymbol } from '../../utils/Helper'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '../button/Button'
import Checkbox from '../checkbox/Checkbox'
import Column from '../grid/column/Column'
import MortgageCalculatorPrice from './MortgageCalculatorPrice'
import MortgageCalculatorSliders from './MortgageCalculatorSliders'
import MortgageCalculatorTotal from './MortgageCalculatorTotal'
import PersonalDataModal from '../personal-data-modal/PersonalDataModal'
import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import Row from '../grid/row/Row'
import TextField from '../text-field/TextField'
import Typography from '../typography/Typography'
import validator from 'validator'

const MortgageCalculator = (
    {
        initialPropertyPrice = 2999000,
        initialMortgageAmount = Math.ceil(initialPropertyPrice / 2),
        initialInterestRate: interestRate = 2.14,
        initialPaymentPeriod = 30,
        submit: propSubmit,
        title,
        currency,
        ...props
    }: MortgageCalculatorProps,
    ref: ForwardedRef<MortgageCalculatorRef>
) => {
    const [propertyPrice, setPropertyPrice] = useState<number>(initialPropertyPrice)
    const [mortgageAmount, setMortgageAmount] = useState<number>(initialMortgageAmount)
    const [paymentPeriod, setPaymentPeriod] = useState<number>(initialPaymentPeriod)
    const [isFormMenuOpened, setFormMenuOpened] = useState<boolean>(false)
    const currencySymbol = getCurrencySymbol(currency)

    const {
        handleSubmit,
        register,
        formState: { errors },
        reset
    } = useForm<MortgageCalculatorContactFormType>({
        resolver: yupResolver(
            yup.object({
                email: yup
                    .string()
                    .test('is-email', 'E-mail není platný.', value => {
                        return validator.isEmail(value || '')
                    })
                    .required('E-mail je vyžadován.'),
                agreeProcessingPersonalData: yup
                    .boolean()
                    .required('Musíte souhlasit s podmínkami.')
                    .oneOf([true], 'Musíte souhlasit s podmínkami.')
            })
        ),
        reValidateMode: 'onChange',
        defaultValues: {
            agreeProcessingPersonalData: true
        }
    })

    useImperativeHandle(
        ref,
        () => {
            return {
                resetForm: () => {
                    return reset()
                }
            }
        },
        [reset]
    )

    const getFieldError = (path: FieldPath<MortgageCalculatorContactFormType>) => {
        return errors[path]?.message as string | string[]
    }

    const submit = (data: MortgageCalculatorContactFormType) => {
        propSubmit?.({
            ...data,
            currency,
            propertyPrice,
            mortgageAmount,
            paymentPeriod
        })
    }

    const toggleFormMenu = () => {
        setFormMenuOpened(prevState => {
            return !prevState
        })
    }

    const totalPrice = useMemo<number>(() => {
        const loanAmount = mortgageAmount
        const monthlyInterestRate = interestRate / 100 / 12
        const numberOfPayments = paymentPeriod * 12
        const monthlyPayment =
            (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
            (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1)
        return Math.ceil(monthlyPayment)
    }, [mortgageAmount, interestRate, paymentPeriod])

    const changePropertyPrice = (value: number) => {
        setPropertyPrice(value)
        setMortgageAmount(Math.ceil(value / 2))
    }

    const renderAgreeWithProcessingPersonalDataLabel = () => {
        return (
            <span>
                Souhlasím se&nbsp;
                <PersonalDataModal>
                    {({ openModal }) => {
                        return (
                            <button
                                type='button'
                                className='underline hover:no-underline text-secondaryRed-400'
                                onClick={openModal}
                            >
                                zpracováním osobních údajů
                            </button>
                        )
                    }}
                </PersonalDataModal>
            </span>
        )
    }

    const renderForm = () => {
        if (!isFormMenuOpened) {
            return
        }
        return (
            <Column cols={12}>
                <Row className='lg:!justify-between'>
                    <Column cols={12}>
                        <Typography as='h3' level='h3' className='text-text-blue text-center lg:text-left'>
                            Hypotéku zařídíme za vás
                        </Typography>
                        <span className='text-body-normal font-normal text-text-blue'>
                            Ušetřete svůj čas i nervy. Naši makléři zařídí veškeré papírování a dovedou vás <br /> až k
                            úspěšnému podpisu smlouvy v bance.
                        </span>
                    </Column>
                    <Column cols={12} className='mt-8'>
                        <Row>
                            <Column cols={12} lg={6}>
                                <TextField
                                    label='Jméno a příjmení'
                                    className='serializableActivator'
                                    invalidMessage={getFieldError('fullName')}
                                    {...register('fullName')}
                                />
                            </Column>
                            <Column cols={12} lg={6}>
                                <TextField
                                    label='Telefon'
                                    className='serializableActivator'
                                    invalidMessage={getFieldError('phone')}
                                    {...register('phone')}
                                />
                            </Column>
                            <Column cols={12} lg={6}>
                                <TextField
                                    required
                                    label='Email'
                                    className='serializableActivator'
                                    invalidMessage={getFieldError('email')}
                                    {...register('email')}
                                />
                            </Column>
                            <Column cols={12}>
                                <Checkbox
                                    label={renderAgreeWithProcessingPersonalDataLabel()}
                                    invalidMessage={getFieldError('agreeProcessingPersonalData')}
                                    {...register('agreeProcessingPersonalData')}
                                />
                            </Column>
                            <Column cols={12} className='flex justify-center lg:justify-start'>
                                <Button color='red' type='submit'>
                                    Odeslat
                                </Button>
                            </Column>
                        </Row>
                    </Column>
                </Row>
            </Column>
        )
    }

    return (
        <RoundedFormCard onSubmit={handleSubmit(submit)} $color='secondaryBlue-10' {...props}>
            <Row className='space-y-10 p-3'>
                <Column cols={12}>
                    {title && (
                        <Typography as='h2' level='h2' className='text-text-button text-center lg:text-left'>
                            {title}
                        </Typography>
                    )}
                    <span className='text-body-normal font-normal text-text-blue'>
                        Na kolik vás vyjde pořízení vlastního bydlení? Zadejte pár informací do naší hypoteční <br />{' '}
                        kalkulačky a hned si prohlédněte výsledek.
                    </span>
                </Column>
                <Column cols={12}>
                    <Row className='space-y-10'>
                        <MortgageCalculatorPrice
                            value={propertyPrice}
                            currency={currencySymbol}
                            change={changePropertyPrice}
                        />
                        <MortgageCalculatorSliders
                            mortgagePriceValue={mortgageAmount}
                            changeMortgagePrice={setMortgageAmount}
                            paymentPeriodValue={paymentPeriod}
                            changePaymentPeriod={setPaymentPeriod}
                            maxMortgagePrice={propertyPrice}
                            maxPaymentPeriod={30}
                            inputCurrency={currencySymbol}
                        />
                        <MortgageCalculatorTotal
                            openMenu={toggleFormMenu}
                            totalPrice={totalPrice}
                            interestRate={interestRate}
                            totalPriceCurrency={currencySymbol}
                        />
                    </Row>
                </Column>
                {renderForm()}
            </Row>
        </RoundedFormCard>
    )
}

export default forwardRef(MortgageCalculator)
