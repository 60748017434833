import tw, { styled } from 'twin.macro'

export const TextFieldRoot = styled.div`
    ${tw`flex flex-col`}

    label {
        font-weight: 400;
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: 0.004em;
        color: ${({ theme: { colors } }) => {
            return colors.text.blue
        }};
    }

    input {
        background-color: ${({ theme: { colors } }) => {
            return colors.basic.white
        }};
        border-radius: 1.563rem;
        border: 0.125rem solid
            ${({ theme: { colors } }) => {
                return colors.secondaryBlue['100']
            }};
        padding: 0.75rem;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1rem;
        color: ${({ theme: { colors } }) => {
            return colors.text.blue
        }};
    }

    @media only screen and (max-width: 700px) {
        label {
            font-size: 0.88rem;
        }
    }
`
