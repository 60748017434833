import tw, { styled } from 'twin.macro'

export const CardBannerRoot = styled.div`
    ${tw`flex flex-col lg:flex-row justify-center lg:justify-between items-center text-center lg:text-left`}
    padding: 3.125rem 5rem;
    background-color: ${({ theme: { colors } }) => {
        return colors.background.cta
    }};
    border-radius: 1.563rem;

    @media only screen and (max-width: 700px) {
        padding: 2.125rem 2.3rem;
    }
`
