import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const ArrowDownIcon = ({ color = 'basic-black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M19.7263 8.49183C20.1476 9.06737 20.0744 9.91816 19.5628 10.3921L12.7628 16.6921C12.3197 17.1026 11.6803 17.1026 11.2372 16.6921L4.43718 10.3921C3.9256 9.91816 3.85241 9.06737 4.27372 8.49183C4.69502 7.91629 5.45129 7.83396 5.96288 8.30793L12 13.9012L18.0371 8.30793C18.5487 7.83396 19.305 7.91629 19.7263 8.49183Z'
                fill={getColorFromString(theme, color)}
            />
        </RootIcon>
    )
}

export default ArrowDownIcon
