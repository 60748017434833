import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { MicroSiteContactFormRequest, MicroSiteContactFormTerms } from './Microsite.types'
import { apiConfig } from '../config/api/apiConfig'

export const getMicroSiteContactFormTerms = async (
    axiosInstance: AxiosInstance,
    config: AxiosRequestConfig = {}
): Promise<MicroSiteContactFormTerms> => {
    const {
        data: { data: propertyMicroSiteFooter }
    } = await axiosInstance.get<AxiosResponse<MicroSiteContactFormTerms>>(
        apiConfig.microSiteShared.getSubmitContactFormTerms,
        config
    )
    return propertyMicroSiteFooter
}

export const postMicroSiteContactForm = async (
    axiosInstance: AxiosInstance,
    slug: string,
    data: MicroSiteContactFormRequest,
    recaptchaToken: string,
    config: AxiosRequestConfig = {}
): Promise<number> => {
    const {
        data: { status }
    } = await axiosInstance.post<AxiosResponse<number>>(
        apiConfig.microSiteShared.submitContactForm.replace('{slug}', slug),
        {
            ...data,
            reCaptcha: recaptchaToken
        },
        config
    )
    return status
}
