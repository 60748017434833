import { BlockProps } from './Block.types'
import { HtmlTextContainerRoot } from '../../styles/GlobalStyles'
import Column from '../grid/column/Column'
import HouseIcon from '../icon/HouseIcon'
import Image from '../image/Image'
import React from 'react'
import Row from '../grid/row/Row'
import Typography from '../typography/Typography'

const Block = ({ title, text, image, renderAsHtml }: BlockProps) => {
    const renderText = () => {
        if (renderAsHtml) {
            return (
                <HtmlTextContainerRoot>
                    <Typography
                        as='div'
                        className='text-basic-primary text-justify'
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                </HtmlTextContainerRoot>
            )
        }
        return (
            <Typography as='p' className='text-basic-primary text-justify'>
                {text}
            </Typography>
        )
    }

    return (
        <Row className='!justify-center lg:!justify-between'>
            <Column cols={12} lg={image ? 6 : 12}>
                {title && (
                    <Typography as='h2' level='h2' className='text-basic-primary text-center lg:text-left'>
                        {title}
                    </Typography>
                )}
                {renderText()}
            </Column>
            {image && (
                <Column cols={12} lg={6}>
                    <Image
                        icon={<HouseIcon size={52} color='basic-primary' />}
                        src={image}
                        width='auto'
                        height='auto'
                        imageIconSize='large'
                    />
                </Column>
            )}
        </Row>
    )
}

export default Block
