import { getColorFromString } from '../../utils/Helper'
import { styled } from 'twin.macro'

export const RootChip = styled.span<{ $color: string }>`
    padding: 0.7rem 1.275rem;
    border-radius: 1.563rem;
    color: ${({ theme }) => {
        return theme.colors.basic.white
    }};
    background-color: ${({ theme, $color }) => {
        return getColorFromString(theme, $color)
    }};
`
