import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const HalfFilledIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const themeColor = getColorFromString(theme, color)
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12 2C11.5582 2 11.1227 2.02871 10.6952 2.08447C10.1475 2.15591 9.76148 2.65777 9.83292 3.20542C9.90435 3.75306 10.4062 4.13911 10.9539 4.06767C11.2958 4.02307 11.645 4 12 4C12.0742 4 12.1481 4.00101 12.2218 4.00301C14.2493 4.0582 16.0884 4.86771 17.4687 6.16101C17.664 6.34404 17.8502 6.53676 18.0264 6.73839C19.2013 8.08292 19.9335 9.82419 19.9957 11.7348C19.9986 11.8228 20 11.9112 20 12C20 12.1039 19.998 12.2073 19.9941 12.3102C19.9217 14.2094 19.1872 15.9389 18.0149 17.2747C17.7807 17.5416 17.529 17.7927 17.2616 18.0264C15.9171 19.2013 14.1758 19.9335 12.2652 19.9957C12.1772 19.9986 12.0888 20 12 20C11.645 20 11.2958 19.9769 10.9539 19.9323C10.4062 19.8609 9.90435 20.2469 9.83292 20.7946C9.76148 21.3422 10.1475 21.8441 10.6952 21.9155C11.1227 21.9713 11.5582 22 12 22C12.1346 22 12.2687 21.9973 12.4021 21.9921C14.7707 21.8984 16.9275 20.9809 18.594 19.5181C18.9229 19.2294 19.2328 18.9194 19.5213 18.5903C20.997 16.9075 21.917 14.7253 21.9946 12.3301C21.9982 12.2205 22 12.1104 22 12C22 11.8654 21.9973 11.7313 21.9921 11.5979C21.8984 9.22931 20.9809 7.07246 19.5181 5.40603C19.2265 5.07376 18.9132 4.761 18.5803 4.46996C16.8218 2.93195 14.5197 2 12 2Z'
                fill={themeColor}
            />
            <path
                d='M7.13027 5.65232C7.56819 5.3158 7.65038 4.688 7.31386 4.25008C6.97734 3.81216 6.34953 3.72996 5.91162 4.06649C5.21922 4.59856 4.59856 5.21922 4.06649 5.91162C3.72996 6.34953 3.81216 6.97734 4.25008 7.31386C4.688 7.65038 5.3158 7.56819 5.65232 7.13027C6.07848 6.57571 6.57571 6.07848 7.13027 5.65232Z'
                fill={themeColor}
            />
            <path
                d='M4.06767 10.9539C4.13911 10.4062 3.75306 9.90435 3.20542 9.83292C2.65777 9.76148 2.15591 10.1475 2.08447 10.6952C2.02871 11.1227 2 11.5582 2 12C2 12.4418 2.02871 12.8773 2.08447 13.3048C2.15591 13.8525 2.65777 14.2385 3.20542 14.1671C3.75306 14.0956 4.13911 13.5938 4.06767 13.0461C4.02307 12.7042 4 12.355 4 12C4 11.645 4.02307 11.2958 4.06767 10.9539Z'
                fill={themeColor}
            />
            <path
                d='M5.65232 16.8697C5.3158 16.4318 4.688 16.3496 4.25008 16.6861C3.81216 17.0227 3.72996 17.6505 4.06649 18.0884C4.59856 18.7808 5.21922 19.4014 5.91162 19.9335C6.34953 20.27 6.97734 20.1878 7.31386 19.7499C7.65038 19.312 7.56819 18.6842 7.13027 18.3477C6.57571 17.9215 6.07848 17.4243 5.65232 16.8697Z'
                fill={themeColor}
            />
        </RootIcon>
    )
}

export default HalfFilledIcon
