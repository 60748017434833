import tw, { styled } from 'twin.macro'

export const NavbarRoot = styled.nav<{ $isScrolled: boolean }>`
    ${tw`
        fixed
        top-0
        transition-all 
        duration-300
        left-0
        w-full 
        h-[7rem]
        z-10
        px-6
        2xl:px-12
    `}
    ${props => {
        return props.$isScrolled ? tw`bg-background-primary shadow-sm` : tw`bg-transparent`
    }}
`

export const NavbarMenuRoot = styled.div`
    ${tw`
        flex 
        items-baseline 
        space-x-12
    `}
    margin-bottom: -.8rem;
`

export const NavbarMenuItemRoot = styled.span<{ $isFocused?: boolean }>`
    ${tw`
        cursor-pointer
        text-basic-primary 
        text-[1rem]
        hover:text-gray-500
        border-b-2
        pb-2
    `}
    ${props => {
        return props.$isFocused ? tw`font-bold border-error-400` : tw`border-transparent`
    }}
`

export const NavbarMobileMenuButtonRoot = styled.button`
    ${tw`
        inline-flex 
        items-center 
        justify-center 
        p-2 
        rounded-md 
        text-gray-800 
        hover:text-gray-500 
        hover:bg-background-secondary 
        focus:outline-none 
        focus:text-gray-500
    `}
`

export const NavbarMobileMenuRoot = styled.div<{ $isMenuOpen: boolean }>`
  ${({ $isMenuOpen }) => {
      return $isMenuOpen
          ? tw`
          flex
          flex-col
          fixed 
          inset-0 
          z-50
          flex 
          items-center
          justify-start
          overflow-y-auto
          bg-background-primary
          pb-10
        `
          : tw`hidden`
  }}
}
`

export const NavbarMobileMenuCloseButtonRoot = styled.button`
    ${tw`
        ml-auto
        text-gray-800 
        hover:text-gray-500 
        mt-6 
        mr-6
    `}
`
