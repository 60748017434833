import { ComponentLocationProps, ComponentLocationViewerResolution } from './ComponentManager.types'
import { ComponentManagerComponentRow } from './ComponentManager.styles'
import { Element as ScrollElement } from 'react-scroll'
import { extractImageUrl } from '../../utils/Helper'
import Block from '../block/Block'
import Column from '../grid/column/Column'
import GoogleMap from '../google-map/GoogleMap'
import Image from '../image/Image'
import ImageViewer from '../image-viewer/ImageViewer'
import React, { useEffect, useMemo, useState } from 'react'
import Row from '../grid/row/Row'
import SectionTitle from '../section-title/SectionTitle'
import useScrollBlock from '../../hooks/useScrollBlock'

const viewerResolutions: ComponentLocationViewerResolution[] = [
    {
        resolution: 'sd',
        width: 640,
        height: 480
    },
    {
        resolution: 'hd',
        width: 1280,
        height: 720
    },
    {
        resolution: 'full-hd',
        width: 1920,
        height: 1080
    },
    {
        resolution: '4k',
        width: 3840,
        height: 2160
    }
]

const ComponentLocation = ({
    component: { title, text, address, images, hash },
    viewerResolution: propViewerResolution = 'hd',
    ...props
}: ComponentLocationProps) => {
    const [currentImage, setCurrentImage] = useState<number>()
    const coordinates = address.split(',')
    const { blockScroll, allowScroll } = useScrollBlock()

    useEffect(() => {
        !!currentImage ? blockScroll() : allowScroll()
    }, [currentImage])

    const imagesViewer = useMemo<string[]>(() => {
        return images.map(image => {
            return extractImageUrl(
                image.url,
                viewerResolutions.find(({ resolution }) => {
                    return resolution === propViewerResolution
                })
            )
        })
    }, [images, propViewerResolution])

    const closeViewer = () => {
        return setCurrentImage(undefined)
    }

    return (
        <ScrollElement name={hash}>
            {typeof currentImage !== 'undefined' && (
                <ImageViewer currentIndex={currentImage} src={imagesViewer} onClose={closeViewer} />
            )}
            <ComponentManagerComponentRow {...props}>
                <Column cols={12}>
                    <SectionTitle>{title}</SectionTitle>
                </Column>
                {text && (
                    <Column cols={12} lg={6}>
                        <Block text={text} renderAsHtml />
                    </Column>
                )}
                <Column cols={12} lg={text ? 6 : 12}>
                    <Row>
                        {coordinates.length === 2 && (
                            <Column cols={12}>
                                <GoogleMap lat={parseFloat(coordinates[0])} lng={parseFloat(coordinates[1])} />
                            </Column>
                        )}
                        {images.length && (
                            <Column cols={12} className={text ? '' : 'pl-lg-0'}>
                                <Row>
                                    {images
                                        .filter(item => {
                                            return item?.url && item.url?.length
                                        })
                                        .map(({ url }, index) => {
                                            return (
                                                <Column key={url} cols={6} sm={text ? 2 : 1} md={text ? 4 : 2}>
                                                    <Image
                                                        onClick={() => {
                                                            return setCurrentImage(index)
                                                        }}
                                                        src={extractImageUrl(url)}
                                                        height='100%'
                                                        width='100%'
                                                        aspectRatio='149 / 133'
                                                    />
                                                </Column>
                                            )
                                        })}
                                </Row>
                            </Column>
                        )}
                    </Row>
                </Column>
            </ComponentManagerComponentRow>
        </ScrollElement>
    )
}

export default ComponentLocation
