import { IconProps } from './Icon.types'
import { RootIcon } from './Icon.styles'
import { getColorFromString } from '../../utils/Helper'
import { useTheme } from 'styled-components'
import React from 'react'

const CellarIcon = ({ color = 'black', size = 16, ...props }: IconProps) => {
    const theme = useTheme()
    const height = typeof size === 'number' ? size : size.height
    const width = typeof size === 'number' ? size : size.width
    const fillColor = getColorFromString(theme, color)

    return (
        <RootIcon
            {...props}
            $width={width}
            $height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M17.9089 20.3333V8.66667C17.9089 5.67512 15.2633 3.25 11.9998 3.25C8.73628 3.25 6.09069 5.67512 6.09069 8.66667V20.3333C6.09069 20.5634 6.29419 20.75 6.54523 20.75H17.4543C17.7054 20.75 17.9089 20.5634 17.9089 20.3333ZM11.9998 2C7.98316 2 4.72705 4.98477 4.72705 8.66667V20.3333C4.72705 21.2538 5.54108 22 6.54523 22H17.4543C18.4585 22 19.2725 21.2538 19.2725 20.3333V8.66667C19.2725 4.98477 16.0164 2 11.9998 2Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M17.9089 19.0833H6.09069V20.3333C6.09069 20.5634 6.29419 20.75 6.54523 20.75H17.4543C17.7054 20.75 17.9089 20.5634 17.9089 20.3333V19.0833ZM4.72705 17.8333V20.3333C4.72705 21.2538 5.54108 22 6.54523 22H17.4543C18.4585 22 19.2725 21.2538 19.2725 20.3333V17.8333H4.72705Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.81774 15.75V17.4167H17.9086V15.75H8.81774ZM8.36319 14.5C7.86112 14.5 7.4541 14.8731 7.4541 15.3333V18.6667H19.2723V14.5H8.36319Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.5453 12.4167V14.0833H17.9089V12.4167H11.5453ZM11.0907 11.1667C10.5887 11.1667 10.1816 11.5398 10.1816 12V15.3333H19.2725V11.1667H11.0907Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.2723 9.08333V10.75H17.9087V9.08333H14.2723ZM13.8178 7.83333C13.3157 7.83333 12.9087 8.20642 12.9087 8.66666V12H19.2723V8.66666C19.2723 8.20642 18.8653 7.83333 18.3632 7.83333H13.8178Z'
                fill={fillColor}
            />
        </RootIcon>
    )
}

export default CellarIcon
