import tw, { styled } from 'twin.macro'

export const InfoListRoot = styled.ul`
    li {
        ${tw`flex justify-between`}
        padding-bottom: 0.525rem;
        padding-top: 1.2rem;

        :not(:last-child) {
            ${tw`border-b border-grey-300`}
        }
    }
`
